import React from 'react';
import axios from 'axios';
import Helpers from 'client-helpers';
import { useStore, useStoreValue } from 'react-context-hook';
import { storeMap } from 'services/constants';
import {
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  Card,
  CardTitle,
  CardBody,
  CardText,
  Label,
} from 'reactstrap';
import { useUsers } from 'services/Users';

import './UserRSSSettings.css';

const UserRSSSettings = () => {
  const Users = useUsers();
  const [user, setUser] = useStore(storeMap.USER);
  const token = useStoreValue(storeMap.TOKEN);
  const [RSSFeeds, setRSSFeeds] = React.useState(user.RSSFeeds || []);
  const [newFeedUrl, setNewFeedUrl] = React.useState('');

  const [isLoading, setIsLoading] = React.useState(false);

  const updateUser = async () => {
    const resolvedUser = await Users.update({ _id: user._id, RSSFeeds });

    if (!resolvedUser) {
      window.flash('Error updating user', 'danger');
      return;
    }

    window.flash('Updated user settings', 'success');
    setUser(resolvedUser);
  };

  const verifyRSSFeed = async () => {
    if (RSSFeeds.find((feed) => feed.url === newFeedUrl)) {
      window.flash('Feed already added', 'warning');
      return;
    }

    setIsLoading(true);

    let feedData;
    try {
      const result = await axios.post(
        '/api/contents/rss-verify',
        { url: newFeedUrl },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      feedData = result.data;
      setRSSFeeds([{ ...feedData }, ...RSSFeeds]);
      setNewFeedUrl('');
      setIsLoading(false);
    } catch (error) {
      window.flash('Invalid RSS Feed URL', 'danger');
      setIsLoading(false);
    }
  };

  const deleteRSSFeed = (feedName) => {
    const updatedRSSFeeds = RSSFeeds.filter((feed) => feed.name !== feedName);
    setRSSFeeds(updatedRSSFeeds);
  };

  return (
    <Row className="user-rss-settings">
      <Col>
        <h3 className="line-text">News Feeds</h3>

        <p>
          You can add custom RSS feeds to have live Content directly in your Dashboard. Just add the
          direct RSS Feed URL below and click Add.
          <br />
          Questions? Just click the chat button in the bottom right and we can help you out!
        </p>

        <Row>
          <Col md={{ size: 6, offset: 2 }}>
            <FormGroup floating>
              <Input
                type="text"
                placeholder="Feed URL"
                name="url"
                value={newFeedUrl}
                disabled={isLoading}
                onChange={(event) => {
                  setNewFeedUrl(event.target.value);
                }}
              />
              <Label>Feed URL</Label>
            </FormGroup>
          </Col>
          <Col md={1}>
            <Button
              className="user-rss-settings__add-button"
              color="primary"
              disabled={!newFeedUrl || isLoading}
              onClick={verifyRSSFeed}
            >
              {isLoading ? (
                <i className="fa-solid fa-circle-o-notch fa-spin loading-icon" />
              ) : (
                'Add'
              )}
            </Button>
          </Col>
        </Row>

        <Row>
          {RSSFeeds?.map((RSSFeed) => (
            <Col key={RSSFeed.name} md={3} className="feed-card">
              <Card>
                <i
                  className="fa-solid fa-times feed-card__close"
                  onClick={() => deleteRSSFeed(RSSFeed.name)}
                />
                <CardTitle>
                  <a href={RSSFeed.link || RSSFeed.url}>{RSSFeed.name}</a>
                </CardTitle>
                <CardBody>
                  <CardText className="feed-card-description">{RSSFeed.description}</CardText>
                  {RSSFeed.imageUrl && (
                    <img className="feed-card-image" src={RSSFeed.imageUrl} alt={RSSFeed.name} />
                  )}

                  <Row>
                    <Col md={10} className="feed-card-url" title={RSSFeed.url}>
                      {RSSFeed.url}
                    </Col>
                    <Col md={2}>
                      <i
                        className="fa-solid fa-copy"
                        onClick={() => {
                          Helpers.copyToClipboard(RSSFeed.url, 'URL copied to clipboard!');
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
        <Button size="sm" color="primary" className="cta" onClick={updateUser}>
          Save
        </Button>
        <hr />
      </Col>
    </Row>
  );
};

export default UserRSSSettings;
