import React from 'react';
import axios from 'axios';
import Home from './Home/Home';
import Helpers from 'client-helpers';
import AppContainer from './App/AppContainer';
import PasswordReset from './PasswordReset';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Container } from 'reactstrap';
import { loadStripe } from '@stripe/stripe-js';
import { withStore, useStore, useSetStoreValue, store } from 'react-context-hook';
import FlashMessage from './components/FlashMessage';
import socket from './websocket';
import ReactGA from 'react-ga4';
import GAListener from './components/GAListener';
import { useUsers } from 'services/Users';
import { storeMap } from 'services/constants';
import ScrollToTop from 'components/ScrollToTop';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import './App.css';

if (process.env.REACT_APP_ENVIRONMENT !== 'dev') {
  Sentry.init({
    dsn: 'https://ee989e5148b244348299f487212f6060@o4504291165536256.ingest.sentry.io/4504291167830016',
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const App = () => {
  const Users = useUsers();
  const [token, setToken] = useStore(storeMap.TOKEN);
  const [user, setUser] = useStore(storeMap.USER);
  const setFlash = useSetStoreValue(storeMap.FLASH_MESSAGE);
  const setStripe = useSetStoreValue(storeMap.STRIPE);
  const defaultOnErrorFn = React.useRef(window.onerror);

  React.useEffect(() => {
    const localToken = localStorage?.getItem('token');
    setToken(localToken);

    const current = defaultOnErrorFn.current;
    window.onerror = (...args) => {
      if (args[0] === 'ResizeObserver loop limit exceeded') {
        return true;
      }
      return current?.(...args);
    };
    return () => {
      window.onerror = current;
    };
  }, []);

  React.useEffect(() => {
    // Setup Flash message component bus
    window.flash = (message, type = 'success', duration = 5000) => {
      setFlash({
        message,
        type,
        duration,
      });
    };
  }, []);

  const fetchUserData = React.useCallback(async () => {
    if (token === null) {
      return null;
    }

    if (!token) {
      return setUser();
    }

    try {
      await Users.fetchCurrent();
    } catch (err) {
      console.error(err);
    }

    socket.init(token);
    return null;
  }, [token]);

  const fetchStripeData = React.useCallback(async () => {
    // Fetch Stripe API Key
    let response;
    try {
      response = await axios.get('/api/stripe');
    } catch (err) {
      console.error(err);
    }

    if (response) {
      const { stripeApiKey } = response.data;
      const stripe = await loadStripe(stripeApiKey);

      setStripe(stripe);
    }
  }, []);

  React.useEffect(() => {
    if (!user) {
      return;
    }

    // Set FreshChat identifiers
    if (window.fcWidget) {
      window.fcWidget.setExternalId(user._id);
      window.fcWidget.user.setEmail(user.email);
      if (user.firstName) {
        window.fcWidget.user.setFirstName(user.firstName);
      }

      if (Helpers.isDev()) {
        window.fcWidget.destroy();
      }
    }

    // Set Fullstory identifiers
    if (window.FS) {
      window.FS.identify(user._id, {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        firmName: user.firmName,
        status: user.status,
      });
    }

    Sentry.setUser({ id: user._id, email: user.email });

    // Set GoogleAnalytics identifiers
    ReactGA.set({ userId: user._id, email: user.email });
  }, [user]);

  React.useEffect(() => {
    fetchUserData();
    fetchStripeData();
  }, [fetchUserData, fetchStripeData]);

  const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

  return (
    <BrowserRouter>
      <ScrollToTop />

      <GAListener trackingId={GOOGLE_ANALYTICS_ID}>
        <Container className="App" fluid>
          <FlashMessage />

          <Routes>
            <Route path="/*" element={<Home />} />
            <Route
              path="app/*"
              element={
                <AppContainer
                  onLogout={() => {
                    localStorage.setItem('token', '');
                    store.reset({});
                  }}
                />
              }
            />

            <Route path="reset-password/:token" element={<PasswordReset />} />
          </Routes>
        </Container>
      </GAListener>
    </BrowserRouter>
  );
};

export default withStore(App, {
  [storeMap.TOKEN]: null,
  [storeMap.USER]: null,
});
