import axios from 'axios';
import { useSetStoreValue, useStoreValue } from 'react-context-hook';
import { storeMap } from './constants';

const useUsers = () => {
  const token = useStoreValue(storeMap.TOKEN);
  const setUser = useSetStoreValue(storeMap.USER);

  const completeTour = async (tourName) => {
    if (!token) {
      return null;
    }

    let response;
    try {
      response = await axios.post(
        `/api/users/complete-tour`,
        { tourName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      return null;
    }

    return response.data;
  };

  const create = async (user) => {
    let response;
    try {
      response = await axios.post(`/api/users`, user);
    } catch (error) {
      return null;
    }

    return response.data;
  };

  const fetch = async () => {
    if (!token) {
      return null;
    }

    // Fetch user data
    const response = await axios.get('/api/users', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  };

  const fetchCurrent = async () => {
    if (!token) {
      return null;
    }

    // Fetch user data
    const response = await axios.get('/api/users/me', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const user = response.data;
    setUser(user);
    return user;
  };

  const impersonate = async (userId) => {
    if (!token) {
      return null;
    }

    let response;
    try {
      response = await axios.post(
        `/api/users/impersonate/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      return null;
    }

    return response.data;
  };

  const login = async ({ email, password }) => {
    let response;

    try {
      response = await axios.post('/api/users/login', { email, password });
    } catch (error) {
      return {};
    }

    return response.data;
  };

  const resetPassword = async ({ token: resetToken, password }) => {
    try {
      await axios.post('/api/users/reset-password', { token: resetToken, password });
    } catch (err) {
      return false;
    }

    return true;
  };

  const update = async (updatedUser) => {
    if (!token) {
      return null;
    }

    let response;
    try {
      response = await axios.put(`/api/users/${updatedUser._id}`, updatedUser, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      return null;
    }

    return response.data;
  };

  const userExists = async (email) => {
    try {
      await axios.post('/api/users/exists', {
        email,
      });
    } catch (error) {
      return true;
    }

    return false;
  };

  const sendContactEmail = async ({ name, email, message, captchaToken }) => {
    try {
      await axios.post('/api/users/contact', { name, email, message, captchaToken });
    } catch (error) {
      return true;
    }

    return false;
  };

  const sendResetPasswordEmail = async (email) => {
    try {
      await axios.post('/api/users/forgot-password', {
        email,
      });
    } catch (error) {
      return true;
    }

    return false;
  };

  const sendTourNotification = async (email) => {
    try {
      await axios.post('/api/users/tour', email);
    } catch (error) {
      return true;
    }

    return false;
  };

  const createReferralCode = async (updatedUser) => {
    if (!token) {
      return null;
    }

    let response;
    try {
      response = await axios.post(
        `/api/users/create-referral-code`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      return null;
    }

    return response.data;
  };

  return {
    completeTour,
    create,
    createReferralCode,
    fetch,
    fetchCurrent,
    impersonate,
    login,
    resetPassword,
    update,
    userExists,
    sendContactEmail,
    sendResetPasswordEmail,
    sendTourNotification,
  };
};

export default useUsers;
export { useUsers };
