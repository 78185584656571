import axios from 'axios';
import { useStoreValue } from 'react-context-hook';
import { storeMap } from './constants';

const useCalendarEvents = () => {
  const token = useStoreValue(storeMap.TOKEN);

  const fetch = async ({ startDttm, endDttm }) => {
    if (!token) {
      return [];
    }

    let response;
    try {
      response = await axios.get(`/api/google/calendar-events`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { startDttm, endDttm },
      });
    } catch (error) {
      window.flash('Error fetching calendar events', 'warning');
      return [];
    }

    const calendarEvents = response.data || [];

    return calendarEvents;
  };

  return {
    fetch,
  };
};

export default useCalendarEvents;
export { useCalendarEvents };
