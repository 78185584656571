import axios from 'axios';
import { useStore, useStoreValue } from 'react-context-hook';
import { storeMap } from './constants';

const useJobs = () => {
  const [jobsById, setJobsById] = useStore(storeMap.JOBS_BY_ID, {});
  const token = useStoreValue(storeMap.TOKEN);

  const fetch = async ({ date }) => {
    if (!token) {
      return;
    }

    let response;
    try {
      response = await axios.get(`/api/jobs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { date },
      });
    } catch (error) {
      window.flash('Error fetching jobs', 'warning');
      return;
    }

    const jobs = response.data;

    jobs.forEach((job) => {
      jobsById[job._id] = job;
    });

    setJobsById({ ...jobsById });
  };

  const create = async (job) => {
    let response;
    try {
      response = await axios.post('/api/jobs', job, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      return window.flash('Error rendering video', 'warning');
    }

    window.flash('Your video is being rendered. Check back in a few minutes!', 'success');

    const persistedJob = response.data;
    jobsById[persistedJob._id] = persistedJob;
    setJobsById({ ...jobsById });

    return persistedJob;
  };

  return {
    fetch,
    create,
  };
};

export default useJobs;
export { useJobs };
