import React from 'react';
import axios from 'axios';
import { Row, Col, Button, FormText } from 'reactstrap';
import { useCollections } from 'services/Collections';
import { useUsers } from 'services/Users';
import CollectionPreviewModal from 'components/CollectionPreviewModal';
import { useStoreValue } from 'react-context-hook';
import { storeMap } from 'services/constants';
import Confirm from 'components/ConfirmModal';

import './CollectionSettings.css';

const CollectionSettings = () => {
  const Collections = useCollections();
  const Users = useUsers();
  const user = useStoreValue(storeMap.USER);
  const token = useStoreValue(storeMap.TOKEN);
  const [isSaving, setIsSaving] = React.useState(false);
  const [collections, setCollections] = React.useState([]);
  const [previewingCollectionId, setPreviewingCollectionId] = React.useState();
  const collectionIds = user.collectionIds || [];

  const purchaseCollection = async (collectionId, creditToUse) => {
    setIsSaving(true);

    // Call API to update user
    try {
      await axios.post(
        `/api/stripe/purchase-collection`,
        { collectionId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      window.flash('Error purchasing collection', 'danger');
      setIsSaving(false);
      return;
    }

    collectionIds.push(collectionId);

    Users.fetchCurrent();

    window.flash('Collection purchased');
    setIsSaving(false);
  };

  React.useEffect(() => {
    Collections.fetchPublic().then((collectionsResponse) => {
      setCollections(collectionsResponse);
    });
  }, []);

  const userCredit = parseInt(user.referral?.credit || 0, 10);

  return (
    <div className="collection-settings">
      <h3 className="line-text">Collections</h3>
      <p>
        Each collection will provide you with limited edition content that will really impress your
        followers.
      </p>

      <Row className="collections">
        {collections.map((collection) => {
          const collectionPrice = parseInt(collection.price, 10);
          const creditToUse = Math.min(collection.price, userCredit);
          let price = <span>${collectionPrice}</span>;

          if (collectionPrice === 0) {
            price = <span>Free</span>;
          } else if (userCredit) {
            price = (
              <span className="collections__discounted-price">
                <del>${collectionPrice}</del> ${collectionPrice - creditToUse}
              </span>
            );
          }

          return (
            <Col key={collection._id} md={{ size: 6 }} className="collection">
              <div className="collection-card">
                <h2>
                  {collection.name} | {price}
                </h2>

                {!!userCredit && <FormText>User credit of ${userCredit}</FormText>}

                {!!collectionPrice && <span>one time cost</span>}

                <div
                  className="collection-preview"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: collection.description }}
                />
                <div className="button-section">
                  <Button
                    color="none"
                    onClick={() => {
                      setPreviewingCollectionId(collection._id);
                    }}
                  >
                    See Examples
                  </Button>

                  <hr />

                  {collectionIds.includes(collection._id) ? (
                    <Button disabled>Owned</Button>
                  ) : (
                    <div>
                      {parseInt(collection.remainingPurchaseCount, 10) === 0 ? (
                        <Button color="cta" disabled>
                          Sold out
                        </Button>
                      ) : (
                        <Button
                          color="cta"
                          disabled={isSaving}
                          onClick={async () => {
                            const result = await Confirm({
                              message: `${collectionPrice ? 'Buy' : 'Add'} collection ${
                                collection.name
                              }?`,
                            });

                            if (!result) {
                              return;
                            }

                            purchaseCollection(collection._id, creditToUse);
                          }}
                        >
                          {collectionPrice ? 'Buy' : 'Add'}
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Col>
          );
        })}
      </Row>

      {previewingCollectionId && (
        <CollectionPreviewModal
          collectionId={previewingCollectionId}
          onClose={() => setPreviewingCollectionId()}
        />
      )}
    </div>
  );
};

export default CollectionSettings;
