import React from 'react';
import { useStore, useStoreValue } from 'react-context-hook';
import { CollectionStatus, storeMap } from 'services/constants';
import { useCollections } from 'services/Collections';
import withSmoothLoad from 'components/withSmoothLoad';
import { Row, Col, Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import ContentModal from 'components/ContentModal';
import Confirm from 'components/ConfirmModal';
import TextInput from 'components/TextInput';
import CollectionForm from 'components/CollectionForm';

import './ContentManager.css';
import ShareCollectionModal from './ShareCollectionModal';

const ContentManager = () => {
  const Collections = useCollections();
  const [collections, setCollections] = React.useState([]);
  const [viewingContentId, setViewingContentId] = useStore(storeMap.VIEWING_CONTENT_ID);
  const contentsById = useStoreValue(storeMap.CONTENTS_BY_ID, {});
  const user = useStoreValue(storeMap.USER);
  const [filter, setFilter] = React.useState();
  const [sharingCollection, setSharingCollection] = React.useState();

  const viewingContent = contentsById[viewingContentId];
  const isContentOwner = viewingContent?.collectionIds?.includes(user.privateCollectionId);

  const onFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const fetchCollections = async () => {
    const fetchedCollections = await Collections.fetch();

    setCollections(fetchedCollections);
  };

  const filteredCollections = collections.filter((collection) => {
    // only show private collections
    const searchTerm = filter?.toLowerCase();
    return (
      !searchTerm ||
      collection.name.toLowerCase().includes(searchTerm) ||
      collection.description?.toLowerCase().includes(searchTerm) ||
      collection.userId?.toLowerCase().includes(searchTerm)
    );
  });

  React.useEffect(() => {
    fetchCollections();
  }, []);

  return (
    <div className="content-manager">
      <ReactTooltip effect="solid" />

      <h3>Community</h3>

      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <TextInput name="filter" displayText="Filter" value={filter} onChange={onFilterChange} />
        </Col>
      </Row>

      <Row className="collections">
        {filteredCollections.map((collection) => (
          <Col key={collection._id} md={6} className="collection">
            <div className="collection-card">
              <h4>{collection.name}</h4>

              <div
                className="collection-preview"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: collection.description }}
              />

              {collection.status === CollectionStatus.PRIVATE && (
                <Button
                  data-tip="Share Collection"
                  className="btn-share-collection"
                  color="none"
                  onClick={() => setSharingCollection(collection)}
                  data-id={collection._id}
                >
                  <i className="fa-solid fa-share-nodes" />
                </Button>
              )}

              {/* <Button
                  className="btn-edit-collection"
                  color="none"
                  onClick={editCollection}
                  data-id={collection._id}
                >
                  <i className="fa-solid fa-pencil" />
                </Button> */}

              {/* <Button
                  className="btn-delete-collection"
                  color="none"
                  onClick={async () => {
                    const result = await Confirm({
                      message: `Delete collection?`,
                    });

                    if (result) {
                      await Collections.deleteCollection(collection._id);
                      fetchCollections();
                    }
                  }}
                  data-id={collection._id}
                >
                  <i className="fa-solid fa-times" />
                </Button> */}
            </div>
          </Col>
        ))}
      </Row>

      {sharingCollection && (
        <ShareCollectionModal
          show
          toggle={() => setSharingCollection()}
          collection={sharingCollection}
        />
      )}

      {viewingContentId && <ContentModal edit={isContentOwner} />}
    </div>
  );
};

export default withSmoothLoad(ContentManager);
