import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, FormText, FormFeedback } from 'reactstrap';

const TextInput = ({
  name,
  displayText,
  value,
  required,
  onChange,
  onFocus,
  invalid,
  hideLabel,
  type,
  helperText,
  feedback,
  autoComplete,
  size,
}) => {
  let displayName = displayText || `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
  if (required) {
    displayName += '*';
  }

  return (
    <FormGroup floating={!hideLabel}>
      <Input
        bsSize={size}
        type={type}
        name={name}
        id={`${name}Field`}
        placeholder={displayName}
        value={value}
        onFocus={onFocus}
        onChange={onChange}
        invalid={!!feedback || invalid}
        required={required}
        autoComplete={autoComplete}
      />
      {!hideLabel && <Label for={`${name}Field`}>{displayName}</Label>}
      <FormText color="muted">{helperText}</FormText>
      {feedback && <FormFeedback>{feedback}</FormFeedback>}
    </FormGroup>
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  displayText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  invalid: PropTypes.bool,
  hideLabel: PropTypes.bool,
  type: PropTypes.string,
  helperText: PropTypes.string,
  feedback: PropTypes.string,
  autoComplete: PropTypes.string,
  size: PropTypes.string,
};

TextInput.defaultProps = {
  onFocus: () => {},
  value: '',
  displayText: null,
  required: false,
  invalid: false,
  hideLabel: false,
  type: 'text',
  helperText: '',
  feedback: '',
  autoComplete: 'on',
  size: 'sm',
};

export default TextInput;
