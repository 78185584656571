import React from 'react';
import ContactForm from '../../components/ContactForm';
import './Contact.css';

const Contact = () => (
  <div className="contact">
    <h3 className="line-text">Contact Us</h3>

    <ContactForm />
  </div>
);

export default Contact;
