import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Label, Input, FormGroup } from 'reactstrap';

const FacebookSettings = ({ facebook, instagram, onPageChange, onInstagramChange }) => {
  const selectedPageId = facebook.pages?.find((page) => page.isActive)?.id;
  const selectedInstagramAccountId = instagram?.accounts.find((account) => account.isActive);

  return (
    <Row className="social-platform-settings">
      {facebook.pages && (
        <Col>
          <FormGroup floating>
            <Input
              type="select"
              name="page"
              id="pageSelect"
              onChange={onPageChange}
              defaultValue={selectedPageId}
            >
              {facebook.pages.map((page) => (
                <option key={page.id} value={page.id}>
                  {page.name}
                </option>
              ))}
            </Input>
            <Label for="pageSelect">Facebook Page</Label>
          </FormGroup>
        </Col>
      )}

      {instagram && (
        <Col>
          <FormGroup floating>
            <Input
              type="select"
              name="instagrameAccount"
              id="instagrameAccount"
              onChange={onInstagramChange}
              defaultValue={selectedInstagramAccountId}
            >
              {instagram?.accounts.map((account) => (
                <option key={account.id} value={account.id}>
                  {account.username}
                </option>
              ))}
            </Input>
            <Label for="instagrameAccount">Instagram Username</Label>
          </FormGroup>
        </Col>
      )}
    </Row>
  );
};

FacebookSettings.propTypes = {
  facebook: PropTypes.shape({
    pages: PropTypes.arrayOf(PropTypes.shape),
  }).isRequired,
  instagram: PropTypes.shape({
    accounts: PropTypes.arrayOf(PropTypes.shape),
  }),
  onPageChange: PropTypes.func.isRequired,
  onInstagramChange: PropTypes.func.isRequired,
};

FacebookSettings.defaultProps = {
  instagram: null,
};

export default FacebookSettings;
