import UserProfileForm from 'components/UserProfileForm';
import React from 'react';
import { NavLink as RRNavLink, Route, Routes } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import withSmoothLoad from '../../components/withSmoothLoad';
import UserAISettings from './UserAISettings';
import UserPaymentSettings from './UserPaymentSettings';
import UserRSSSettings from './UserRSSSettings';
import UserSocialSettings from './UserSocialSettings';

import { useStore } from 'react-context-hook';
import { storeMap } from 'services/constants';
import CollectionSettings from './CollectionSettings';
import './Settings.css';

const Settings = () => {
  const [user, setUser] = useStore(storeMap.USER);

  return (
    <Row className="settings">
      <Col>
        <ReactTooltip effect="solid" />

        <h2>Settings</h2>

        <Nav>
          <NavItem>
            <NavLink tag={RRNavLink} to="/app/settings/profile">
              Profile
            </NavLink>
          </NavItem>

          {user._id !== '63e6a9c0e112ad201b554e95' && (
            <NavItem>
              <NavLink tag={RRNavLink} to="/app/settings/social">
                Social
              </NavLink>
            </NavItem>
          )}

          <NavItem>
            <NavLink tag={RRNavLink} to="/app/settings/payment">
              Payment
            </NavLink>
          </NavItem>

          {user._id !== '63e6a9c0e112ad201b554e95' && (
            <NavItem>
              <NavLink tag={RRNavLink} to="/app/settings/collections">
                Collections
              </NavLink>
            </NavItem>
          )}

          <NavItem>
            <NavLink tag={RRNavLink} to="/app/settings/news">
              News Feeds
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink tag={RRNavLink} to="/app/settings/ai">
              AI
            </NavLink>
          </NavItem>
        </Nav>

        <hr />

        <Routes>
          <Route path="profile" element={<UserProfileForm user={user} onUpdate={setUser} />} />
          <Route path="social" element={<UserSocialSettings />} />
          <Route path="payment" element={<UserPaymentSettings />} />
          <Route path="collections" element={<CollectionSettings />} />
          <Route path="news" element={<UserRSSSettings />} />
          <Route path="ai" element={<UserAISettings />} />
        </Routes>
      </Col>
    </Row>
  );
};

export default withSmoothLoad(Settings);
