import React from 'react';
import ContactForm from './ContactForm';
import { useUsers } from 'services/Users';
import ReCAPTCHA from 'react-google-recaptcha';
import { useStoreValue } from 'react-context-hook';
import { storeMap } from 'services/constants';

const recaptchaRef = React.createRef();

const ContactFormWithSubmit = () => {
  const Users = useUsers();
  const user = useStoreValue(storeMap.USER);

  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LdOX0UkAAAAAAl8IEYZKyZ_QNElz10FBvaEJkTW"
      />
      <ContactForm
        user={user}
        onSubmit={async ({ name, email, message }) => {
          let captchaToken;
          try {
            captchaToken = await recaptchaRef.current.execute({
              action: 'contactForm',
            });
          } catch (err) {
            console.error(err);
          }

          await Users.sendContactEmail({ name, email, message, captchaToken });

          window.flash('Message sent!');
        }}
      />
    </>
  );
};

export default ContactFormWithSubmit;
