import axios from 'axios';
import { store, useStoreValue, useSetStoreValue } from 'react-context-hook';
import { storeMap } from './constants';

const LINK_UNFURL_CACHE = {};

const useCollections = () => {
  const token = useStoreValue(storeMap.TOKEN);
  const setCollectionsById = useSetStoreValue(storeMap.COLLECTIONS_BY_ID);

  const create = async (collection) => {
    if (!token) {
      return null;
    }

    let response;
    try {
      response = await axios.post(`/api/collections`, collection, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      return null;
    }

    const resolvedCollection = response.data.collection;

    if (resolvedCollection) {
      const collectionsById = store.getState()[storeMap.COLLECTIONS_BY_ID] || {};
      collectionsById[resolvedCollection._id] = resolvedCollection;
      setCollectionsById({ ...collectionsById });
    }

    return resolvedCollection;
  };

  const fetch = async (all) => {
    if (!token) {
      return null;
    }

    // Fetch collection data
    const response = await axios.get('/api/collections', {
      params: {
        all,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const collections = response.data.collections;
    const collectionsById = store.getState()[storeMap.COLLECTIONS_BY_ID] || {};
    collections.forEach((collection) => {
      collectionsById[collection._id] = collection;
    });
    setCollectionsById({ ...collectionsById });

    return response.data.collections || [];
  };

  const fetchPublic = async () => {
    // Fetch public collections
    const response = await axios.get('/api/collections/public');

    return response.data.collections || [];
  };

  const fetchExampleContent = async (collectionId) => {
    // Fetch collection data
    const response = await axios.get(`/api/collections/${collectionId}/example-contents`);

    return response.data.contents || [];
  };

  const share = async (collectionId, emails) => {
    const response = await axios.post(
      `/api/collections/${collectionId}/share`,
      { emails },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  };

  const unfurl = async (url) => {
    if (LINK_UNFURL_CACHE[url]) {
      return LINK_UNFURL_CACHE[url];
    }

    // Fetch unfurled content
    let response;
    try {
      response = await axios.get(`/api/collections/unfurl?url=${url}`);
    } catch (error) {
      return null;
    }

    const linkInfo = response.data;
    LINK_UNFURL_CACHE[url] = linkInfo;
    return linkInfo;
  };

  const update = async (updatedCollection) => {
    if (!token) {
      return null;
    }

    let response;
    try {
      response = await axios.put(`/api/collections/${updatedCollection._id}`, updatedCollection, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      return null;
    }

    return response.data.collection;
  };

  const deleteCollection = async (collectionId) => {
    if (!token) {
      return null;
    }

    let response;
    try {
      response = await axios.delete(`/api/collections/${collectionId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      return null;
    }

    return response.data.collection;
  };

  return {
    create,
    fetch,
    fetchPublic,
    fetchExampleContent,
    share,
    unfurl,
    update,
    deleteCollection,
  };
};

export default useCollections;
export { useCollections };
