/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import { useStore, useStoreValue } from 'react-context-hook';
import { storeMap } from 'services/constants';
import SocialPostCard from 'components/SocialPostCard';
import SocialPostModal from 'components/SocialPostModal';

import './DaySelect.css';

const DaySelect = ({ dates }) => {
  const socialPostsById = useStoreValue(storeMap.SOCIAL_POSTS_BY_ID, {});
  const [selectedDate, setSelectedDate] = useStore(storeMap.SELECTED_DATE);
  const [selectedSocialPostId, setSelectedSocialPostId] = React.useState();

  return (
    <div className="day-select">
      {dates.map((date) => {
        // TODO: Memoize this shit up top
        const socialPosts = Object.values(socialPostsById).filter((iSocialPost) => {
          const socialPostDate = moment(iSocialPost.date).format().split('T')[0];
          const isDateSame = date === socialPostDate;
          return iSocialPost.createdAt && isDateSame;
        });
        return (
          <div key={date} className={`day-select__day ${date === selectedDate ? 'selected' : ''}`}>
            <div className="date" onClick={() => setSelectedDate(date)}>
              {moment.utc(date).format('ddd DD')}
            </div>
            {socialPosts.map((socialPost) => (
              <div className="day-select__post" key={socialPost._id}>
                <i
                  className={`fab fa-${socialPost.platform} disabled`}
                  id={`contentPreview-${socialPost._id}`}
                  onClick={() => setSelectedSocialPostId(socialPost._id)}
                />
                <UncontrolledPopover
                  className="content-preview-popover"
                  target={`contentPreview-${socialPost._id}`}
                  trigger="hover"
                >
                  <PopoverBody>
                    <SocialPostCard {...socialPost} />
                  </PopoverBody>
                </UncontrolledPopover>
              </div>
            ))}
          </div>
        );
      })}
      <i
        className="fa-solid fa-chevron-right"
        onClick={() =>
          setSelectedDate(moment(selectedDate).add(1, 'w').toISOString(true).split('T')[0])
        }
      />
      {selectedSocialPostId && (
        <SocialPostModal socialPostId={selectedSocialPostId} onClose={setSelectedSocialPostId} />
      )}
    </div>
  );
};

DaySelect.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string),
};

DaySelect.defaultProps = {
  dates: [],
};

export default DaySelect;
