/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './BlogPost.css';

const BlogPost = ({ title, createdAt, keywords, htmlContent }) => (
  <div className="blog-post">
    <div className="title">
      <h3>{title}</h3>
    </div>

    <div className="date">
      <p>
        <i className="fa-solid fa-clock-o" /> {moment(createdAt).format('MM/DD YYYY')}
      </p>
    </div>

    <div className="keywords">
      <b>Keywords:</b> {keywords.join(', ')}
    </div>

    <div className="content" dangerouslySetInnerHTML={{ __html: htmlContent }} />
  </div>
);

BlogPost.propTypes = {
  _id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string),
  htmlContent: PropTypes.string.isRequired,
};

BlogPost.defaultProps = {
  keywords: [],
};

export default BlogPost;
