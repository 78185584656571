/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import { useSearchParams } from 'react-router-dom';
import { useStore, useStoreValue } from 'react-context-hook';
import { FeatureFlags, storeMap, Tours } from 'services/constants';
import { Row, Col, FormGroup, Input, Button } from 'reactstrap';
import { useUsers } from 'services/Users';
import FacebookSettings from './FacebookSettings';
import LinkedInSettings from './LinkedInSettings';
import { socialLandingTour, socialConnectedTour } from 'util/shepherd';

import './UserSocialSettings.css';
import GoogleSettings from './GoogleSettings';

const UserSocialSettings = () => {
  const Users = useUsers();
  const [searchParams] = useSearchParams();
  const [user, setUser] = useStore(storeMap.USER);
  const token = useStoreValue(storeMap.TOKEN);
  const [twitter, setTwitter] = React.useState(user.twitter);
  const [facebook, setFacebook] = React.useState(user.facebook);
  const [instagram, setInstagram] = React.useState(user.instagram);
  const [linkedin, setLinkedin] = React.useState(user.linkedin);
  const [google, setGoogle] = React.useState(user.google);
  const [tiktok, setTiktok] = React.useState(user.tiktok);

  React.useEffect(() => {
    if (user.flags?.[Tours.SOCIAL_LANDING]) {
      const tour = socialLandingTour();
      tour.start();

      Users.completeTour(Tours.SOCIAL_LANDING);
      setUser({ ...user, flags: { ...user.flags, [Tours.SOCIAL_LANDING]: null } });

      return () => {
        tour.complete();
      };
    }

    const socialConnected = user.facebook || user.twitter || user.linkedin || user.google;
    if (socialConnected && user.flags?.[Tours.SOCIAL_CONNECTED]) {
      const tour = socialConnectedTour();
      tour.start();

      Users.completeTour(Tours.SOCIAL_CONNECTED);
      setUser({ ...user, flags: { ...user.flags, [Tours.SOCIAL_CONNECTED]: null } });

      return () => {
        tour.complete();
      };
    }

    return null;
  }, []);

  // Check for social connection errors
  if (searchParams.get('success') === 'false') {
    window.flash(decodeURIComponent(searchParams.get('message')), 'danger', 10 * 1000);
  }

  const updateUser = async () => {
    const fieldsToUpdate = { _id: user._id };

    if (user.twitter !== twitter) {
      fieldsToUpdate.twitter = twitter;
    }
    if (user.facebook !== facebook) {
      fieldsToUpdate.facebook = facebook;
    }
    if (user.instagram !== instagram) {
      fieldsToUpdate.instagram = instagram;
    }
    if (user.linkedin !== linkedin) {
      fieldsToUpdate.linkedin = linkedin;
    }
    if (user.google !== google) {
      fieldsToUpdate.google = google;
    }

    const resolvedUser = await Users.update(fieldsToUpdate);

    if (!resolvedUser) {
      window.flash('Error updating user', 'danger');
      return;
    }

    window.flash('Updated user settings', 'success');
    setUser(resolvedUser);
  };

  const disconnectSocialService = async (serviceName, setService) => {
    let response;
    try {
      response = await axios.post(
        `/api/${serviceName}/disconnect`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      window.flash(`Error disconnecting ${serviceName}`, 'danger');
      return;
    }

    window.flash(`${serviceName} disconnected`, 'success');
    const updatedUser = response.data;

    setService(null);
    setUser(updatedUser);
  };

  return (
    <Row className="user-social-settings">
      <Col>
        <h3 className="line-text social-accounts-header">Social Accounts</h3>
        <ReactTooltip effect="solid" />

        <Row>
          <Col md={{ size: 8, offset: 2 }} className="user-social-settings__platform">
            <Row>
              <Col md={4} className="user-social-settings__platform-toggle">
                <FormGroup switch data-tour-facebook-switch>
                  <Input
                    id="facebookSwitch"
                    type="switch"
                    name="facebook"
                    defaultChecked={facebook}
                    onChange={() => {
                      if (facebook) {
                        disconnectSocialService('facebook', setFacebook);
                      } else {
                        window.location.href = `/api/facebook/connect?token=${token}`;
                      }
                    }}
                  />
                </FormGroup>
                <span className="brand-icons">
                  <i className="fa-brands fa-facebook" /> <i className="fa-brands fa-instagram" />
                </span>
                {facebook && (
                  <div className="user-social-settings__platform-profile">
                    {facebook.displayName && <div>{facebook.displayName}</div>}
                    {facebook.picture && (
                      <div>
                        <img src={facebook.picture} alt="profile" />
                      </div>
                    )}
                  </div>
                )}
              </Col>
              <Col md={8}>
                {facebook && (
                  <FacebookSettings
                    facebook={facebook}
                    instagram={instagram}
                    onPageChange={async (e) => {
                      // Set this page as active
                      const pageId = e.target.value;
                      facebook.pages.forEach((page) => {
                        page.isActive = page.id === pageId ? true : undefined;
                      });
                      setFacebook({ ...facebook });
                    }}
                    onInstagramChange={async (e) => {
                      // Set this page as active
                      const accountId = e.target.value;
                      instagram.accounts.forEach((account) => {
                        account.isActive = account.id === accountId ? true : undefined;
                      });
                      setInstagram({ ...instagram });
                    }}
                  />
                )}
              </Col>
            </Row>
          </Col>

          <Col md={{ size: 8, offset: 2 }} className="user-social-settings__platform">
            <Row>
              <Col md={4} className="user-social-settings__platform-toggle">
                <FormGroup switch>
                  <Input
                    id="twitterSwitch"
                    type="switch"
                    name="twitter"
                    defaultChecked={twitter}
                    onChange={() => {
                      if (twitter) {
                        disconnectSocialService('twitter', setTwitter);
                      } else {
                        window.location.href = `/api/twitter/connect?token=${token}`;
                      }
                    }}
                  />
                </FormGroup>
                <i className="fa-brands fa-twitter" />

                {twitter && (
                  <div className="user-social-settings__platform-profile">
                    <a
                      href={`https://twitter.com/${twitter.username}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      @{twitter?.username}
                    </a>
                    {twitter.picture && (
                      <div>
                        <img src={twitter.picture} alt="profile" />
                      </div>
                    )}
                  </div>
                )}
              </Col>

              <Col md={8}>{twitter && <div className="social-platform-settings" />}</Col>
            </Row>
          </Col>

          <Col md={{ size: 8, offset: 2 }} className="user-social-settings__platform">
            <Row>
              <Col md={4} className="user-social-settings__platform-toggle">
                <FormGroup switch>
                  <Input
                    id="linkedinSwitch"
                    type="switch"
                    role="switch"
                    name="linkedin"
                    defaultChecked={linkedin}
                    onChange={() => {
                      if (linkedin) {
                        disconnectSocialService('linkedin', setLinkedin);
                      } else {
                        window.location.href = `/api/linkedin/connect?token=${token}`;
                      }
                    }}
                  />
                </FormGroup>
                <i className="fa-brands fa-linkedin" />

                {linkedin && (
                  <div className="user-social-settings__platform-profile">
                    {linkedin.displayName && (
                      <a
                        href={`https://linkedin.com/${linkedin.displayName.replace(/\s/g, '')}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {linkedin.displayName}
                      </a>
                    )}
                    {linkedin.picture && (
                      <div>
                        <img src={linkedin.picture} alt="profile" />
                      </div>
                    )}
                  </div>
                )}
              </Col>

              <Col md={8}>
                {linkedin && (
                  <LinkedInSettings
                    linkedin={linkedin}
                    onChange={async (e) => {
                      // Set this organization as active
                      const organizationId = e.target.value;
                      linkedin.organizations.forEach((organization) => {
                        organization.isActive =
                          organization.id === organizationId ? true : undefined;
                      });
                      setLinkedin({
                        ...linkedin,
                      });
                    }}
                  />
                )}
              </Col>
            </Row>
          </Col>

          <Col md={{ size: 8, offset: 2 }} className="user-social-settings__platform">
            <Row>
              <Col md={4} className="user-social-settings__platform-toggle">
                {google ? (
                  <>
                    <FormGroup switch>
                      <Input
                        id="googleSwitch"
                        type="switch"
                        name="google"
                        defaultChecked
                        onChange={() => {
                          disconnectSocialService('google', setGoogle);
                        }}
                      />
                    </FormGroup>
                    <img className="google-logo" src="/img/google_logo.png" alt="Google logo" />

                    <div className="user-social-settings__platform-profile">
                      <div>{google.name}</div>
                      {google.picture && (
                        <div>
                          <img src={google.picture} alt="profile" />
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <span
                      className="google-button"
                      onClick={() => {
                        window.location.href = `/api/google/connect?token=${token}`;
                      }}
                    />

                    <i
                      className="fa-solid fa-circle-info"
                      data-tip="Ensure you check all permissions boxes, otherwise Storiful will not be able to fetch your Calendar Events"
                    />
                  </>
                )}
              </Col>
              <Col md={8}>
                {google && (
                  <GoogleSettings
                    google={google}
                    onChange={async (calendarId, value) => {
                      // Set this calendar as active
                      google.calendars.forEach((calendar) => {
                        if (calendar.id === calendarId) {
                          calendar.isActive = value;
                        }
                      });
                      setGoogle({ ...google });
                    }}
                  />
                )}
              </Col>
            </Row>
          </Col>

          {user.flags?.[FeatureFlags.ENABLE_TIKTOK] && (
            <Col md={{ size: 8, offset: 2 }} className="user-social-settings__platform">
              <Row>
                <Col md={4} className="user-social-settings__platform-toggle">
                  <FormGroup switch>
                    <Input
                      id="tiktokSwitch"
                      type="switch"
                      name="tiktok"
                      defaultChecked={tiktok}
                      onChange={() => {
                        if (tiktok) {
                          disconnectSocialService('tiktok', setLinkedin);
                        } else {
                          window.location.href = `/api/tiktok/connect?token=${token}`;
                        }
                      }}
                    />
                  </FormGroup>
                  <i className="fa-brands fa-tiktok" />

                  {tiktok && (
                    <div className="user-social-settings__platform-profile">
                      <div>{tiktok.name}</div>
                      {tiktok.picture && (
                        <div>
                          <img src={tiktok.picture} alt="profile" />
                        </div>
                      )}
                    </div>
                  )}
                </Col>
                <Col md={8}>
                  {tiktok && (
                    <GoogleSettings
                      google={google}
                      onChange={async (calendarId, value) => {
                        // Set this calendar as active
                        google.calendars.forEach((calendar) => {
                          if (calendar.id === calendarId) {
                            calendar.isActive = value;
                          }
                        });
                        setGoogle({ ...google });
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Button size="sm" color="primary" className="cta" onClick={updateUser}>
          Save
        </Button>
        <hr />
      </Col>
    </Row>
  );
};

export default UserSocialSettings;
