import axios from 'axios';
import { store, useSetStoreValue, useStoreValue } from 'react-context-hook';
import { storeMap, SocialPostPlatformOrder, SocialPostStatus } from './constants';

const LINK_UNFURL_CACHE = {};

const useSocialPosts = () => {
  const setSocialPostsById = useSetStoreValue(storeMap.SOCIAL_POSTS_BY_ID);
  const token = useStoreValue(storeMap.TOKEN);

  const fetch = async (params = {}) => {
    if (!token) {
      return;
    }

    let response;
    try {
      response = await axios.get(`/api/social-posts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      });
    } catch (error) {
      // window.flash('Error fetching SocialPosts', 'danger');
      return;
    }

    const socialPosts = response.data;
    const socialPostsById = store.getState()[storeMap.SOCIAL_POSTS_BY_ID] || {};
    socialPosts.forEach((socialPost) => {
      const existingSocialPost = socialPostsById[socialPost._id] || {};
      socialPostsById[socialPost._id] = { ...existingSocialPost, ...socialPost };
    });

    setSocialPostsById({ ...socialPostsById });
  };

  const fetchOne = async (socialPostId) => {
    if (!token) {
      return;
    }

    let response;
    try {
      response = await axios.get(`/api/social-posts/${socialPostId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      // window.flash('Error fetching SocialPosts', 'danger');
      return;
    }

    const socialPost = response.data;
    const socialPostsById = store.getState()[storeMap.SOCIAL_POSTS_BY_ID] || {};
    socialPostsById[socialPost._id] = socialPost;
    setSocialPostsById({ ...socialPostsById });
  };

  const unfurl = async (socialPostId, url) => {
    let socialPostsById = store.getState()[storeMap.SOCIAL_POSTS_BY_ID] || {};
    if (!token || !socialPostsById[socialPostId]) {
      return;
    }

    if (LINK_UNFURL_CACHE[url]) {
      socialPostsById[socialPostId].linkInfo = LINK_UNFURL_CACHE[url];
      setSocialPostsById({ ...socialPostsById });
      return;
    }

    // Fetch unfurled link
    let response;
    try {
      response = await axios.get(`/api/contents/unfurl?url=${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      // window.flash('Error unfurling link', 'warning');
      return;
    }

    const linkInfo = response.data;
    socialPostsById = store.getState()[storeMap.SOCIAL_POSTS_BY_ID] || {};
    LINK_UNFURL_CACHE[url] = linkInfo;
    socialPostsById[socialPostId].linkInfo = linkInfo;
    setSocialPostsById({ ...socialPostsById });
  };

  const fetchSocialPostStats = async ({ platformId, platform }) => {
    if (!token) {
      return null;
    }

    let response;
    try {
      response = await axios.get(`/api/${platform}/${platformId}/stats/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      // window.flash('Error fetching SocialPosts', 'danger');
      return null;
    }

    return response.data;
  };

  const upsert = async (updatedSocialPost, persist = false) => {
    let socialPostsById = store.getState()[storeMap.SOCIAL_POSTS_BY_ID] || {};

    const socialPost = {
      ...socialPostsById[updatedSocialPost._id],
      ...updatedSocialPost,
    };

    if (socialPost._id) {
      socialPostsById[socialPost._id] = socialPost;
    }

    if (persist) {
      let response;
      try {
        response = await axios.put(`/api/social-posts/${socialPost._id || ''}`, socialPost, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        const errorMessage = error.response?.status === 409 ? 'Duplicate Social Post' : '';
        window.flash(`Error scheduling Social Post ${errorMessage}`, 'danger');
        return false;
      }

      const persistedSocialPost = response.data;
      socialPostsById = store.getState()[storeMap.SOCIAL_POSTS_BY_ID] || {};
      socialPostsById[persistedSocialPost._id] = persistedSocialPost;
      window.flash(`Social Post saved!`, 'success');
    }

    setSocialPostsById({ ...socialPostsById });

    return true;
  };

  const deleteSocialPost = async (socialPost) => {
    if (!socialPost.platformPosted) {
      try {
        await axios.delete(`/api/social-posts/${socialPost._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        return window.flash('Error deleting social post', 'danger');
      }
    }
    if (socialPost.status === SocialPostStatus.POSTED) {
      try {
        await axios.delete(
          `/api/${socialPost.platform}/${socialPost.data?.id}?platformUsername=${socialPost.platformUsername}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        return window.flash('Error deleting social post', 'danger');
      }
    }

    const socialPostsById = store.getState()[storeMap.SOCIAL_POSTS_BY_ID] || {};
    delete socialPostsById[socialPost._id];
    setSocialPostsById({ ...socialPostsById });
    return window.flash('Social post deleted');
  };

  // Sorts social posts into order of Facebook, Twitter, LinkedIn

  const sortByPlatform = (socialPosts) =>
    socialPosts.sort(
      ({ platform: platformA }, { platform: platformB }) =>
        SocialPostPlatformOrder[platformA] - SocialPostPlatformOrder[platformB]
    );

  return {
    fetch,
    fetchOne,
    fetchSocialPostStats,
    unfurl,
    upsert,
    deleteSocialPost,
    sortByPlatform,
  };
};

export default useSocialPosts;
export { useSocialPosts };
