import is from 'is_js';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import { useState, useEffect } from 'react';
import deselectCurrent from 'toggle-selection';
import {
  MAX_IMAGE_WIDTH,
  MAX_IMAGE_HEIGHT,
  MAX_LOGO_WIDTH,
  MAX_LOGO_HEIGHT,
  MIN_LOGO_WIDTH,
  MIN_LOGO_HEIGHT,
  MAX_IMAGE_SIZE,
} from 'util/constants';

const LINK_UNFURL_CACHE = {};

function format(message) {
  const copyKey = `${/mac os x/i.test(navigator.userAgent) ? '⌘' : 'Ctrl'}+C`;
  return message.replace(/#{\s*key\s*}/g, copyKey);
}

function resizeBase64Image({ file, minWidth, minHeight, maxWidth, maxHeight, maxSize }) {
  return new Promise((resolve, reject) => {
    // Create a file to read the file data
    const reader = new FileReader();
    reader.onload = async () => {
      const fileData = reader.result;

      // Create an image to receive the Data URI
      const img = document.createElement('img');

      // When the event "onload" is triggered we can resize the image.
      img.onload = () => {
        if (img.width < minWidth || img.height < minHeight) {
          window.flash('Please choose a higher resolution image', 'danger');
          return reject(new Error('Image dimensions too low'));
        }

        // Create a canvas and get its context.
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Calculate the dimension furthest from the desired size
        let resizeFactor = 1;
        if (img.src.length > maxSize) {
          resizeFactor = maxSize / img.src.length;
        } else if (img.width - maxWidth > img.height - maxHeight && img.width > maxWidth) {
          resizeFactor = maxWidth / img.width;
        } else if (img.height > maxHeight) {
          resizeFactor = maxHeight / img.height;
        }

        // Resize canvas based on calculated resizeFactor
        canvas.width = img.width * resizeFactor;
        canvas.height = img.height * resizeFactor;

        // Resize the image with the canvas method drawImage();
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        return canvas.toBlob((blob) => {
          resolve(blob);
        }, file.type);
      };

      // Set image src to imageData
      img.src = fileData;
    };

    reader.readAsDataURL(file);
  });
}

const Helpers = {
  canEditContent(content, user) {
    // TODO: Sort out logic for editing if owner
    return content?.collectionIds?.some(
      (collectionId) => collectionId === user.privateCollectionId
    );
  },
  copyToClipboard(text, options) {
    let message;
    let reselectPrevious;
    let range;
    let selection;
    let mark;
    let success = false;
    if (!options) {
      options = {};
    }

    if (options.ie || is.ie()) {
      try {
        window.clipboardData.setData('text', text);
        success = true;
      } catch (err) {
        message = format(
          'message' in options ? options.message : 'Copy to clipboard: #{key}, Enter'
        );
        throw new Error(message);
      }
    } else {
      try {
        reselectPrevious = deselectCurrent();

        range = document.createRange();
        selection = document.getSelection();

        mark = document.createElement('span');
        mark.textContent = text;
        mark.setAttribute(
          'style',
          [
            // reset user styles for span element
            'all: unset',
            // prevents scrolling to the end of the page
            'position: fixed',
            'top: 0',
            'clip: rect(0, 0, 0, 0)',
            // used to preserve spaces and line breaks
            'white-space: pre',
            // do not inherit user-select (it may be `none`)
            '-webkit-user-select: text',
            '-moz-user-select: text',
            '-ms-user-select: text',
            'user-select: text',
          ].join(';')
        );

        document.body.appendChild(mark);

        range.selectNode(mark);
        selection.addRange(range);

        const successful = document.execCommand('copy');
        if (!successful) {
          throw new Error('copy command was unsuccessful');
        }
        success = true;
      } catch (err) {
        throw new Error(err);
      } finally {
        if (selection) {
          if (typeof selection.removeRange === 'function') {
            selection.removeRange(range);
          } else {
            selection.removeAllRanges();
          }
        }

        if (mark) {
          document.body.removeChild(mark);
        }
        reselectPrevious();
      }
    }

    window.flash(options.message || 'Copied! Now paste into the Post Form', 'success');

    return success;
  },
  getPlatformUsername: (user = {}, platform = null) => {
    switch (platform) {
      case 'facebook':
        const activePage = _.find(user.facebook?.pages || [], (page) => page.isActive) || {};
        return activePage.name;
      case 'instagram':
        const activeAccount =
          _.find(user.instagram?.accounts || [], (account) => account.isActive) || {};
        return activeAccount.username;
      case 'twitter':
        return user.twitter?.displayName;
      case 'linkedin':
        const activeOrganization =
          _.find(user.linkedin?.organizations || [], (organization) => organization.isActive) || {};
        return activeOrganization.name;
      default:
        return null;
    }
  },
  getMonday(d) {
    d = moment(d).utc().toDate();
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  },
  getTimezoneAbbreviation: () => {
    let result = 'unknown';

    try {
      // Chrome, Firefox
      result = /.*\s(.+)/.exec(
        new Date().toLocaleDateString(navigator.language, { timeZoneName: 'short' })
      )[1];
    } catch (err) {
      // IE, some loss in accuracy due to guessing at the abbreviation
      // Note: This regex adds a grouping around the open paren as a
      //       workaround for an IE regex parser bug

      result = new Date().toTimeString().match(new RegExp('[A-Z](?!.*[(])', 'g')).join('');
    }

    return result;
  },
  isMobile: () => is.mobile(),
  isDev: () => process.env.REACT_APP_ENVIRONMENT === 'dev',
  isProd: () => process.env.REACT_APP_ENVIRONMENT === 'prod',
  isTest: () => process.env.NODE_ENV === 'test',
  randomTime: (startTime, endTime) => {
    const diff = endTime.getTime() - startTime.getTime();
    const newDiff = diff * Math.random();
    return new Date(startTime.getTime() + newDiff);
  },
  unfurl: async ({ token, url }) => {
    if (!LINK_UNFURL_CACHE[url]) {
      // Fetch unfurled content
      let response;
      try {
        response = await axios.get(`/api/contents/unfurl?url=${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        return null;
      }

      const linkInfo = response.data;
      LINK_UNFURL_CACHE[url] = linkInfo;
    }

    return LINK_UNFURL_CACHE[url];
  },
  uploadFirmLogo: async ({ token, file }) => {
    // Check dimensions and resize image
    const data = await resizeBase64Image({
      file,
      maxWidth: MAX_LOGO_WIDTH,
      maxHeight: MAX_LOGO_HEIGHT,
      minWidth: MIN_LOGO_WIDTH,
      minHeight: MIN_LOGO_HEIGHT,
    });
    file = new File([data], file.name, { type: file.type });

    // Upload to S3
    const uploadUrlResponse = await axios.post(
      `/api/users/logo-upload`,
      { key: file.name, contentType: file.type },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Handle invalid creation
    if (uploadUrlResponse.status !== 200) {
      window.flash('Error getting upload Url', 'danger');
      throw new Error('Error getting upload Url');
    }

    const { uploadUrl, accessUrl } = uploadUrlResponse.data;

    // Post data to S3
    await axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });

    return accessUrl;
  },
  uploadUserMedia: async (file, token) => {
    let resizedFile = file;
    const isImage = file.type.startsWith('image/');

    if (isImage && file.size > MAX_IMAGE_SIZE) {
      resizedFile = await resizeBase64Image({
        file,
        maxSize: MAX_IMAGE_SIZE,
      });
    }

    // Get AWS uploadURL to post file to
    const uploadUrlResponse = await axios.post(
      `/api/social-posts/files/upload`,
      { key: file.name, contentType: resizedFile.type },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Handle invalid creation
    if (uploadUrlResponse.status !== 200) {
      window.flash(`Error uploading file. Please try again!`, 'danger');
      return null;
    }

    const { uploadUrl, accessUrl } = uploadUrlResponse.data;

    // Post file data to S3
    try {
      await axios.put(uploadUrl, resizedFile, {
        headers: {
          'Content-Type': resizedFile.type,
        },
      });
    } catch (error) {
      window.flash(`Error uploading file. Please try again!`, 'danger');
      return null;
    }

    return accessUrl;
  },
  useDebounce: (value, delay = 1000) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
      () => {
        // Set debouncedValue to value (passed in) after the specified delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);

        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      // Only re-call effect if value changes
      // You could also add the "delay" var to inputs array if you ...
      // ... need to be able to change that dynamically.
      [value, delay]
    );

    return debouncedValue;
  },
  watermarkImage: async (originalImagePath, watermarkImagePath) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    const originalImage = new Image();
    originalImage.crossOrigin = 'anonymous';
    await new Promise(
      // eslint-disable-next-line
      (resolve) => (originalImage.onload = resolve),
      (originalImage.src = `${originalImagePath}?${escape(new Date())}`)
    );

    const canvasWidth = originalImage.width;
    const canvasHeight = originalImage.height;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    // initializing the canvas with the original image
    context.drawImage(originalImage, 0, 0, canvasWidth, canvasHeight);

    // loading the watermark image and transforming it into a pattern
    const watermarkImage = new Image();
    watermarkImage.crossOrigin = 'Anonymous';
    await new Promise(
      // eslint-disable-next-line
      (resolve) => (watermarkImage.onload = resolve),
      (watermarkImage.src = `${watermarkImagePath}?${escape(new Date())}`)
    );

    // Calculate watermark scaling to be 80px tall
    const scalingFactor = 80 / (watermarkImage.height || 80);
    // Draw watermark image to the bottom left corner
    context.drawImage(
      watermarkImage,
      10,
      canvasHeight - 90,
      watermarkImage.width * scalingFactor,
      watermarkImage.height * scalingFactor
    );

    const base64Image = canvas.toDataURL();
    const filename = originalImagePath.slice(originalImagePath.lastIndexOf('/') + 1);
    const arr = base64Image.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  },
};

export default Helpers;
