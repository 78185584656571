import axios from 'axios';
import { store, useStoreValue, useSetStoreValue } from 'react-context-hook';
import { storeMap } from './constants';
import Helpers from 'client-helpers';

const useRSSFeeds = () => {
  const token = useStoreValue(storeMap.TOKEN);
  const user = useStoreValue(storeMap.USER);
  const setRSSContentsById = useSetStoreValue(storeMap.RSS_CONTENTS_BY_ID);

  const fetch = async ({ startDttm, endDttm, feedName }) => {
    if (!token) {
      return [];
    }

    let response;
    try {
      response = await axios.get(`/api/contents/rss-content`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { startDttm, endDttm, feedName },
      });
    } catch (error) {
      window.flash('Error fetching RSS content', 'warning');
      return [];
    }

    const RSSContents = response.data || [];
    const RSSContentsById = {};

    RSSContents.forEach((content) => {
      RSSContentsById[content.id] = content;
    });

    setRSSContentsById(RSSContentsById);

    return RSSContentsById;
  };

  const unfurl = async (contentId, url) => {
    let RSSContentsById = store.getState()[storeMap.RSS_CONTENTS_BY_ID] || {};
    if (!token || !RSSContentsById[contentId]) {
      return;
    }

    const linkInfo = await Helpers.unfurl({ token, url });

    RSSContentsById = store.getState()[storeMap.RSS_CONTENTS_BY_ID] || {};
    if (!RSSContentsById[contentId]) {
      return;
    }

    RSSContentsById[contentId].link = url;
    RSSContentsById[contentId].linkInfo = linkInfo;
    setRSSContentsById({ ...RSSContentsById });
  };

  return {
    fetch,
    unfurl,
  };
};

export default useRSSFeeds;
export { useRSSFeeds };
