import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useStore, useStoreValue } from 'react-context-hook';
import withSmoothLoad from 'components/withSmoothLoad';
import { storeMap } from 'services/constants';
import { Row, Col } from 'reactstrap';
import Helpers from 'client-helpers';
import CalendarEventCard from 'components/CalendarEventCard';

import './CalendarEventPreview.css';

const CalendarEventPreview = () => {
  const user = useStoreValue(storeMap.USER);
  const [selectedCalendarEvent, setSelectedCalendarEvent] = useStore(
    storeMap.SELECTED_CALENDAR_EVENT
  );
  const [stubSocialPost, setStubSocialPost] = useStore(storeMap.STUB_SOCIAL_POST);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, [selectedCalendarEvent]);

  // Copy Calendar Event to Content
  // const copyToContent = async () => {
  //   const contentCopy = await Contents.create({
  //     ...content,
  //     collectionIds: [user.privateCollectionId],
  //     copiedFrom: content._id,
  //     _id: undefined,
  //     createdAt: undefined,
  //     updatedAt: undefined,
  //   });

  //   if (contentCopy) {
  //     setSelectedContentId(contentCopy._id);
  //     setViewingContentId(contentCopy._id);
  //   }
  // };

  return (
    <div className="calendar-event-preview">
      <Row className="calendar-event-preview__buttons">
        <Col>
          <i
            className="fa-solid fa-feather-pointed"
            data-tip="Copy to Social Post"
            onClick={() => {
              if (selectedCalendarEvent.description) {
                Helpers.copyToClipboard(selectedCalendarEvent.description);
              }
            }}
          />

          <i className="fa-solid fa-times" onClick={() => setSelectedCalendarEvent()} />
        </Col>
      </Row>
      <CalendarEventCard {...selectedCalendarEvent} showUnfurl showExpand />
    </div>
  );
};

export default withSmoothLoad(CalendarEventPreview);
