import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Row, Col, Button, FormGroup, Label, FormText, Input } from 'reactstrap';
import TextInput from 'components/TextInput';
import { MAX_IMAGE_SIZE, EMAIL_REGEX } from 'util/constants';
import Helpers from 'client-helpers';
import { FeatureFlags, storeMap, UserStatus } from 'services/constants';
import { useUsers } from 'services/Users';
import { useStoreValue } from 'react-context-hook';

import './UserProfileForm.css';

const UserProfileForm = ({ user, allowAdminUpdate, onUpdate, onCancel }) => {
  const Users = useUsers();

  const token = useStoreValue(storeMap.TOKEN);
  const [updatedUser, setUpdatedUser] = React.useState(user);

  const onChange = (e) => {
    setUpdatedUser({
      ...updatedUser,
      [e.target.name]: e.target.value,
      emailError: null,
    });
  };

  const onFirmLogoChange = async (e) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];

      // Check for fileSize > 10MB
      if (file.size > MAX_IMAGE_SIZE) {
        window.flash('File must be less than 10MB', 'warning');
        return;
      }

      const firmLogoUrl = await Helpers.uploadFirmLogo({ token, file });

      setUpdatedUser({
        ...updatedUser,
        firmLogoUrl,
      });
    }
  };

  const updateUser = async () => {
    const userKeys = [
      'firstName',
      'lastName',
      'firmName',
      'firmLogoUrl',
      'firmWebsiteUrl',
      'status',
      'collectionIds',
      'zip',
    ];
    const existingUser = user;
    const fieldsToUpdate = { _id: existingUser._id };

    // Handle email change
    if (updatedUser.email !== existingUser.email && (await Users.userExists(updatedUser.email))) {
      setUpdatedUser({ updatedUser, emailError: 'Email already in use' });
      return;
    }

    // Handle string properties
    userKeys.forEach((key) => {
      if (updatedUser[key] !== existingUser[key]) {
        fieldsToUpdate[key] = updatedUser[key];
      }
    });

    // Handle password changes
    if (
      updatedUser.password &&
      updatedUser.newPassword &&
      updatedUser.newPassword === updatedUser.confirmNewPassword
    ) {
      fieldsToUpdate.password = updatedUser.newPassword;
    }

    // Handle feature flag updates
    if (!_.isEqual(updatedUser.flags, existingUser.flags)) {
      fieldsToUpdate.flags = updatedUser.flags;
    }

    const resolvedUser = await Users.update(fieldsToUpdate);

    if (!resolvedUser) {
      window.flash('Error updating user', 'danger');
      return;
    }

    window.flash('Updated user settings', 'success');
    onUpdate(resolvedUser);
  };

  const {
    email,
    status,
    password,
    newPassword,
    confirmNewPassword,
    firstName,
    lastName,
    firmName,
    firmLogoUrl,
    firmWebsiteUrl,
    collectionIds,
    emailError,
    zip,
    flags,
  } = updatedUser;

  const emailInvalid = !email || !EMAIL_REGEX.test(email);
  const passwordInvalid = !!(password && newPassword && newPassword !== confirmNewPassword);

  return (
    <Row className="user-profile-form">
      <Col>
        <h3 className="line-text">Profile</h3>

        <Row>
          <Col md={{ size: 5, offset: 1 }}>
            <Row>
              <Col md={6}>
                <TextInput
                  type="email"
                  name="email"
                  value={email}
                  onChange={onChange}
                  invalid={emailInvalid}
                  feedback={emailError}
                  required
                />
              </Col>

              <Col md={6}>
                <TextInput
                  type="password"
                  name="password"
                  displayText="Current Password"
                  value={password}
                  onChange={onChange}
                  helperText="Only enter a password if you want to change it"
                  invalid={passwordInvalid}
                  autoComplete="off"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextInput
                  type="password"
                  name="newPassword"
                  displayText="New Password"
                  value={newPassword}
                  onChange={onChange}
                  invalid={passwordInvalid}
                />
              </Col>

              <Col md={6}>
                <TextInput
                  type="password"
                  name="confirmNewPassword"
                  displayText="Confirm New Password"
                  value={confirmNewPassword}
                  onChange={onChange}
                  invalid={passwordInvalid}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <TextInput
                  name="firstName"
                  displayText="First Name"
                  value={firstName}
                  onChange={onChange}
                />
              </Col>
              <Col md={6}>
                <TextInput
                  name="lastName"
                  displayText="Last Name"
                  value={lastName}
                  onChange={onChange}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextInput name="zip" value={zip} displayText="Zip code" onChange={onChange} />
              </Col>
            </Row>

            {allowAdminUpdate && (
              <>
                <FormGroup floating>
                  <Input
                    type="select"
                    name="status"
                    id="statusSelect"
                    onChange={onChange}
                    defaultValue={status}
                  >
                    <option value={UserStatus.ADMIN}>Admin</option>
                    <option value={UserStatus.DEMO}>Demo</option>
                    <option value={UserStatus.ACTIVE}>Paid</option>
                    <option value={UserStatus.CANCELED}>Cancelled</option>
                  </Input>
                  <Label for="statusSelect">Status</Label>
                </FormGroup>

                <p>Collections</p>
                <FormGroup floating>
                  <Input
                    type="text"
                    name="collectionIds"
                    id="collectionIds"
                    placeholder="ID1,ID2,ID3"
                    onChange={(e) => {
                      const value = e.target.value?.split(',');
                      setUpdatedUser({
                        ...updatedUser,
                        collectionIds: value,
                      });
                    }}
                    defaultValue={collectionIds}
                  />
                  <Label for="collectionIds">CollectionIds</Label>
                  <FormText color="muted">Comma separated list</FormText>
                </FormGroup>
              </>
            )}
          </Col>
          <Col md={{ size: 5 }}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Row className="firm-logo-row">
                    <Col>
                      <p>Update Company logo</p>
                      {!!firmLogoUrl && (
                        <img className="firm-logo" src={firmLogoUrl} alt="Company Logo" />
                      )}

                      <Input
                        bsSize="sm"
                        type="file"
                        accept="image/*"
                        name="firmLogoUrl"
                        onChange={onFirmLogoChange}
                      />
                      <FormText color="muted">Acceptable formats: png, jpg, svg</FormText>
                      <FormText color="muted">Resolution should be at least 256px X 256px</FormText>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md={6}>
                <p>Video Render Preview</p>
                <FormText color="muted">Approximate placement of logo and website</FormText>
                <div className="video-render-preview">
                  {!!firmLogoUrl && <img src={firmLogoUrl} alt="Company Logo" />}
                  <div className="firm-website-url">{firmWebsiteUrl}</div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <TextInput
                  name="firmName"
                  displayText="Company Name"
                  value={firmName}
                  onChange={onChange}
                />
              </Col>
              <Col md={6}>
                <TextInput
                  name="firmWebsiteUrl"
                  value={firmWebsiteUrl}
                  displayText="Company Website"
                  onChange={onChange}
                />
              </Col>
            </Row>

            {allowAdminUpdate && (
              <>
                <p>Feature Flags</p>
                {Object.keys(FeatureFlags).map((featureFlag) => (
                  <FormGroup check inline key={featureFlag}>
                    <Label for={FeatureFlags[featureFlag]}>{FeatureFlags[featureFlag]}</Label>

                    <Input
                      type="checkbox"
                      name={FeatureFlags[featureFlag]}
                      id={FeatureFlags[featureFlag]}
                      onChange={(e) => {
                        setUpdatedUser({
                          ...updatedUser,
                          flags: {
                            ...flags,
                            [FeatureFlags[featureFlag]]: e.target.checked,
                          },
                        });
                      }}
                      checked={!!flags?.[FeatureFlags[featureFlag]]}
                    />
                  </FormGroup>
                ))}
              </>
            )}
          </Col>
        </Row>

        <Button
          size="sm"
          color="primary"
          onClick={updateUser}
          disabled={emailInvalid || passwordInvalid}
        >
          Save
        </Button>

        <hr />

        {onCancel && (
          <Button color="default" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </Col>
    </Row>
  );
};

UserProfileForm.propTypes = {
  user: PropTypes.shape({}).isRequired,
  onUpdate: PropTypes.func.isRequired,
  allowAdminUpdate: PropTypes.bool,
  onCancel: PropTypes.func,
};

UserProfileForm.defaultProps = {
  allowAdminUpdate: false,
  onCancel: null,
};

export default UserProfileForm;
