import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useCollections } from 'services/Collections';
import Autolinker from 'autolinker';

import './CollectionPreviewModal.css';

const CollectionPreviewModal = ({ collectionId, onClose }) => {
  const Collections = useCollections();
  const [contents, setContents] = React.useState([]);

  React.useEffect(() => {
    Collections.fetchExampleContent(collectionId).then((exampleContents) => {
      exampleContents.forEach((content) => {
        if (!content.videoUrl && !content.link && content.text) {
          const links = Autolinker.parse(content.text);
          content.link = links.find((iLink) => iLink.url)?.url;
        }
      });
      setContents(exampleContents);
    });
  }, []);

  // Generate link previews
  React.useEffect(() => {
    const unfurlLinks = async () => {
      const unfurledContents = [];
      for (const content of contents) {
        if (!content.videoUrl && content.link) {
          if (content.link && !content.linkInfo) {
            // eslint-disable-next-line no-await-in-loop
            const linkInfo = await Collections.unfurl(content.link);
            content.linkInfo = linkInfo;
          }
        }
        unfurledContents.push(content);
      }
      setContents(unfurledContents);
    };

    unfurlLinks();
  }, [contents.length]);

  return (
    <Modal className="collection-preview-modal" isOpen toggle={() => onClose()}>
      <ModalHeader toggle={() => onClose()}>Collection Preview</ModalHeader>
      <ModalBody className="dashboard">
        {contents.map(({ _id, title, text, imageUrl, videoUrl, link, linkInfo }) => (
          <div className="text-option" key={_id}>
            {title && <div className="title">{title}</div>}
            {text && <div>{text}</div>}
            {linkInfo?.description && (
              <div onClick={() => window.open(link, '_blank')}>
                <div className="line-text">
                  <span className="subtle-text">Link preview</span>
                </div>
                {linkInfo.title && <h6>{linkInfo.title}</h6>}
                {linkInfo.description}
                {linkInfo.imageUrl && <img src={linkInfo.imageUrl} alt="Link" />}
              </div>
            )}
            {imageUrl && (
              <div>
                <img src={imageUrl} alt={imageUrl} />
              </div>
            )}
            {videoUrl && (
              <video
                className="video-thumbnail"
                controls
                disablePictureInPicture
                controlsList="nodownload"
                preload="metadata"
              >
                <source src={videoUrl} type="video/mp4" />
              </video>
            )}
            <hr />
          </div>
        ))}
      </ModalBody>
    </Modal>
  );
};

CollectionPreviewModal.propTypes = {
  collectionId: PropTypes.string,
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
    })
  ),
  onClose: PropTypes.func.isRequired,
};

CollectionPreviewModal.defaultProps = {
  collectionId: '',
  contents: [],
};

export default CollectionPreviewModal;
