import React from 'react';
import _ from 'lodash';
import { useStoreValue } from 'react-context-hook';
import { Row, Col, Button } from 'reactstrap';
import { useUsers } from 'services/Users';
import User from './User';
import UserProfileForm from 'components/UserProfileForm';
import NewUserForm from './NewUserForm';
import TextInput from 'components/TextInput';
import './UserEditor.css';
import 'react-quill/dist/quill.snow.css';
import { storeMap } from 'services/constants';

const UserEditor = () => {
  const Users = useUsers();
  const token = useStoreValue(storeMap.TOKEN);
  const [users, setUsers] = React.useState([]);
  const [editingUserId, setEditingUserId] = React.useState();
  const [filter, setFilter] = React.useState();

  const onFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const impersonateUser = async (e) => {
    const userId = _.get(e, 'target.dataset.id');

    const user = await Users.impersonate(userId);

    if (!user || !user.token) {
      window.flash('Error impersonating user', 'warning');
      return;
    }

    localStorage.setItem('token', user.token);
    window.location.reload();
  };

  const editUser = (e) => {
    setEditingUserId(e.target.dataset.id);
  };

  const cancelEdit = () => {
    setEditingUserId(null);
  };

  const fetchUsers = async () => {
    // Fetch user data
    const fetchedUsers = await Users.fetch();

    setUsers(fetchedUsers);
  };

  const onUpdate = () => {
    fetchUsers();
    cancelEdit();
  };

  React.useEffect(() => {
    fetchUsers();
  }, []);

  const filteredUsers = users.filter(
    (user) =>
      !filter ||
      user.email.includes(filter) ||
      user.firstName?.includes(filter) ||
      user.lastName?.includes(filter)
  );

  return (
    <div className="user-editor">
      <h2>User Editor</h2>

      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <TextInput name="filter" displayText="Filter" value={filter} onChange={onFilterChange} />
        </Col>
      </Row>

      <Row>
        <Col>
          {editingUserId === 'new' ? (
            <NewUserForm onCreate={fetchUsers} onCancel={cancelEdit} />
          ) : (
            <Button
              size="sm"
              color="success"
              onClick={() => {
                setEditingUserId('new');
              }}
            >
              New Demo Account
            </Button>
          )}
        </Col>
      </Row>

      <hr />

      <Row>
        {filteredUsers.map((user) => {
          const isEditing = user._id === editingUserId;

          if (isEditing) {
            return (
              <UserProfileForm
                key={user._id}
                token={token}
                user={user}
                onUpdate={onUpdate}
                onCancel={cancelEdit}
                allowAdminUpdate
              />
            );
          }

          return (
            <Col key={user._id} md={4}>
              <User {...user} />
              <div className="button-container">
                <Button size="sm" color="success" onClick={impersonateUser} data-id={user._id}>
                  Impersonate
                </Button>
                <Button size="sm" color="default" onClick={editUser} data-id={user._id}>
                  Edit
                </Button>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default UserEditor;
