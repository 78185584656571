import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import moment from 'moment';
import Helpers from 'client-helpers';
import ReactTooltip from 'react-tooltip';
import ReactDatePicker from 'react-datepicker';
import { useStoreValue } from 'react-context-hook';
import { storeMap } from 'services/constants';
import { Row, Col, Button, FormGroup, Label, Input } from 'reactstrap';

import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

const SupportArticleForm = ({ supportArticle, createOrUpdateSupportArticle, onCancel }) => {
  const token = useStoreValue(storeMap.TOKEN);
  const [editingSupportArticle, setEditingSupportArticle] = React.useState(supportArticle);
  const [isUploadingFile, setIsUploadingFile] = React.useState(false);
  const attachVideoRef = React.createRef();

  const handleChange = (value) => {
    setEditingSupportArticle({ ...editingSupportArticle, body: value });
  };

  const saveUpdatedSupportArticle = () => {
    createOrUpdateSupportArticle(editingSupportArticle);
  };

  const onFileSelect = async (event) => {
    const file = event.currentTarget?.files?.[0];

    if (!file) {
      return;
    }

    // Janky AF but we have to remove spaces here
    Object.defineProperty(file, 'name', {
      writable: true,
      value: file.name.replace(/ /g, '_'),
    });

    setIsUploadingFile(true);
    const fileUrl = await Helpers.uploadUserMedia(file, token);
    setIsUploadingFile(false);

    setEditingSupportArticle({
      ...editingSupportArticle,
      videoUrl: fileUrl,
    });
  };

  return (
    <Row>
      <Col key={supportArticle._id} className="support-article">
        <ReactTooltip effect="solid" />

        <Row>
          <Col md={2}>
            <b>Id:</b> {supportArticle._id}
          </Col>
          <Col md={1}>
            <input
              className="attach-video-input"
              type="file"
              accept="video/*"
              ref={attachVideoRef}
              onChange={(e) => onFileSelect(e, 'video')}
            />

            {editingSupportArticle.videoUrl ? (
              <i
                className="fa-solid fa-video attached"
                data-tip="Remove video"
                disabled={editingSupportArticle.videoUrl}
                onClick={() => {
                  delete editingSupportArticle.videoUrl;
                  setEditingSupportArticle({ ...editingSupportArticle });
                }}
              />
            ) : (
              <i
                className={`fa-solid ${isUploadingFile ? 'fa-spin fa-circle-o-notch' : 'fa-video'}`}
                data-tip="Attach video"
                disabled={editingSupportArticle.videoUrl}
                onClick={() => {
                  attachVideoRef.current?.click();
                }}
              />
            )}
          </Col>
          <Col md={3}>
            <FormGroup inline>
              <Label>CreatedAt</Label>
              <ReactDatePicker
                selected={moment.utc(editingSupportArticle.createdAt).toDate()}
                onChange={(date) =>
                  setEditingSupportArticle({
                    ...editingSupportArticle,
                    createdAt: date.toUTCString(),
                  })
                }
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            {' '}
            <FormGroup floating>
              <Input
                bsSize="sm"
                type="text"
                name="title"
                id="titleField"
                placeholder="Title"
                value={editingSupportArticle.title || ''}
                onChange={(event) =>
                  setEditingSupportArticle({ ...editingSupportArticle, title: event.target.value })
                }
                invalid={!editingSupportArticle.title}
                required
              />

              <Label for="titleField">Title*</Label>
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <ReactQuill
            value={editingSupportArticle.body || ''}
            onChange={handleChange}
            modules={modules}
            formats={formats}
          />
        </FormGroup>

        <div>
          <Button
            size="sm"
            color="success"
            disabled={isUploadingFile}
            onClick={saveUpdatedSupportArticle}
          >
            {supportArticle._id ? 'Save' : 'Create'}
          </Button>
          {onCancel && (
            <Button size="sm" color="default" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </div>
      </Col>
    </Row>
  );
};

SupportArticleForm.propTypes = {
  supportArticle: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string,
    createdAt: PropTypes.string,
  }),
  createOrUpdateSupportArticle: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

SupportArticleForm.defaultProps = {
  supportArticle: {
    _id: null,
    title: '',
    body: '',
    createdAt: '',
  },
  onCancel: null,
};

export default SupportArticleForm;
