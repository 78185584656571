import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import ContentExportModal from './ContentExportModal';

const ContentExportModalWithData = (props) =>
  new Promise((resolve) => {
    let el = document.createElement('div');

    const handleResolve = (result) => {
      unmountComponentAtNode(el);
      el = null;
      resolve(result);
    };

    render(<ContentExportModal {...props} onClose={handleResolve} />, el);
  });

export default ContentExportModalWithData;
