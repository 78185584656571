import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Page, Text, Image, View, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    display: 'block',
    backgroundColor: '#FFF',
    padding: 15,
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginBottom: 15,
  },
  sectionBlock: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  hr: {
    alignSelf: 'stretch',
    margin: 15,
    width: '95%',
    borderBottomColor: '#00CED1',
    borderBottomWidth: 1,
  },
  text: {
    h1: {
      margin: 15,
      fontSize: 28,
      textAlign: 'center',
    },
    normal: {
      fontSize: 12,
    },
    platform: {
      fontSize: 10,
      textTransform: 'capitalize',
      marginBottom: 10,
    },
    post: {
      fontSize: 8,
    },
    small: {
      fontSize: 8,
    },
  },
});

// Create Document Component
const ContentExportPDF = ({ user, startDate, endDate, socialPostsByDate, jobsById }) => {
  const networks = [];

  if (user.facebook) {
    networks.push('Facebook');
  }
  if (user.instagram) {
    networks.push('Instagram');
  }
  if (user.twitter) {
    networks.push('Twitter');
  }
  if (user.linkedin) {
    networks.push('LinkedIn');
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.sectionBlock}>
            <Text style={styles.text.normal}>{user.firmName}</Text>
            <Text style={styles.text.normal}>
              {user.firstName} {user.lastName}
            </Text>
            <Text style={styles.text.normal}>{user.email}</Text>
            <Text style={styles.text.normal}>{user.firmWebsiteUrl}</Text>
          </View>
          <View style={styles.sectionBlock}>
            {user.firmLogoUrl && (
              <Image
                style={{ width: 200, height: 75, position: 'absolute', right: 10, top: 10 }}
                source={user.firmLogoUrl}
              />
            )}
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.hr} />
        </View>

        <View style={{ ...styles.section, marginTop: 50 }}>
          <Text style={styles.text.h1}>Storiful Archive Report</Text>
        </View>
        <View style={{ ...styles.section, flexDirection: 'column', marginBottom: 50 }}>
          <Text
            style={{
              marginLeft: 100,
              fontSize: 16,
            }}
          >
            Date Range: {moment(startDate).format('MM/DD/YYYY')} -{' '}
            {moment(endDate).format('MM/DD/YYYY')}
          </Text>
          <Text
            style={{
              marginLeft: 100,
              fontSize: 16,
            }}
          >
            Profiles: {networks.join(', ')}
          </Text>
        </View>

        <View style={styles.section}>
          <View style={styles.hr} />
        </View>
      </Page>

      <Page style={styles.page}>
        {Object.keys(socialPostsByDate)
          .sort()
          .map((date) => {
            const socialPosts = socialPostsByDate[date] || [];

            if (!socialPosts.length) {
              return null;
            }

            const mediaUrlsByReference = {};
            let referenceId = 1;

            return (
              <View style={{ padding: 20 }} key={date}>
                <View style={styles.section}>
                  <Text>{moment(date).format('MMMM Do, YYYY')}</Text>
                </View>
                <View style={styles.section}>
                  {socialPosts.map((socialPost) => {
                    let screenshotUrl;

                    // TODO: Check if the url is already referenced and just recyle that referenceId
                    if (socialPost.data.videoUrl) {
                      mediaUrlsByReference[referenceId] = socialPost.data.videoUrl;
                      referenceId++;
                      screenshotUrl = jobsById?.[socialPost.jobId]?.screenshotUrl;
                    } else if (socialPost.data.imageUrl) {
                      mediaUrlsByReference[referenceId] = socialPost.data.imageUrl;
                      referenceId++;
                    }

                    return (
                      <View
                        key={socialPost._id}
                        style={{
                          border: 1,
                          borderColor: '#00ced1',
                          borderRadius: 16,
                          width: '100%',
                          margin: 10,
                          padding: 10,
                        }}
                      >
                        <Text style={styles.text.platform}>
                          {socialPost.platform} - {socialPost.platformPosted && 'Natively at '}
                          {moment(socialPost.data.createdAt).format('hh:mm A')}
                        </Text>

                        <Text style={styles.text.post}>{socialPost.data.text}</Text>

                        {socialPost.data.imageUrl && <Image src={socialPost.data.imageUrl} />}

                        {socialPost.data.imageUrl && (
                          <Text style={styles.text.small}>{referenceId - 1}</Text>
                        )}

                        {screenshotUrl && (
                          <View>
                            <Image
                              style={{ marginVertical: 15, marginHorizontal: 15 }}
                              src={screenshotUrl}
                            />
                            <Text style={styles.text.small}>{referenceId - 1}</Text>
                          </View>
                        )}
                      </View>
                    );
                  })}
                </View>
                {!!Object.keys(mediaUrlsByReference).length && (
                  <View style={{ ...styles.section, flexDirection: 'column' }}>
                    <Text style={styles.text.small}>Media Reference Urls:</Text>

                    {Object.keys(mediaUrlsByReference).map((i) => (
                      <Text key={i} style={styles.text.small}>
                        {i} - {mediaUrlsByReference[i]}
                      </Text>
                    ))}
                  </View>
                )}
              </View>
            );
          })}
      </Page>
    </Document>
  );
};

ContentExportPDF.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    firmName: PropTypes.string,
    firmWebsiteUrl: PropTypes.string,
    firmLogoUrl: PropTypes.string,
    facebook: PropTypes.shape({}),
    instagram: PropTypes.shape({}),
    twitter: PropTypes.shape({}),
    linkedin: PropTypes.shape({}),
  }).isRequired,
  jobsById: PropTypes.shape({}),
  startDate: PropTypes.shape({}),
  endDate: PropTypes.shape({}),
  socialPostsByDate: PropTypes.shape({}).isRequired,
};

ContentExportPDF.defaultProps = {
  jobsById: {},
  startDate: null,
  endDate: null,
};

export default ContentExportPDF;
