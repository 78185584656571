/* eslint-disable react/no-danger */
import React from 'react';
import { NavLink, Button } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import './Paywall.css';

const Paywall = ({}) => (
  <div className="paywall">
    <div className="paywall-content">
      <p>
        Your subscription is currently inactive.
        <br />
        Visit the Settings page to subscribe now!
      </p>
      <NavLink tag={RRNavLink} to="/app/settings/subscriptions">
        <Button color="primary">Settings</Button>
      </NavLink>
    </div>
  </div>
);

export default Paywall;
