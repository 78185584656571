import React from 'react';
import UserPaymentSettings from './UserPaymentSettings';
import { Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { useStoreValue } from 'react-context-hook';
import { storeMap } from 'services/constants';

const UserPaymentSettingsWithStripe = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const propsWithData = {
    ...props,
    stripe,
    elements,
  };

  if (!stripe || !elements) {
    return <i className="fa-solid fa-circle-o-notch fa-spin" />;
  }

  return <UserPaymentSettings {...propsWithData} />;
};

const UserPaymentSettingsWithElements = (props) => {
  const stripe = useStoreValue(storeMap.STRIPE);

  if (!stripe) {
    return <i className="fa-solid fa-circle-o-notch fa-spin" />;
  }

  return (
    <Elements stripe={stripe}>
      <UserPaymentSettingsWithStripe {...props} />
    </Elements>
  );
};

export default UserPaymentSettingsWithElements;
