const Shepherd = window.Shepherd;

const defaultStepOptions = {
  cancelIcon: {
    enabled: true,
  },
  classes: 'class-1 class-2',
  scrollTo: { behavior: 'smooth', block: 'center' },
};

export const socialLandingTour = () => {
  const tour = new Shepherd.Tour({
    defaultStepOptions,
    steps: [
      {
        text: 'Toggle on any of the social channels to get started',
        attachTo: {
          element: '[data-tour-facebook-switch]',
          on: 'top',
        },
      },
    ],
  });

  return tour;
};

export const socialConnectedTour = () => {
  const tour = new Shepherd.Tour({
    defaultStepOptions,
    steps: [
      {
        text: 'If needed, select the proper page or account',
        attachTo: {
          element: '.social-platform-settings',
          on: 'top',
        },
        buttons: [
          {
            text: 'Next',
            action() {
              return this.next();
            },
          },
        ],
      },
      {
        text: 'Head over to the dashboard to start posting!',
        attachTo: {
          element: '.fa-solid.fa-columns',
          on: 'top',
        },
      },
    ],
  });

  return tour;
};

export const firstPostTour = () => {
  const tour = new Shepherd.Tour({
    defaultStepOptions,
    steps: [
      {
        text: 'Click to deselect or select a Social Channel',
        attachTo: {
          element: '.platform-select',
          on: 'top',
        },
        buttons: [
          {
            text: 'Next',
            action() {
              return this.next();
            },
          },
        ],
      },
      {
        text: 'Enter the text you want to post',
        attachTo: {
          element: '.social-input textarea',
          on: 'top',
        },
        buttons: [
          {
            text: 'Next',
            action() {
              return this.next();
            },
          },
        ],
      },
      {
        text: 'Add an image or video if desired',
        attachTo: {
          element: '.attach-buttons',
          on: 'top',
        },
        buttons: [
          {
            text: 'Next',
            action() {
              return this.next();
            },
          },
        ],
      },
      {
        text: 'Click to edit the time to post',
        attachTo: {
          element: '.scheduled-text span',
          on: 'top',
        },
        buttons: [
          {
            text: 'Next',
            action() {
              return this.next();
            },
          },
        ],
      },
      {
        text: 'Click to schedule your post!',
        attachTo: {
          element: '.social-input__buttons .btn-primary',
          on: 'top',
        },
        buttons: [
          {
            text: 'Done',
            action() {
              return this.complete();
            },
          },
        ],
      },
    ],
  });

  return tour;
};
