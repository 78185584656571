import React from 'react';
import { ObjectID } from 'bson';
import Autolinker from 'autolinker';
import ReactTooltip from 'react-tooltip';
import { useSetStoreValue, useStore, useStoreValue } from 'react-context-hook';
import withSmoothLoad from 'components/withSmoothLoad';
import ContentCard from 'components/ContentCard';
import { FeatureFlags, storeMap } from 'services/constants';
import { useContents } from 'services/Contents';
import { useRSSFeeds } from 'services/RSSFeeds';
import { useJobs } from 'services/Jobs';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Helpers from 'client-helpers';

import './ContentPreview.css';

const ContentPreview = () => {
  const Jobs = useJobs();
  const Contents = useContents();
  const RSSFeeds = useRSSFeeds();
  const user = useStoreValue(storeMap.USER);
  const contentsById = useStoreValue(storeMap.CONTENTS_BY_ID, {});
  const RSSContentsById = useStoreValue(storeMap.RSS_CONTENTS_BY_ID, {});
  const [jobsById, setJobsById] = useStore(storeMap.JOBS_BY_ID, {});
  const [selectedContentId, setSelectedContentId] = useStore(storeMap.SELECTED_CONTENT_ID);
  const [stubSocialPost, setStubSocialPost] = useStore(storeMap.STUB_SOCIAL_POST, {});
  const setViewingContentId = useSetStoreValue(storeMap.VIEWING_CONTENT_ID);
  const [optionsDropdownOpen, setOptionsDropdownOpen] = React.useState(false);

  const content = contentsById[selectedContentId] || RSSContentsById[selectedContentId];
  const job = Object.values(jobsById).find((iJob) => iJob.contentId === selectedContentId);
  const canEdit = Helpers.canEditContent(content, user);

  // Unfurl links
  React.useEffect(() => {
    if (!content?.text) {
      return;
    }

    const links = Autolinker.parse(content?.text);
    const link = links.find((iLink) => iLink.url)?.url;
    if (link) {
      if (contentsById[selectedContentId]) {
        Contents.unfurl(content._id, link);
      } else {
        RSSFeeds.unfurl(content.id, link);
      }
    }
  }, [content]);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, [selectedContentId]);

  const createJob = () => {
    // create job
    const newJob = {
      _id: ObjectID().toString(),
      contentId: content?._id,
      url: content?.videoUrl,
    };

    jobsById[newJob._id] = newJob;
    setJobsById({ ...jobsById });

    return newJob;
  };

  // Copy Content users private collection
  const copyContent = async () => {
    const contentCopy = await Contents.create({
      ...content,
      collectionIds: [user.privateCollectionId],
      copiedFrom: content._id,
      _id: undefined,
      createdAt: undefined,
      updatedAt: undefined,
    });

    if (contentCopy) {
      setSelectedContentId(contentCopy._id);
      setViewingContentId(contentCopy._id);
    }
  };

  return (
    <div className="content-preview">
      <Row className="content-preview__buttons">
        <Col>
          <i
            className="fa-solid fa-feather-pointed"
            data-tip="Copy to Social Post"
            onClick={async () => {
              let imageUrl;
              let text;
              let jobId;

              if (content.imageUrl) {
                imageUrl = content.imageUrl;
              }
              if (content.text) {
                if (user.flags?.[FeatureFlags.FACEBOOK_TEST]) {
                  Helpers.copyToClipboard(content.text);
                } else {
                  text = `${stubSocialPost.data.text || ''} ${content.text}`.trim();
                }
              } else if (content.title) {
                if (user.flags?.[FeatureFlags.FACEBOOK_TEST]) {
                  Helpers.copyToClipboard(content.title);
                } else {
                  text = `${stubSocialPost.data.text || ''} ${content.title}`.trim();
                }
              }
              if (content.videoUrl) {
                jobId = job?._id;
                if (!jobId) {
                  const newJob = createJob();
                  jobId = newJob._id;
                }
              }

              setStubSocialPost({
                ...stubSocialPost,
                jobId,
                data: {
                  ...stubSocialPost.data,
                  text,
                  imageUrl,
                },
              });
            }}
          />

          {canEdit ? (
            <i
              className="fa-solid fa-pencil"
              data-tip="Edit Content"
              onClick={() => setViewingContentId(content._id)}
            />
          ) : (
            <i
              className="fa-solid fa-copy"
              data-tip="Customize Content"
              onClick={async () => {
                await copyContent();
              }}
            />
          )}

          {content?.videoUrl && (
            <Dropdown
              className="content-preview__option-menu"
              isOpen={optionsDropdownOpen}
              toggle={() => setOptionsDropdownOpen(!optionsDropdownOpen)}
            >
              <DropdownToggle color="none">
                <i className="fa-solid fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu end>
                {!job && (
                  <DropdownItem
                    onClick={async () => {
                      const manualJob = createJob();
                      await Jobs.create(manualJob);
                    }}
                  >
                    Manual Render
                  </DropdownItem>
                )}

                {job?.url && (
                  <DropdownItem
                    onClick={async () => {
                      window.open(job.url, '_blank');
                    }}
                  >
                    Download
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          )}

          <i className="fa-solid fa-times" onClick={() => setSelectedContentId()} />
        </Col>
      </Row>
      <ContentCard {...content} showUnfurl showExpand />
    </div>
  );
};

export default withSmoothLoad(ContentPreview);
