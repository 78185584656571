import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ContentManager from './ContentManager';
import Dashboard from './Dashboard';
import Settings from './Settings';
import Admin from './Admin';
import ChangeLog from './ChangeLog';
import Support from './Support';
import { useSetStoreValue, useStoreValue } from 'react-context-hook';
import { Row, Nav, NavItem, NavLink } from 'reactstrap';
import {
  NavLink as RRNavLink,
  Navigate,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { storeMap, FeatureFlags, UserStatus } from 'services/constants';

import './AppContainer.css';

const AppContainer = ({ onLogout }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = useStoreValue(storeMap.TOKEN);
  const user = useStoreValue(storeMap.USER);
  const setSelectedDate = useSetStoreValue(storeMap.SELECTED_DATE);

  React.useEffect(() => {
    if (location.pathname === '/app') {
      navigate('dashboard');
    }
  }, [navigate, location]);

  React.useEffect(() => {
    setSelectedDate(moment().toISOString(true).split('T')[0]);
  }, []);

  if (token === undefined || user === undefined) {
    return <Navigate to="/" replace />;
  }
  if (token === null || user === null) {
    return <i className="fa-solid fa-circle-o-notch fa-spin" />;
  }

  return (
    <Row className="app-container">
      <div className="left-panel">
        <div className="logo-container">
          <img
            className="logo-square"
            src="/img/storiful-logo-color-square.png"
            alt="Storiful Square"
          />
          <img
            className="logo"
            src="/img/storiful-logo-color.png"
            alt="Storiful Logo"
            width="138"
            height="60"
          />
        </div>

        <hr />

        <Nav vertical>
          <NavItem>
            <NavLink tag={RRNavLink} to="/app/dashboard">
              <i className="fa-solid fa-columns" />
              <span className="nav-text">Dashboard</span>
            </NavLink>
          </NavItem>
          {user.flags?.[FeatureFlags.ENABLE_CONTENT_MANAGER] && (
            <NavItem>
              <NavLink tag={RRNavLink} to="/app/content-manager">
                <i className="fa-solid fa-book-open" />
                <span className="nav-text">Community</span>
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink tag={RRNavLink} to="/app/settings/profile">
              <i className="fa-solid fa-cog" />
              <span className="nav-text">Settings</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={RRNavLink} to="/app/support">
              <i className="fa-solid fa-question" />
              <span className="nav-text">Support</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              onClick={() => {
                onLogout();
                navigate('/');
              }}
            >
              <i className="fa-solid fa-door-open" />
              <span className="nav-text">Logout</span>
            </NavLink>
          </NavItem>
          {user.status === UserStatus.ADMIN && (
            <NavItem>
              <NavLink tag={RRNavLink} to="/app/admin/contents">
                <i className="fa-solid fa-cogs" />
                <span className="nav-text">Admin</span>
              </NavLink>
            </NavItem>
          )}
          <div className="nav-spacer" />
          <NavItem>
            <NavLink tag={RRNavLink} to="/app/changelog">
              <i className="fa-solid fa-list" />
              <span className="nav-text">Changelog</span>
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div className="right-panel">
        {user._id ? (
          <Routes>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="content-manager" element={<ContentManager />} />
            <Route path="settings/*" element={<Settings />} />
            <Route path="support" element={<Support />} />
            <Route path="changelog" element={<ChangeLog />} />
            {user.status === UserStatus.ADMIN && <Route path="admin/*" element={<Admin />} />}
          </Routes>
        ) : (
          <div className="spinner-border text-info" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
      </div>
    </Row>
  );
};

AppContainer.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

AppContainer.defaultProps = {};

export default AppContainer;
