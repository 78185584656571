import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup } from 'reactstrap';
import { EMAIL_REGEX } from 'util/constants';
import TextInput from 'components/TextInput';
import { useUsers } from 'services/Users';
import './Login.css';

const Login = ({ onLogin, onSignupClick, onForgotPasswordClick }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [touched, setTouched] = React.useState({});
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState();
  const Users = useUsers();

  const onLoginClick = async () => {
    setIsLoading(true);

    const user = await Users.login({ email, password });

    // Handle invalid login
    if (!user?.token) {
      window.flash('Invalid credentials', 'danger');
      setIsLoading(false);
      return;
    }

    onLogin(user);
  };

  const onFocus = (event) => {
    setTouched({ ...touched, [event.target.name]: true });
  };

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      onLoginClick();
    }
  };

  const emailInvalid = !email || !EMAIL_REGEX.test(email);
  const passwordInvalid = !password;

  return (
    <Form className="login">
      <FormGroup className="login-form" onKeyPress={onKeyPress}>
        <TextInput
          type="email"
          name="email"
          value={email}
          onFocus={onFocus}
          onChange={(event) => setEmail(event.target.value)}
          invalid={touched.email && (!email || !EMAIL_REGEX.test(email))}
          hideLabel
          required
        />

        <TextInput
          type="password"
          name="password"
          value={password}
          onFocus={onFocus}
          onChange={(event) => setPassword(event.target.value)}
          invalid={touched.password && !password}
          hideLabel
          required
        />

        <Button
          size="sm"
          onClick={onLoginClick}
          color="primary"
          disabled={isLoading || emailInvalid || passwordInvalid}
        >
          {isLoading ? <i className="fa-solid fa-circle-o-notch fa-spin" /> : 'Login'}
        </Button>
        <Button size="sm" onClick={onSignupClick} color="primary">
          Sign up
        </Button>

        <div
          className="forgot-password"
          onKeyPress={() => onForgotPasswordClick(email)}
          onClick={() => onForgotPasswordClick(email)}
        >
          Forgot password?
        </div>
      </FormGroup>
    </Form>
  );
};

Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onSignupClick: PropTypes.func.isRequired,
  onForgotPasswordClick: PropTypes.func.isRequired,
};

export default Login;
