import React, { useEffect } from 'react';
import { Alert } from 'reactstrap';
import { useStore } from 'react-context-hook';
import { storeMap } from 'services/constants';
import './FlashMessage.css';

const FlashMessage = () => {
  const [flashMessage, setFlashMessage] = useStore(storeMap.FLASH_MESSAGE);
  const { message, type, duration } = flashMessage || {};

  useEffect(() => {
    setTimeout(() => {
      setFlashMessage(null);
    }, duration);
  }, [setFlashMessage, duration]);

  if (!message) {
    return null;
  }

  return (
    <Alert color={type} className="flash-message show" isOpen toggle={() => setFlashMessage(null)}>
      {message}
    </Alert>
  );
};

export default FlashMessage;
