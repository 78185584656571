import Helpers from 'client-helpers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useStoreValue } from 'react-context-hook';
import DatePicker from 'react-datepicker';
import ReactTooltip from 'react-tooltip';
import { Button, Col, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { ContentType, storeMap } from 'services/constants';

import './ContentInput.css';

const months = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
};

const daysOfWeek = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
};

const formatDate = (content) => {
  let dateString = '';

  if (parseInt(content.month, 10) >= 0) {
    dateString += `${months[content.month]} `;
  }
  if (parseInt(content.day, 10) > 0) {
    dateString += `${content.day} `;
  }
  if (parseInt(content.year, 10) > 0) {
    dateString += `${content.year}`;
  }

  return dateString ? `Valid ${dateString}` : 'Evergreen';
};

const ContentInput = ({ content, setContent, showAdvanced }) => {
  const token = useStoreValue(storeMap.TOKEN);
  const collectionsById = useStoreValue(storeMap.COLLECTIONS_BY_ID, {});
  const [isEditingDate, setIsEditingDate] = React.useState(false);
  const attachImageRef = React.createRef();
  const attachVideoRef = React.createRef();

  const onFileSelect = async (event, type = 'image') => {
    const file = event.currentTarget?.files?.[0];
    const contentProp = type === 'image' ? 'imageUrl' : 'videoUrl';

    if (!file) {
      return;
    }

    // Janky AF but we have to remove spaces here
    Object.defineProperty(file, 'name', {
      writable: true,
      value: file.name.replace(/ /g, '_'),
    });

    const fileUrl = await Helpers.uploadUserMedia(file, token);

    setContent({
      ...content,
      [contentProp]: fileUrl,
    });
  };

  const hasVideo = !!content.videoUrl;
  const hasImage = !!content.imageUrl;

  return (
    <Row className="content-input">
      <Col>
        <ReactTooltip effect="solid" />

        <Row>
          <Col md={8}>
            <FormGroup>
              <Input
                type="text"
                placeholder="Title"
                value={content.title || ''}
                onChange={(event) => {
                  setContent({
                    ...content,
                    title: event.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md={4} className="content-prompt">
            <i
              className="fa-solid fa-circle-info"
              data-tip="Is this Ready Made Content or a Prompt?"
            />
            <Label>Is Prompt? </Label>
            <FormGroup switch className="prompt-switch">
              <Input
                type="switch"
                checked={content.type === ContentType.PROMPT}
                onChange={() => {
                  const type =
                    content.type === ContentType.PROMPT
                      ? ContentType.READY_MADE
                      : ContentType.PROMPT;
                  setContent({ ...content, type });
                }}
              />
            </FormGroup>
          </Col>
        </Row>

        <Input
          type="textarea"
          name="text"
          placeholder="What do you want to say?"
          value={content.text || ''}
          onChange={(event) => {
            setContent({
              ...content,

              text: event.target.value,
            });
          }}
        />

        <Row>
          <Col className="attach-buttons" md={3}>
            <input
              className="attach-image-input"
              type="file"
              accept="image/*"
              ref={attachImageRef}
              onChange={(e) => onFileSelect(e, 'image')}
            />

            {hasImage ? (
              <i
                className="fa-solid fa-image attached"
                data-tip="Remove image"
                disabled={content.imageUrl}
                onClick={() => {
                  delete content.imageUrl;
                  setContent({ ...content });
                }}
              />
            ) : (
              <i
                className="fa-solid fa-image"
                data-tip="Attach image"
                disabled={content.imageUrl}
                onClick={() => attachImageRef.current.click()}
              />
            )}

            <input
              className="attach-video-input"
              type="file"
              accept="video/*"
              ref={attachVideoRef}
              onChange={(e) => onFileSelect(e, 'video')}
            />

            {hasVideo ? (
              <i
                className="fa-solid fa-video attached"
                data-tip="Remove video"
                disabled={content.videoUrl}
                onClick={() => {
                  delete content.videoUrl;
                  setContent({ ...content });
                }}
              />
            ) : (
              <i
                className="fa-solid fa-video"
                data-tip="Attach video"
                disabled={content.videoUrl}
                onClick={() => {
                  attachVideoRef.current?.click();
                }}
              />
            )}
          </Col>
          <Col className="datelock" md={9}>
            {isEditingDate ? (
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      type="select"
                      name="month"
                      placeholder="Month"
                      value={content.month >= 0 ? content.month : -1}
                      bsSize="sm"
                      onChange={(event) => {
                        const value = parseInt(event.target.value, 10);
                        const month = value >= 0 ? value : -1;
                        setContent({
                          ...content,
                          month,
                        });
                      }}
                    >
                      <option value={-1}>All</option>
                      {[...Array(12).keys()].map((n, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <option key={i} value={i}>
                          {months[i]}
                        </option>
                      ))}
                    </Input>
                    <Label for="monthField">Month</Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      type="select"
                      name="day"
                      id="dayField"
                      bsSize="sm"
                      value={content.day || -1}
                      onChange={(event) => {
                        const value = parseInt(event.target.value, 10);
                        const day = value >= 0 ? value : null;
                        setContent({
                          ...content,
                          day,
                        });
                      }}
                    >
                      <option value={-1}>All</option>
                      {[...Array(31).keys()].map((n, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <option key={i} value={i + 1}>
                          {i + 1}
                        </option>
                      ))}
                    </Input>

                    <Label for="dayField">Day</Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      type="select"
                      name="year"
                      id="yearField"
                      bsSize="sm"
                      value={content.year || -1}
                      onChange={(e) => {
                        const year = parseInt(e.target.value, 10);
                        setContent({
                          ...content,
                          year,
                        });
                      }}
                    >
                      <option value={-1}>All</option>
                      {[...Array(10).keys()].map((n, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <option key={i} value={2023 + i}>
                          {2023 + i}
                        </option>
                      ))}
                    </Input>
                    <Label for="yearField">Year</Label>
                  </FormGroup>
                </Col>
                {showAdvanced && (
                  <Col>
                    <FormGroup floating>
                      <Input
                        type="select"
                        name="dayOfWeek"
                        id="dayOfWeekField"
                        bsSize="sm"
                        value={content.dayOfWeek || -1}
                        onChange={(event) => {
                          const value = parseInt(event.target.value, 10);
                          const dayOfWeek = value >= 0 ? value : -1;
                          setContent({
                            ...content,
                            dayOfWeek,
                          });
                        }}
                      >
                        <option value={-1}>All</option>
                        {[...Array(7).keys()].map((n, i) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <option key={i} value={i}>
                            {daysOfWeek[i]}
                          </option>
                        ))}
                      </Input>
                      <Label for="monthField">DOTW</Label>
                    </FormGroup>
                  </Col>
                )}
                <Button color="none" onClick={() => setIsEditingDate(false)}>
                  Done
                </Button>
              </Row>
            ) : (
              <div className="datelock__text">
                <span onClick={() => setIsEditingDate(true)}>
                  <i className="fa-solid fa-pencil" data-tip="Edit available dates" />
                  {formatDate(content)}
                </span>
                <i
                  className="fa-solid fa-circle-info"
                  data-tip="Specify when this Content should be available for posting"
                />
              </div>
            )}
          </Col>
        </Row>

        {showAdvanced && (
          <>
            <FormGroup floating>
              <Input
                type="text"
                placeholder="Tags"
                value={content.tags?.join(',') || ''}
                onChange={(event) => {
                  const tags = event.target.value.split(',').map((k) => k.trim());
                  setContent({
                    ...content,
                    tags,
                  });
                }}
              />
              <Label>Tags</Label>
              <FormText color="muted">Comma separated list: Tag,Tag,etc</FormText>
            </FormGroup>

            <FormGroup floating>
              {/* <Input
                type="text"
                placeholder="CollectionIds"
                value={content.collectionIds?.join(',') || []}
                onChange={(event) => {
                  const collectionIds = event.target.value
                    .split(',')
                    .filter((k) => k)
                    .map((k) => k.trim());
                  setContent({
                    ...content,
                    collectionIds,
                  });
                }}
              />

              <Label>CollectionIds</Label>
              <FormText color="muted">
                Comma separated list: CollectionId, CollectionId,etc
              </FormText> */}

              <FormGroup floating>
                <Input
                  className="content-input__collection-select"
                  type="select"
                  name="collection"
                  multiple
                  onChange={(e) => {
                    const collectionIds =
                      Array.from(e.target.selectedOptions)
                        .map((option) => option.value)
                        .filter((option) => option) || [];
                    setContent({
                      ...content,
                      collectionIds,
                    });
                  }}
                  value={content.collectionIds || []}
                >
                  <option value="">No collection</option>
                  {Object.values(collectionsById).map((collection) => (
                    <option key={collection._id} value={collection._id}>
                      {collection.name}
                    </option>
                  ))}
                </Input>

                <Label for="type">Collection</Label>
              </FormGroup>
            </FormGroup>

            <Row>
              <Col>
                <FormGroup inline>
                  <Label>CreatedAt</Label>
                  <DatePicker
                    selected={moment.utc(content.createdAt).toDate()}
                    onChange={(date) => setContent({ ...content, createdAt: date.toUTCString() })}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
};

ContentInput.propTypes = {
  content: PropTypes.shape({
    _id: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    imageUrl: PropTypes.string,
    videoUrl: PropTypes.string,
    type: PropTypes.string,
    day: PropTypes.number,
    month: PropTypes.number,
    year: PropTypes.number,
    dayOfWeek: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.string),
    collectionIds: PropTypes.arrayOf(PropTypes.string),
    createdAt: PropTypes.string,
  }),
  setContent: PropTypes.func,
  onFileSelect: PropTypes.func,
  onDeleteContent: PropTypes.func,
  maxLength: PropTypes.string,
  showAdvanced: PropTypes.bool,
};

ContentInput.defaultProps = {
  content: null,
  setContent: (v) => v,
  onDeleteContent: (v) => v,
  onFileSelect: null,
  maxLength: null,
  showAdvanced: false,
};

export default ContentInput;
