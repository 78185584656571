/* eslint-disable jsx-a11y/anchor-has-content, react/no-unescaped-entities, jsx-a11y/anchor-is-valid, max-len, no-useless-escape */
import React from 'react';
import Login from 'components/Login/Login';
import {
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { NavLink as RRNavLink, Route, Routes, useNavigate } from 'react-router-dom';
import SignupModal from 'components/SignupModal';
import TourModal from 'components/TourModal';
import ForgotPasswordModal from 'components/ForgotPasswordModal';
import LandingPage from './LandingPage';
import Pricing from './Pricing';
import AboutUs from './AboutUs';
import FAQ from './FAQ';
import Blog from './Blog';
import Contact from './Contact';
import Privacy from './Privacy';
import TOS from './TOS';
import { storeMap } from 'services/constants';
import Demo from './Demo';
import { useStore, useSetStoreValue } from 'react-context-hook';

import './Home.css';

const Home = () => {
  const setToken = useSetStoreValue(storeMap.TOKEN);
  const [user, setUser] = useStore(storeMap.USER);
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [loginDropdownOpen, setLoginDropdownOpen] = React.useState(false);
  const [tourModalVisible, setTourModalVisible] = React.useState(false);
  const [signupModalVisible, setSignupModalVisible] = React.useState(false);
  const [forgotPasswordModalVisible, setForgotPasswordModalVisible] = React.useState(false);

  const toggleForgotPasswordModal = (loginEmail) => {
    setEmail(loginEmail);
    setLoginDropdownOpen(false);
    setForgotPasswordModalVisible(!forgotPasswordModalVisible);
  };

  const toggleSignupModal = () => {
    setLoginDropdownOpen(false);
    setSignupModalVisible(!signupModalVisible);
  };
  const toggleTourModal = () => setTourModalVisible(!tourModalVisible);

  // const toggleTrialModal = () => store.set(storeMap.TRIAL_MODAL_VISIBLE, !trialModalVisible);

  const toggleLoginDropdown = (e) => {
    if (e.target.classList.contains('nav-link')) {
      setLoginDropdownOpen(!loginDropdownOpen);
    }
  };

  const onLogin = (loggedInUser, landingPage = '/app/dashboard') => {
    setToken(loggedInUser.token);
    setUser(loggedInUser);
    localStorage.setItem('token', loggedInUser.token);

    navigate(landingPage);
  };

  return (
    <div className="home">
      <Row className="App-header">
        <Col>
          <div className="nav-panel">
            <Nav pills>
              <div className="logo">
                <RRNavLink to="/">
                  <img alt="Storiful Logo" src="/img/storiful-logo-light.png" />
                </RRNavLink>
              </div>

              <NavItem>
                <NavLink tag="a" href="#why-subscribe">
                  Product
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag="a" href="#pricing">
                  Pricing
                </NavLink>
              </NavItem>

              {user ? (
                <span className="login-buttons">
                  <NavItem className="account-nav btn btn-primary btn-signin">
                    <NavLink tag={RRNavLink} to="/app">
                      My Account
                    </NavLink>
                  </NavItem>
                </span>
              ) : (
                <span className="login-buttons">
                  <Dropdown
                    nav
                    isOpen={loginDropdownOpen}
                    toggle={toggleLoginDropdown}
                    className="account-nav btn btn-primary"
                  >
                    <DropdownToggle nav>Login</DropdownToggle>
                    <DropdownMenu>
                      <Login
                        onLogin={onLogin}
                        onSignupClick={toggleSignupModal}
                        onForgotPasswordClick={toggleForgotPasswordModal}
                      />
                    </DropdownMenu>
                  </Dropdown>

                  <NavLink onClick={toggleSignupModal}>
                    <Button color="primary">Get Started</Button>
                  </NavLink>
                </span>
              )}
            </Nav>
          </div>
        </Col>
      </Row>

      <Routes>
        <Route path="/" element={<LandingPage toggleSignupModal={toggleSignupModal} />} />
        <Route path="/pricing" element={<Pricing toggleSignupModal={toggleSignupModal} />} />
        <Route path="/about-us" element={<AboutUs toggleSignupModal={toggleSignupModal} />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/tos" element={<TOS />} />
        <Route path="/demo" element={<Demo />} />
      </Routes>
      <Row className="App-footer text-center">
        <Col>
          <Nav pills>
            <NavItem>
              <NavLink tag={RRNavLink} to="/">
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RRNavLink} to="/about-us">
                About Us
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RRNavLink} to="/pricing">
                Pricing
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RRNavLink} to="/faq">
                FAQ
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink tag={RRNavLink} to="/blog">
                Blog
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink tag={RRNavLink} to="/contact">
                Contact
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RRNavLink} to="/privacy">
                Privacy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RRNavLink} to="/tos">
                TOS
              </NavLink>
            </NavItem>
          </Nav>

          <p>© {new Date().getFullYear()} Storiful, LLC. All Rights Reserved.</p>
        </Col>
      </Row>

      {signupModalVisible && <SignupModal show toggle={toggleSignupModal} onLogin={onLogin} />}

      {forgotPasswordModalVisible && (
        <ForgotPasswordModal show toggle={toggleForgotPasswordModal} email={email} />
      )}

      {tourModalVisible && <TourModal show toggle={toggleTourModal} email={email} />}

      {/* {trialModalVisible && (
        <TrialModal show toggle={toggleTrialModal} onLogin={onLogin}  />
      )} */}
    </div>
  );
};

export default Home;
