import React from 'react';
import PropTypes from 'prop-types';
import { useContents } from 'services/Contents';
import { useCollections } from 'services/Collections';
import Helpers from 'client-helpers';
import { useSetStoreValue, useStoreValue } from 'react-context-hook';
import { ContentType, storeMap } from 'services/constants';
import { Row, Col, Button, Input } from 'reactstrap';
import ContentCard from 'components/ContentCard';

import './ContentContainer.css';

const CONTENT_LIMIT = 8;

const ContentContainer = ({ onContentSelect }) => {
  const Contents = useContents();
  const Collections = useCollections();

  const user = useStoreValue(storeMap.USER);
  const selectedDate = useStoreValue(storeMap.SELECTED_DATE);
  const contentsById = useStoreValue(storeMap.CONTENTS_BY_ID, {});
  const selectedContentId = useStoreValue(storeMap.SELECTED_CONTENT_ID);
  const collectionsById = useStoreValue(storeMap.COLLECTIONS_BY_ID, {});
  const setViewingContentId = useSetStoreValue(storeMap.VIEWING_CONTENT_ID);

  const [searchString, setSearchString] = React.useState();
  const [contentStart, setContentStart] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasMoreContent, setHasMoreContent] = React.useState(true);
  const [selectedCollectionId, setSelectedCollectionId] = React.useState();
  const [displayStartIndex, setDisplayStartIndex] = React.useState(0);

  const debouncedSearchString = Helpers.useDebounce(searchString);
  const privateCollectionId = user.privateCollectionId;
  const collections = Object.values(collectionsById);

  const getContentClass = React.useCallback(
    (content) => {
      let className = '';

      if (content._id === selectedContentId) {
        className += ` selected`;
      }

      if (content.collectionIds?.some((collectionId) => collectionId === privateCollectionId)) {
        className += ' private';
      }

      if (content.day || content.week || content.year || content.dayOfWeek) {
        className += ' datelocked';
      }

      return className;
    },
    [selectedContentId, privateCollectionId]
  );

  React.useEffect(() => {
    setIsLoading(true);
  }, [searchString]);

  React.useEffect(() => {
    Collections.fetch();
  }, []);

  // TODO: This probably performs poorly...
  React.useEffect(() => {
    Contents.clearCache(ContentType.READY_MADE);
    setContentStart(0);
    setIsLoading(false);
    setDisplayStartIndex(0);
  }, [selectedDate, debouncedSearchString, selectedCollectionId]);

  React.useEffect(() => {
    if (!selectedDate) {
      return;
    }

    const fetchContent = async () => {
      const { hasMoreResults } = await Contents.fetch(
        {
          date: debouncedSearchString ? null : selectedDate,
          search: debouncedSearchString,
          limit: CONTENT_LIMIT,
          start: contentStart,
          collectionId: selectedCollectionId,
        },
        !debouncedSearchString
      );

      setHasMoreContent(hasMoreResults);
    };

    fetchContent();
  }, [selectedDate, selectedCollectionId, debouncedSearchString, contentStart]);

  // Create new empty content
  const createContent = async () => {
    const currentDate = new Date(selectedDate);
    const newContent = await Contents.create({
      day: currentDate.getUTCDate(),
      month: currentDate.getUTCMonth(),
      collectionIds: [user.privateCollectionId],
    });

    if (newContent) {
      setViewingContentId(newContent._id);
    }
  };

  const [isSearching, setIsSearching] = React.useState(false);

  const contents = React.useMemo(() => {
    const copiedContentsById = {};
    return Object.values(contentsById)
      .filter((content) => content.type === ContentType.READY_MADE)
      .sort((a, b) => {
        if (a.copiedFrom) {
          copiedContentsById[a.copiedFrom] = 1;
        }
        if (b.copiedFrom) {
          copiedContentsById[b.copiedFrom] = 1;
        }

        if (
          a.collectionIds?.includes(user.privateCollectionId) &&
          !b.collectionIds?.includes(user.privateCollectionId)
        ) {
          return -1;
        }
        if (
          !a.collectionIds?.includes(user.privateCollectionId) &&
          b.collectionIds?.includes(user.privateCollectionId)
        ) {
          return 1;
        }

        if (a.year && !b.year) {
          return -1;
        }
        if (!a.year && b.year) {
          return 1;
        }

        if (a.collectionIds?.length && !b.collectionIds?.length) {
          return -1;
        }
        if (!a.collectionIds?.length && b.collectionIds?.length) {
          return 1;
        }

        return 0;
      })
      .filter((content) => !copiedContentsById[content._id]);
  }, [contentsById, user.privateCollectionId]);

  return (
    <div className="content-container">
      <Row className={`content-container__header ${isSearching && 'is-searching'}`}>
        <Col>
          <Button color="primary" onClick={createContent}>
            + New
          </Button>

          <i
            className="fa-solid fa-search column-icon"
            data-tip="Search content"
            onClick={() => setIsSearching(true)}
          />

          <Input
            className="search-input"
            type="text"
            name="search"
            placeholder="Search"
            value={searchString || ''}
            onChange={(e) => {
              const newSearchString = e.target.value;
              setSearchString(newSearchString);
            }}
          />

          {isSearching && !isLoading && (
            <i
              className="fa-solid fa-times search-close-icon"
              data-tip="Search"
              onClick={() => {
                setSearchString(null);
                setIsSearching(false);
              }}
            />
          )}

          {isSearching && isLoading && (
            <i className="fa-solid fa-circle-o-notch fa-spin loading-icon" />
          )}

          {collections.length > 1 && (
            <Input
              className="content-container__collection-select"
              type="select"
              name="collection"
              onChange={(e) => {
                setSelectedCollectionId(e.target.value);
              }}
              defaultValue={selectedCollectionId}
            >
              <option value="">All collections</option>
              {collections.map((collection) => (
                <option key={collection._id} value={collection._id}>
                  {collection.name}
                </option>
              ))}
            </Input>
          )}
        </Col>
      </Row>

      <Row className="content-container__container">
        {displayStartIndex > 0 && (
          <span
            className="content-container__previous-arrow"
            onClick={() => {
              setDisplayStartIndex(Math.max(displayStartIndex - 4, 0));
            }}
          >
            <i className="fa-solid fa-chevron-left" />
          </span>
        )}

        {contents.slice(displayStartIndex, displayStartIndex + 4).map((content) => {
          const contentClass = getContentClass(content);
          return (
            <Col
              key={content._id}
              className={`content-option${contentClass}`}
              md={3}
              onClick={() => onContentSelect(content._id)}
            >
              <ContentCard {...content} />
            </Col>
          );
        })}

        {(hasMoreContent || contents.length > displayStartIndex + 4) && (
          <span
            className="content-container__next-arrow"
            onClick={() => {
              if (hasMoreContent) {
                setContentStart(contentStart + CONTENT_LIMIT);
              }
              setDisplayStartIndex(displayStartIndex + 4);
            }}
          >
            <i className="fa-solid fa-chevron-right" />
          </span>
        )}
      </Row>
    </div>
  );
};

ContentContainer.propTypes = {
  onContentSelect: PropTypes.func,
};

ContentContainer.defaultProps = {
  onContentSelect: null,
};

export default ContentContainer;
