import React from 'react';
import PropTypes from 'prop-types';
import { Label, Input, FormGroup } from 'reactstrap';

const LinkedInSettings = ({ linkedin, onChange }) => {
  const selectedOrganizationId = linkedin.organizations?.find(
    (organization) => organization.isActive
  )?.id;

  return (
    <FormGroup floating className="social-platform-settings">
      <Input
        type="select"
        name="organization"
        id="organizationSelect"
        onChange={onChange}
        defaultValue={selectedOrganizationId}
      >
        {linkedin.organizations.map((organization) => (
          <option key={organization.id} value={organization.id}>
            {organization.name}
          </option>
        ))}
      </Input>
      <Label for="organizationSelect">LinkedIn Organization</Label>
    </FormGroup>
  );
};

LinkedInSettings.propTypes = {
  linkedin: PropTypes.shape({
    id: PropTypes.string,
    organizations: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LinkedInSettings;
