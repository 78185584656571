/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './CalendarEventCard.css';

const isAllDay = (startDttm, endDttm) =>
  startDttm.isSame(moment.parseZone(startDttm).startOf('day'), 'second') &&
  endDttm.isSame(moment.parseZone(endDttm).startOf('day'), 'second');

const getTimeDisplay = (startDttm, endDttm) => {
  startDttm = moment(startDttm);
  endDttm = moment(endDttm);
  const allDay = isAllDay(startDttm, endDttm);
  if (allDay) {
    const endDate = moment(endDttm).subtract(1, 'day');

    if (endDate.isSame(startDttm, 'day')) {
      return startDttm.format('ddd, MMM Do');
    }
    return `${startDttm.format('MMM Do, YYYY')} - ${endDate.format('MMM Do, YYYY')}`;
  }
  if (endDttm.isSame(startDttm, 'day')) {
    return `${startDttm.format('ddd, MMM Do')}\n${startDttm.format('h:mma')} - ${endDttm.format(
      'h:mma'
    )}`;
  }
  return `${startDttm.format('MMM Do, YYYY, h:mma')} -\n${endDttm.format('MMM Do, YYYY, h:mma')}`;
};

const CalendarEventCard = ({
  id,
  description,
  summary,
  calendarName,
  color,
  status,
  location,
  startDttm,
  endDttm,
  allDay,
  recurrence,
  htmlLink,
  organizer,
  showExpand,
}) => {
  const calendarEventCardRef = React.createRef();
  const [isContentOverflowing, setIsContentOverflowing] = React.useState(false);
  const [isFullHeight, setIsFullHeight] = React.useState(false);

  React.useEffect(() => {
    setIsFullHeight(false);
  }, [id]);

  React.useEffect(() => {
    if (!calendarEventCardRef.current) {
      return null;
    }

    setIsContentOverflowing(
      calendarEventCardRef.current?.clientHeight < calendarEventCardRef.current?.scrollHeight
    );

    return null;
  }, [calendarEventCardRef]);

  return (
    <div className={`calendar-event-card ${isFullHeight ? 'full-height' : ''}`}>
      <div ref={calendarEventCardRef} className="calendar-event-card__content">
        {htmlLink && (
          <div className="link">
            <a href={htmlLink} target="_blank" rel="noreferrer">
              <i className="fa-solid fa-arrow-up-right-from-square" />
            </a>
          </div>
        )}

        {summary && (
          <div className="summary">
            <i className="fa-solid fa-square" style={{ color }} />
            {summary}
          </div>
        )}

        <div className="time">{getTimeDisplay(startDttm, endDttm, allDay)}</div>

        {location && (
          <div className="location">
            <i className="fa-solid fa-location-dot" />
            {location}
          </div>
        )}

        <div className="calendar-name">
          <i className="fa-solid fa-calendar-day" />
          {calendarName}
        </div>

        {description && (
          <div className="description" title="Description">
            <i className="fa-solid fa-bars-staggered" />
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        )}
      </div>

      {showExpand && isContentOverflowing && !isFullHeight && (
        <div className="calendar-event-card__expand" onClick={() => setIsFullHeight(true)}>
          <i className="fa-solid fa-chevron-down" />
        </div>
      )}
      {showExpand && isFullHeight && (
        <div className="calendar-event-card__expand" onClick={() => setIsFullHeight(false)}>
          <i className="fa-solid fa-chevron-up" />
        </div>
      )}
    </div>
  );
};

CalendarEventCard.propTypes = {
  id: PropTypes.string,
  description: PropTypes.string,
  summary: PropTypes.string,
  status: PropTypes.string,
  calendarName: PropTypes.string,
  color: PropTypes.string,
  location: PropTypes.string,
  startDttm: PropTypes.string,
  endDttm: PropTypes.string,
  allDay: PropTypes.bool,
  recurrence: PropTypes.arrayOf(PropTypes.string),
  htmlLink: PropTypes.string,
  organizer: PropTypes.shape({}),
  showExpand: PropTypes.bool,
};

CalendarEventCard.defaultProps = {
  id: null,
  description: '',
  summary: '',
  status: '',
  calendarName: '',
  color: null,
  location: null,
  startDttm: '',
  endDttm: '',
  allDay: false,
  recurrence: null,
  htmlLink: '',
  organizer: {},
  showExpand: false,
};

export default CalendarEventCard;
