import React from 'react';
import axios from 'axios';
import { Row, Col } from 'reactstrap';
import Contact from 'Home/Contact';
import withSmoothLoad from 'components/withSmoothLoad';

import './Support.css';
import SupportArticle from './SupportArticle';

const Support = () => {
  const [supportArticles, setSupportArticles] = React.useState([]);
  const [selectedArticle, setSelectedArticle] = React.useState();

  React.useEffect(() => {
    const fetchSupportArticles = async () => {
      let response;
      try {
        response = await axios.get('/api/support-articles');
      } catch (error) {
        window.flash('Error fetching Support Articles', 'danger');
        return;
      }
      const responseSupportArticles = response.data || [];

      setSupportArticles(responseSupportArticles);
    };

    fetchSupportArticles();
  }, []);

  return (
    <div className="support">
      <h3 className="line-text">Support</h3>

      <Row>
        <Col md={2} className="article-list">
          <hr />
          {supportArticles.map((supportArticle) => (
            <div
              className={supportArticle === selectedArticle ? 'selected' : ''}
              key={supportArticle._id}
              onClick={() => setSelectedArticle(supportArticle)}
            >
              {supportArticle.title}
            </div>
          ))}
          <div onClick={() => setSelectedArticle()}>Contact</div>
          <hr />
        </Col>
        <Col>{selectedArticle ? <SupportArticle {...selectedArticle} /> : <Contact />}</Col>
      </Row>
    </div>
  );
};
export default withSmoothLoad(Support);
