import React from 'react';
import axios from 'axios';
import BlogPost from '../../components/BlogPost';
import './Blog.css';

let isMounted = false;

const fetchBlogPosts = async setBlogPosts => {
  let response;
  try {
    response = await axios.get('/api/blog-posts');
  } catch (error) {
    window.flash('Error fetching blogPosts', 'danger');
    return;
  }
  const blogPosts = response.data || [];

  if (isMounted) setBlogPosts(blogPosts);
};

const Blog = () => {
  const [blogPosts, setBlogPosts] = React.useState([]);

  React.useEffect(() => {
    isMounted = true;

    fetchBlogPosts(setBlogPosts);

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="blog">
      {blogPosts.map(blogPost => (
        <BlogPost key={blogPost._id} {...blogPost} />
      ))}
    </div>
  );
};

export default Blog;
