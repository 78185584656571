import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Row, Col, Button, Input, FormText } from 'reactstrap';
import { CardElement } from '@stripe/react-stripe-js';
import SubscriptionSettings from './SubscriptionSettings';
import Helpers from 'client-helpers';
import { useUsers } from 'services/Users';
import { useStore, useStoreValue } from 'react-context-hook';
import { FeatureFlags, storeMap, UserStatus } from 'services/constants';

import './UserPaymentSettings.css';

const UserPaymentSettings = ({ stripe, elements }) => {
  const Users = useUsers();
  const token = useStoreValue(storeMap.TOKEN);
  const [user, setUser] = useStore(storeMap.USER);
  const [isUpdatingCreditCard, setIsUpdatingCreditCard] = React.useState(false);
  const [stripeError, setStripeError] = React.useState();
  const [isSaving, setIsSaving] = React.useState(false);

  const isSubscribed = user.status === UserStatus.ACTIVE;

  React.useEffect(() => {
    setIsUpdatingCreditCard(!isSubscribed);
  }, [isSubscribed]);

  const onStripeElementChange = (event) => {
    const { error } = event;

    setStripeError(error);
  };

  const updatePaymentMethod = async () => {
    setIsSaving(true);

    // Get Stripe token
    const cardElement = elements.getElement(CardElement);
    const stripeResponse = await stripe.createToken(cardElement, { name: user.email });
    const { token: { id } = {} } = stripeResponse;
    if (!id) {
      window.flash('Please enter a valid credit card', 'danger');
      setIsSaving(false);
      return;
    }

    // Call API to update user
    try {
      const updatedStripe = await axios.post(
        '/api/stripe/update-payment',
        { token: id, email: user.email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUser({ ...user, stripe: updatedStripe });
    } catch (error) {
      window.flash('Error updating payment method', 'danger');
      setIsSaving(false);
      return;
    }

    window.flash('Payment method updated', 'success');
    setIsSaving(false);
  };

  const generateReferralCode = async () => {
    let referral;
    try {
      referral = await Users.createReferralCode();
    } catch (error) {
      return;
    }

    setUser({ ...user, referral });
  };

  const stripeInvalid = !!stripeError;

  return (
    <div className="user-payment-settings">
      <h3 className="line-text">Payment Method</h3>
      <Row className="user-payment-settings__payment-section">
        <Col md={{ size: 4, offset: 1 }}>
          {isUpdatingCreditCard ? (
            <div>
              <CardElement onChange={onStripeElementChange} className="form-control" />
              {isSubscribed && (
                <Button
                  color="cta"
                  onClick={updatePaymentMethod}
                  disabled={stripeInvalid || isSaving}
                >
                  {isSaving ? (
                    <i className="fa-solid fa-circle-o-notch fa-spin" />
                  ) : (
                    'Update Payment'
                  )}
                </Button>
              )}
            </div>
          ) : (
            <div>
              {user.stripe?.brand && user.stripe.last4 && (
                <h6>
                  {user.stripe.brand} ending in {user.stripe.last4} expiration {user.stripe.exp}
                </h6>
              )}
              <Button color="cta" onClick={() => setIsUpdatingCreditCard(true)}>
                Change Credit Card
              </Button>
            </div>
          )}
        </Col>
        <Col md={{ size: 4, offset: 2 }}>
          <FormText>
            Get $100 in Collection credit for every user that signs up with your referral code.
          </FormText>
          <hr />
          {user.referral ? (
            <div className="user-payment-settings__referral-block">
              <div>
                Referral code: {user.referral.code}{' '}
                <i
                  className="fa-solid fa-copy"
                  onClick={() => {
                    Helpers.copyToClipboard(user.referral.code, {
                      message: 'Copied! Now go share it!',
                    });
                  }}
                />
              </div>
              <div>Remaining uses: {user.referral.remainingUses}</div>
              <div>Collection credit: ${user.referral.credit}</div>
            </div>
          ) : (
            <Button color="cta" onClick={generateReferralCode}>
              Generate Referral Code
            </Button>
          )}
        </Col>
      </Row>

      <SubscriptionSettings stripe={stripe} elements={elements} stripeInvalid={stripeInvalid} />
    </div>
  );
};

UserPaymentSettings.propTypes = {
  stripe: PropTypes.shape({
    createToken: PropTypes.func,
    customers: PropTypes.shape({
      update: PropTypes.func,
    }),
  }).isRequired,
  elements: PropTypes.shape({
    getElement: PropTypes.func,
  }).isRequired,
};

export default UserPaymentSettings;
