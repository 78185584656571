import React from 'react';
import PropTypes from 'prop-types';
import TextInput from 'components/TextInput';
import { Row, Col, Button } from 'reactstrap';
import './ContactForm.css';

const ContactForm = ({ user, onSubmit }) => {
  const [email, setEmail] = React.useState(user?.email);
  const [name, setName] = React.useState(user?.firstName && `${user?.firstName} ${user?.lastName}`);
  const [message, setMessage] = React.useState();
  const [isSent, setIsSent] = React.useState(false);

  if (isSent) {
    return (
      <Row>
        <Col>Thank you for contacting us! We will reach out to you as soon as possible!</Col>
      </Row>
    );
  }

  return (
    <Row className="contact-form">
      <Col md={{ size: 6, offset: 3 }}>
        <p>
          {`Have questions? We'd love to hear from you! Shoot us a message and we'll get back to you ASAP.`}
        </p>

        <TextInput
          type="text"
          name="name"
          value={name}
          onChange={({ target: { value } }) => setName(value)}
          required
        />

        <TextInput
          type="email"
          name="email"
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
          required
        />

        <TextInput
          type="textarea"
          name="message"
          value={message}
          onChange={({ target: { value } }) => setMessage(value)}
          required
        />

        <div>
          <Button
            color="primary"
            disabled={!name || !email || !message}
            onClick={() => {
              onSubmit({ email, name, message });
              setIsSent(true);
            }}
          >
            Send
          </Button>
        </div>
      </Col>
      <Col md="2" className="text-left">
        <p />
        <b>Email</b>
        <br />
        <a href="mailto:team@storiful.com">team@storiful.com</a>
        <p>
          <b>Phone</b>
          <br />
          202-670-1733
        </p>
      </Col>
    </Row>
  );
};

ContactForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

ContactForm.defaultProps = {
  user: {},
};

export default ContactForm;
