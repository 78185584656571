import EventBus from './bus';
import { io } from 'socket.io-client';

const serverUrl = process.env.REACT_APP_SERVER_ROOT || 'http://127.0.0.1:3000';
let socket;

console.log(`
                  Welcome to Storiful!

                                             ((((((((((((           
                                         (((((((((((((((            
                                   (((((((((((((((((((((            
                                (((((((((((((((((((((((             
                             (((((((((((((((((((((((((              
                            (((((((((((((((((((((((                 
                        ((((((((((((((((((((((((((((                
                       ((((((((((((((((((((((((((((                 
                     (((((((((((((((((((((((((((((                  
                   (((((((((((((( (((((((((((((((                   
                  ((((((((((((( ((((((((((((((                      
                   (((((((((( (((((((((((((((                       
                ((((((((((( ((((((((((((((((                        
               ((((((((((( (((((((((((((((                          
              (((((((((( (((((((((((((((                            
              ((((((((( ((((((((((((((                              
             ((((((((( (((((((((((                                  
             ((((((( (((((((((((((                                  
             (((((( ((((((((((                                      
             (((( (((((((((                                         
             ((( (((((((                                            
             (( (((                                                 
             ((                                                     
            ((                                                      
           ((                                                       
          ((                                                        
   ((((((((((((                                                     
  (((((((((((((((                                                   
(((((((((((((((((((                                                 
(((((((((((((((((((                                                 
(((((((((((((((((((                                                  
`);

export default {
  init: (token) => {
    socket = io(serverUrl, { transports: ['websocket'] });

    socket.on('connect', () => {
      socket.emit('authenticate', { token });
    });

    socket.on('message', (message) => {
      EventBus.emit('websocket_message', message);
    });

    socket.on('disconnect', () => {
      // After disconnected connect/open socket again
      socket.open();
    });
  },
};
