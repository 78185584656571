import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withSmoothLoad from 'components/withSmoothLoad';
import { FeatureFlags, SocialPostStatus, storeMap } from 'services/constants';

import './SocialPostCard.css';
import { useStoreValue } from 'react-context-hook';

const SocialPostCard = ({
  platform,
  platformUsername,
  platformPosted,
  data,
  date,
  jobId,
  link,
  linkInfo,
  status,
  showUnfurl,
}) => {
  const user = useStoreValue(storeMap.USER);
  const isPosted = status === SocialPostStatus.POSTED && data?.id;
  const hasStats =
    user.flags?.[FeatureFlags.ENABLE_SOCIAL_STATS] &&
    (!!data.likes || !!data.comments || !!data.shares);
  return (
    <div className="social-post-card">
      <div className="posted-at">
        <i className={`fab fa-${platform}`} />
        {platformUsername && (
          <span className="subtle-text platform-username">{platformUsername}</span>
        )}
        <span className="subtle-text post-time">
          {platformPosted && 'Natively '}
          {isPosted ? 'Posted at ' : 'Scheduled for '}
          {moment(data?.createdAt || date).format('h:mm A')}
        </span>
      </div>

      {data.text && <div className="social-post-card-text">{data.text}</div>}
      {data.imageUrl && (
        <div>
          <img src={data.imageUrl} alt="User post" />
        </div>
      )}
      {data.videoUrl && (
        <video controls preload="metadata">
          <source src={data.videoUrl} type="video/mp4" />
        </video>
      )}
      {jobId && !data.videoUrl && <div className="video-placeholder">Video Rendering...</div>}
      {showUnfurl && linkInfo?.description && (
        <div onClick={() => window.open(link, '_blank')}>
          <div className="line-text">
            <span className="subtle-text">Link preview</span>
          </div>
          {linkInfo.title && <h6>{linkInfo.title}</h6>}
          {linkInfo.description}
          {linkInfo.imageUrl && <img src={linkInfo.imageUrl} alt="Link" />}
        </div>
      )}

      {hasStats && (
        <div>
          <hr />
          Likes: {data.likes || 0} Comments: {data.comments || 0} Shares: {data.shares || 0}
          <hr />
        </div>
      )}
    </div>
  );
};

SocialPostCard.propTypes = {
  _id: PropTypes.string,
  platform: PropTypes.string,
  platformUsername: PropTypes.string,
  platformPosted: PropTypes.bool,
  status: PropTypes.string,
  jobId: PropTypes.string,
  date: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    createdAt: PropTypes.string,
    likes: PropTypes.number,
    comments: PropTypes.number,
    shares: PropTypes.number,
    imageUrl: PropTypes.string,
    videoUrl: PropTypes.string,
  }),
  link: PropTypes.string,
  linkInfo: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    link: PropTypes.string,
  }),
  showUnfurl: PropTypes.bool,
};

SocialPostCard.defaultProps = {
  _id: '',
  platform: '',
  platformUsername: '',
  platformPosted: false,
  status: '',
  jobId: '',
  date: '',
  data: {},
  link: '',
  linkInfo: null,
  showUnfurl: false,
};

export default withSmoothLoad(SocialPostCard);
