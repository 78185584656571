import React from 'react';
import { Row, Col } from 'reactstrap';
import './Demo.css';

const Demo = () => (
  <div className="demo">
    <Row>
      <Col>
        <iframe
          title="Storiful Demo"
          src="https://www.youtube.com/embed/aFNyZMQ00PQ"
          width="900"
          height="510"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Col>
    </Row>
  </div>
);

export default Demo;
