import React from 'react';
import { useStoreValue } from 'react-context-hook';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as RRNavLink, Route, Routes } from 'react-router-dom';
import { storeMap, UserStatus } from 'services/constants';
import ContentEditor from './ContentEditor';
import CollectionEditor from './CollectionEditor';
import JobEditor from './JobEditor';
import UserEditor from './UserEditor';
import BlogEditor from './BlogEditor';

import './Admin.css';
import SupportArticleEditor from './SupportArticleEditor';

const Admin = () => {
  const user = useStoreValue(storeMap.USER);

  if (!user.status === UserStatus.ADMIN) {
    return null;
  }

  return (
    <Row className="admin-container">
      <Col>
        <h2>Admin</h2>
        <Nav>
          <NavItem>
            <NavLink tag={RRNavLink} to="/app/admin/contents">
              Content
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink tag={RRNavLink} to="/app/admin/collections">
              Collections
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink tag={RRNavLink} to="/app/admin/jobs">
              Jobs
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink tag={RRNavLink} to="/app/admin/users">
              Users
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink tag={RRNavLink} to="/app/admin/support">
              Support Articles
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink tag={RRNavLink} to="/app/admin/blog">
              Blog Posts
            </NavLink>
          </NavItem>
        </Nav>

        <hr />

        <Routes>
          <Route path="contents" element={<ContentEditor />} />
          <Route path="collections" element={<CollectionEditor />} />
          <Route path="jobs" element={<JobEditor />} />
          <Route path="users" element={<UserEditor />} />
          <Route path="support" element={<SupportArticleEditor />} />
          <Route path="blog" element={<BlogEditor />} />
        </Routes>
      </Col>
    </Row>
  );
};

export default Admin;
