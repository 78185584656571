import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const User = ({ _id, email, status, firstName, lastName, firmName, firmLogoUrl, lastLogin }) => (
  <div className="user">
    <div>
      <b className="label">Id:</b> {_id}
    </div>
    <div>
      <b className="label">Email:</b> {email}
    </div>
    <div>
      <b className="label">Status:</b> {status}
    </div>
    <div>
      <b className="label">First Name:</b> {firstName}
    </div>
    <div>
      <b className="label">Last Name:</b> {lastName}
    </div>
    <div>
      <b className="label">Company Name:</b> {firmName}
    </div>
    {lastLogin && (
      <div>
        <b className="label">Last Login:</b> {moment(lastLogin).format('ddd DD-MMM-YYYY, h:mm A')}
      </div>
    )}
    {firmLogoUrl && (
      <div className="firm-logo-container">
        <b className="label">Company Logo:</b>{' '}
        <img className="firm-logo" src={firmLogoUrl} alt="Company Logo" />
      </div>
    )}
  </div>
);

User.propTypes = {
  _id: PropTypes.string,
  email: PropTypes.string,
  status: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  firmName: PropTypes.string,
  firmLogoUrl: PropTypes.string,
  lastLogin: PropTypes.string,
};

User.defaultProps = {
  _id: null,
  email: null,
  status: null,
  firstName: null,
  lastName: null,
  firmName: null,
  firmLogoUrl: null,
  lastLogin: null,
};
export default User;
