import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import Helpers from 'client-helpers';

function sendPageView(location) {
  ReactGA.send({
    hitType: 'pageview',
    page: location.pathname,
  });
}

function GAListener({ children, trackingId }) {
  const location = useLocation();
  useEffect(() => {
    if (Helpers.isTest() || !trackingId) {
      return () => {};
    }

    ReactGA.initialize(trackingId);
    return sendPageView(location);
  }, [location, trackingId]);

  return children;
}

GAListener.propTypes = {
  children: PropTypes.node,
  trackingId: PropTypes.string,
  history: PropTypes.shape({
    listen: PropTypes.func,
  }),
};

export default GAListener;
