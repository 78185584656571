import React from 'react';
import PropTypes from 'prop-types';
import { useUsers } from 'services/Users';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

const ForgotPasswordModal = ({ show, toggle, email: inputEmail }) => {
  const Users = useUsers();
  const [email, setEmail] = React.useState(inputEmail || '');

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const onChangePasswordClick = async () => {
    await Users.sendResetPasswordEmail(email);

    window.flash('Check your email for a reset password link!', 'success');

    setEmail('');
    toggle();
  };

  return (
    <Modal isOpen={show} toggle={toggle}>
      <ModalHeader toggle={toggle}>Forgot password</ModalHeader>
      <ModalBody>
        <Row className="form-group">
          <Col>
            <FormGroup>
              <Label for="fpEmailField">Email</Label>
              <Input
                type="email"
                name="email"
                id="fpEmailField"
                placeholder="Email"
                value={email}
                onChange={onChange}
                invalid={!email}
                required
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onChangePasswordClick}>
          Change password
        </Button>{' '}
        <Button color="default" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ForgotPasswordModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  email: PropTypes.string,
};

ForgotPasswordModal.defaultProps = {
  email: '',
};

export default ForgotPasswordModal;
