import React from 'react';
import { useStore, useStoreValue } from 'react-context-hook';
import { storeMap } from 'services/constants';
import { Row, Col, FormGroup, Input, Button, Label } from 'reactstrap';
import { useUsers } from 'services/Users';

import './UserAISettings.css';

const UserAISettings = () => {
  const Users = useUsers();
  const [user, setUser] = useStore(storeMap.USER);
  const [noInvestmentAdvice, setNoInvestmentAdvice] = React.useState(
    user.AISettings?.noInvestmentAdvice || false
  );
  const [isLoading, setIsLoading] = React.useState(false);

  const updateUser = async () => {
    setIsLoading(true);
    const updatedAISettings = {
      noInvestmentAdvice,
    };
    const resolvedUser = await Users.update({ _id: user._id, AISettings: updatedAISettings });

    setIsLoading(false);

    if (!resolvedUser) {
      window.flash('Error updating user', 'danger');
      return;
    }

    window.flash('Updated user settings', 'success');
    setUser(resolvedUser);
  };

  return (
    <Row className="user-ai-settings">
      <Col>
        <h3 className="line-text">AI</h3>

        <p>Tune your AI suggestions to engage your audience.</p>

        <Row>
          <Col>
            <FormGroup>
              <Label>Add no specific investment advice clause?</Label>
              <Input
                type="checkbox"
                name="noInvestmentAdvice"
                checked={noInvestmentAdvice}
                disabled={isLoading}
                onChange={(event) => {
                  setNoInvestmentAdvice(event.target.checked);
                }}
              />
            </FormGroup>
          </Col>
        </Row>

        <Button size="sm" color="primary" className="cta" onClick={updateUser}>
          Save
        </Button>
        <hr />
      </Col>
    </Row>
  );
};

export default UserAISettings;
