import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';
import {
  Row,
  Col,
  Input,
  Button,
  FormText,
  // Dropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
} from 'reactstrap';
import ReactDatePicker from 'react-datepicker';
import Autolinker from 'autolinker';
import Helpers from 'client-helpers';
import ReactTooltip from 'react-tooltip';
import twttr from 'twitter-text';
import { useSetStoreValue, useStore, useStoreValue } from 'react-context-hook';
import { useJobs } from 'services/Jobs';
import { useContents } from 'services/Contents';
import { useSocialPosts } from 'services/SocialPosts';
import { storeMap, SocialChannels, UserStatus, AI_MODEL, AI_MAX_TOKENS } from 'services/constants';

import './SocialPostInput.css';

const SocialPostInput = ({ socialPost, setSocialPost, onSave }) => {
  const Jobs = useJobs();
  const Contents = useContents();
  const SocialPosts = useSocialPosts();
  const token = useStoreValue(storeMap.TOKEN);
  const user = useStoreValue(storeMap.USER);
  const selectedContentId = useStoreValue(storeMap.SELECTED_CONTENT_ID);
  const [jobsById, setJobsById] = useStore(storeMap.JOBS_BY_ID, {});
  const setViewingContentId = useSetStoreValue(storeMap.VIEWING_CONTENT_ID);
  const [selectedPlatforms, setSelectedPlatforms] = React.useState([]);
  const [availableChannels, setAvailableChannels] = React.useState([]);
  const [isEditingDate, setIsEditingDate] = React.useState(false);
  const [isUploadingFile, setIsUploadingFile] = React.useState(false);
  // const [optionsDropdownOpen, setOptionsDropdownOpen] = React.useState(false);
  const attachImageRef = React.createRef();
  const attachVideoRef = React.createRef();
  const socialCardRef = React.createRef();
  const unfurlImageRef = React.createRef();
  const textareaRef = React.createRef();
  const [isContentOverflowing, setIsContentOverflowing] = React.useState(false);
  const [isFullHeight, setIsFullHeight] = React.useState(false);
  const [linkInfo, setLinkInfo] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [previousSocialPostText, setPreviousSocialPostText] = React.useState('');
  const debouncedSocialPostText = Helpers.useDebounce(socialPost.data?.text, 100);
  const twitterSelected = selectedPlatforms.includes(SocialChannels.TWITTER);

  const onFileSelect = async (event, type = 'image') => {
    const file = event.currentTarget?.files?.[0];
    const socialPostProp = type === 'image' ? 'imageUrl' : 'videoUrl';

    if (!file) {
      return;
    }

    // Janky AF but we have to remove spaces here
    Object.defineProperty(file, 'name', {
      writable: true,
      value: file.name.replace(/ /g, '_'),
    });

    setIsUploadingFile(true);
    const fileUrl = await Helpers.uploadUserMedia(file, token);
    setIsUploadingFile(false);

    setSocialPost({
      ...socialPost,
      data: {
        ...socialPost.data,
        [socialPostProp]: fileUrl,
      },
    });
  };

  const scheduleSocialPost = async () => {
    const contentEmpty =
      !socialPost?.data?.text?.trim() && !socialPost?.data?.imageUrl && !socialPost?.data?.videoUrl;
    const isSubscribed = user.status !== UserStatus.CANCELED;
    if ((contentEmpty && !socialPost.jobId) || !isSubscribed) {
      window.flash('Social Post empty', 'danger');
      return;
    }

    if (
      selectedPlatforms.includes(SocialChannels.INSTAGRAM) &&
      !socialPost?.data?.imageUrl &&
      !socialPost?.data?.videoUrl &&
      !socialPost.jobId
    ) {
      window.flash('Instagram requires either an image or video.', 'danger');
      return;
    }

    if (twitterSelected && socialPost.data?.text?.length > 280) {
      window.flash('Twitter posts can be a max length of 280 characters.', 'danger');
      return;
    }

    if (socialPost.jobId) {
      let job = jobsById[socialPost.jobId];

      if (!user.firmLogoUrl || !user.firmWebsiteUrl) {
        window.flash('Video rendering requires a valid Company logo and Website', 'danger');
        return;
      }

      // Persist job to be rendered if it's not already
      if (!job?.createdAt) {
        job = await Jobs.create(job);
      }

      if (!job?._id) {
        window.flash('Error rendering video', 'danger');
        return;
      }

      jobsById[job._id] = job;
      socialPost.jobId = job._id;
      setJobsById({ ...jobsById });
    }

    if (socialPost.data?.imageUrl && socialPost.data?.watermarkImageUrl) {
      // watermark image
      const watermarkedImage = await Helpers.watermarkImage(
        socialPost.data.imageUrl,
        socialPost.data.watermarkImageUrl
      );

      // Upload to S3
      const imageUrl = await Helpers.uploadUserMedia(watermarkedImage, token);
      socialPost.data.imageUrl = imageUrl;
    }

    const socialPostPromises = [];

    selectedPlatforms.forEach((platform) => {
      const platformUsername = Helpers.getPlatformUsername(user, platform);

      socialPostPromises.push(
        SocialPosts.upsert(
          {
            ...socialPost,
            contentId: selectedContentId,
            platform,
            platformUsername,
          },
          true
        )
      );
    });

    await Promise.all(socialPostPromises);

    const successMessage = 'Your content has been scheduled!';
    window.flash(successMessage, 'success', 15000);

    setSocialPost();
  };

  // Copy social post to new Content
  const convertToContent = async () => {
    const contentCopy = await Contents.create({
      collectionIds: [user.privateCollectionId],
      text: socialPost.data?.text,
      imageUrl: socialPost.data?.imageUrl,
      videoUrl: socialPost.data?.videoUrl,
    });

    if (contentCopy) {
      setViewingContentId(contentCopy._id);
    }
  };

  const formattedPrompt = React.useMemo(() => {
    const { noInvestmentAdvice } = user.AISettings || {};
    let finalizedPrompt = `social post about ${debouncedSocialPostText}`;

    if (noInvestmentAdvice) {
      finalizedPrompt += ` (do not include SEC or FINRA regulated content, investment advice 
        or strategies. Do not mention specific stocks, funds, or ETFs.)`;
    }

    return finalizedPrompt;
  }, [debouncedSocialPostText, user.AISettings]);

  // Update social post with AI Completion
  const generate = async () => {
    setIsLoading(true);

    let response;
    try {
      response = await axios.post(
        '/api/ai/chat_completion',
        {
          prompt: formattedPrompt,
          maxTokens: AI_MAX_TOKENS,
          model: AI_MODEL.GPT_35_TURBO_0301,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      console.error(errorMessage);
      window.flash(errorMessage, 'warning');
      setIsLoading(false);
      return;
    }

    let completion = response.data.result?.choices?.[0]?.message?.content?.replaceAll('\n', '');

    if (linkInfo?.url && !completion.includes(linkInfo.url)) {
      completion += ` ${linkInfo.url}`;
    }

    setSocialPost({
      ...socialPost,
      data: {
        ...socialPost.data,
        text: completion,
      },
    });
    setPreviousSocialPostText(debouncedSocialPostText);
    setIsLoading(false);
  };

  React.useEffect(() => {
    const userAvailableChannels = [];
    const selectedChannels = [];
    if (user.facebook) {
      userAvailableChannels.push({
        platformName: SocialChannels.FACEBOOK,
        platformUsername: Helpers.getPlatformUsername(user, SocialChannels.FACEBOOK),
      });
      selectedChannels.push(SocialChannels.FACEBOOK);
    }
    if (user.instagram) {
      userAvailableChannels.push({
        platformName: SocialChannels.INSTAGRAM,
        platformUsername: Helpers.getPlatformUsername(user, SocialChannels.INSTAGRAM),
      });
      selectedChannels.push(SocialChannels.INSTAGRAM);
    }
    if (user.twitter) {
      userAvailableChannels.push({
        platformName: SocialChannels.TWITTER,
        platformUsername: Helpers.getPlatformUsername(user, SocialChannels.TWITTER),
      });
      selectedChannels.push(SocialChannels.TWITTER);
    }
    if (user.linkedin) {
      userAvailableChannels.push({
        platformName: SocialChannels.LINKEDIN,
        platformUsername: Helpers.getPlatformUsername(user, SocialChannels.LINKEDIN),
      });
      selectedChannels.push(SocialChannels.LINKEDIN);
    }
    setAvailableChannels(userAvailableChannels);
    setSelectedPlatforms(selectedChannels);
  }, [user]);

  React.useEffect(() => {
    if (!socialCardRef.current) {
      return null;
    }

    if (unfurlImageRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        // Do what you want to do when the size of the element changes
        setIsContentOverflowing(
          socialCardRef.current?.clientHeight < socialCardRef.current?.scrollHeight
        );
      });
      resizeObserver.observe(unfurlImageRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }

    setIsContentOverflowing(
      socialCardRef.current?.clientHeight < socialCardRef.current?.scrollHeight
    );

    return null;
  }, [socialCardRef, unfurlImageRef, linkInfo, debouncedSocialPostText]);

  React.useEffect(() => {
    // Reset height to match text
    textareaRef.current.style.height = '1px';
    textareaRef.current.style.height = `${8 + textareaRef.current.scrollHeight}px`;
    textareaRef.current.style.maxHeight = textareaRef.current.style.height;

    if (!debouncedSocialPostText) {
      return;
    }

    const unfurl = async () => {
      // Unfurl links
      const links = Autolinker.parse(debouncedSocialPostText);
      const url = links.find((iLink) => iLink.url)?.url;
      if (url) {
        const unfurlResponse = await Helpers.unfurl({ token, url });
        setLinkInfo({ ...unfurlResponse, url });
      } else {
        if (linkInfo) {
          setIsFullHeight(false);
        }
        setLinkInfo();
      }
    };

    unfurl();
  }, [debouncedSocialPostText]);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, [availableChannels]);

  const { platform, jobId, data: { text, imageUrl, videoUrl } = {} } = socialPost;
  const remainingCharacters = 280 - twttr.parseTweet(text || '').weightedLength;
  const isOverLength = twitterSelected && remainingCharacters < 0;
  const job = jobsById[jobId];
  const hasVideo = !!(videoUrl || jobId);
  const hasImage = !!imageUrl;
  const hasContent = text || imageUrl || videoUrl || jobId;

  return (
    <Row className="social-input">
      <Col>
        <ReactTooltip effect="solid" />
        <Row>
          <Col className="platform-select">
            {platform ? (
              <i className={`fab fa-${platform}`} />
            ) : (
              <>
                {availableChannels.map(({ platformName, platformUsername }) => {
                  const isSelected = selectedPlatforms.includes(platformName);

                  return (
                    <i
                      key={platformName}
                      data-tip={`${platformUsername} - Click to enable/disable`}
                      className={`fab fa-${platformName} ${!isSelected ? 'disabled' : ''}`}
                      onClick={() => {
                        if (isSelected) {
                          setSelectedPlatforms([
                            ...selectedPlatforms.filter((i) => i !== platformName),
                          ]);
                        } else {
                          setSelectedPlatforms([...selectedPlatforms, platformName]);
                        }
                      }}
                    />
                  );
                })}
              </>
            )}
          </Col>
          <Col>
            {/* <Dropdown
              className="option-menu"
              isOpen={optionsDropdownOpen}
              toggle={() => setOptionsDropdownOpen(!optionsDropdownOpen)}
            >
              <DropdownToggle color="none">
                <i className="fa-solid fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem
                  disabled={!hasContent}
                  onClick={() => {
                    convertToContent();
                  }}
                >
                  Save as content
                </DropdownItem>
              </DropdownMenu>
            </Dropdown> */}
          </Col>
        </Row>

        {twitterSelected && (
          <FormText title="Twitter only allows up to 280 characters">
            {remainingCharacters} characters ramaining
          </FormText>
        )}

        <Button
          className="clear-input-button"
          color="none"
          onClick={() => {
            setSocialPost({
              ...socialPost,
              data: {},
            });
            setLinkInfo();
            setIsFullHeight(false);
          }}
        >
          <i className="fa-solid fa-times" />
        </Button>

        <div
          className={`social-input__text ${isFullHeight ? 'full-height' : ''}`}
          ref={socialCardRef}
        >
          <div className="social-input__text-container">
            <Input
              type="textarea"
              name="text"
              placeholder="What do you want to say?"
              value={text || ''}
              innerRef={textareaRef}
              onChange={(event) => {
                setSocialPost({
                  ...socialPost,
                  data: {
                    ...socialPost.data,
                    text: event.target.value,
                  },
                });
              }}
            />

            {imageUrl && (
              <div>
                <img src={imageUrl} alt={text} />
              </div>
            )}

            {videoUrl ? (
              <div className="video-container" key={videoUrl}>
                <video
                  className="video-thumbnail"
                  controls
                  controlsList="nodownload"
                  preload="metadata"
                >
                  <source src={videoUrl} type="video/mp4" />
                </video>
              </div>
            ) : (
              <div>
                {job && (
                  <div className="video-container" key={job.url}>
                    <video
                      className="video-thumbnail"
                      controls
                      controlsList="nodownload"
                      preload="metadata"
                    >
                      <source src={job.url} type="video/mp4" />
                    </video>
                  </div>
                )}
              </div>
            )}

            {linkInfo?.description && (
              <div onClick={() => window.open(linkInfo.url, '_blank')} className="linkInfo">
                <div className="line-text">
                  <span className="subtle-text">Link preview</span>
                </div>
                {linkInfo.title && <h6>{linkInfo.title}</h6>}
                {linkInfo.description}
                {linkInfo.imageUrl && (
                  <img src={linkInfo.imageUrl} alt="Link" ref={unfurlImageRef} />
                )}
              </div>
            )}
          </div>

          {isContentOverflowing && !isFullHeight && (
            <div className="content-card__expand" onClick={() => setIsFullHeight(true)}>
              <i className="fa-solid fa-chevron-down" />
            </div>
          )}
          {isFullHeight && (
            <div className="content-card__expand" onClick={() => setIsFullHeight(false)}>
              <i className="fa-solid fa-chevron-up" />
            </div>
          )}
        </div>

        <Row>
          {isUploadingFile ? (
            <Col className="attach-buttons" md={4}>
              <i className="fa-solid fa-circle-o-notch fa-spin" />
            </Col>
          ) : (
            <Col className="attach-buttons" md={4}>
              <input
                className="attach-image-input"
                type="file"
                accept="image/*"
                ref={attachImageRef}
                onChange={(e) => onFileSelect(e, 'image')}
              />

              {hasImage ? (
                <i
                  className="fa-solid fa-image attached"
                  data-tip="Remove image"
                  disabled={socialPost.data?.imageUrl}
                  onClick={() => {
                    delete socialPost.data?.imageUrl;
                    setSocialPost({ ...socialPost });
                  }}
                />
              ) : (
                <i
                  className="fa-solid fa-image"
                  data-tip="Attach image"
                  disabled={socialPost.data?.imageUrl}
                  onClick={() => attachImageRef.current.click()}
                />
              )}

              <input
                className="attach-video-input"
                type="file"
                accept="video/*"
                ref={attachVideoRef}
                onChange={(e) => onFileSelect(e, 'video')}
              />

              {hasVideo ? (
                <i
                  className="fa-solid fa-video attached"
                  data-tip="Remove video"
                  disabled={socialPost.data?.videoUrl}
                  onClick={() => {
                    delete socialPost.data?.videoUrl;
                    delete socialPost.jobId;
                    setSocialPost({ ...socialPost });
                  }}
                />
              ) : (
                <i
                  className="fa-solid fa-video"
                  data-tip="Attach video"
                  disabled={socialPost.data?.videoUrl}
                  onClick={() => {
                    attachVideoRef.current?.click();
                  }}
                />
              )}
            </Col>
          )}
          <Col className="scheduled-text" md={8}>
            {isEditingDate ? (
              <>
                <ReactDatePicker
                  selected={moment.utc(socialPost.date).toDate()}
                  onChange={(newDate) => {
                    const now = new Date();
                    if (newDate < now) {
                      newDate = now;
                    }
                    setSocialPost({ ...socialPost, date: newDate.toUTCString() });
                  }}
                  dateFormat="h:mm aa"
                  timeCaption="Time"
                  showTimeSelect
                />
                <Button color="none" size="sm" onClick={() => setIsEditingDate(false)}>
                  Done
                </Button>
              </>
            ) : (
              <span onClick={() => setIsEditingDate(true)}>
                <i className="fa-solid fa-pencil" />
                {moment(socialPost.date).format('ddd, MMM Do [at] h:mm a')}
              </span>
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            {previousSocialPostText ? (
              <Button
                className="ai-enhance-button"
                color="none"
                size="sm"
                onClick={() => {
                  setSocialPost({
                    ...socialPost,
                    data: {
                      ...socialPost.data,
                      text: previousSocialPostText,
                    },
                  });
                  setPreviousSocialPostText();
                }}
              >
                Revert AI
              </Button>
            ) : (
              <Button
                className="ai-enhance-button"
                color="cta"
                size="sm"
                disabled={!debouncedSocialPostText}
                onClick={() => {
                  generate();
                }}
              >
                {isLoading ? (
                  <i className="fa-solid fa-circle-o-notch fa-spin" />
                ) : (
                  <>
                    Enhance with AI <i className="fa-solid fa-microchip" />
                  </>
                )}
              </Button>
            )}
          </Col>
          <Col className="social-input__buttons">
            <Button data-tip="Save as Content" color="none" onClick={convertToContent}>
              Save
            </Button>
            {socialPost._id ? (
              <Button
                onClick={() => {
                  SocialPosts.upsert(socialPost, true);
                  onSave(socialPost);
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                data-tip={!hasContent ? 'Not content entered yet' : ''}
                color="primary"
                size="sm"
                onClick={async () => {
                  await scheduleSocialPost();
                }}
                disabled={!hasContent || selectedPlatforms.length === 0 || isOverLength}
              >
                Schedule
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

SocialPostInput.propTypes = {
  socialPost: PropTypes.shape({
    _id: PropTypes.string,
    platform: PropTypes.string,
    jobId: PropTypes.string,
    status: PropTypes.string,
    data: PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      imageUrl: PropTypes.string,
      watermarkImageUrl: PropTypes.string,
      videoUrl: PropTypes.string,
      createdAt: PropTypes.string,
    }),
    date: PropTypes.oneOfType([Date, PropTypes.string]),
    createdAt: PropTypes.string,
  }),
  setSocialPost: PropTypes.func,
  onSave: PropTypes.func,
};

SocialPostInput.defaultProps = {
  socialPost: null,
  setSocialPost: (v) => v,
  onSave: (v) => v,
};

export default SocialPostInput;
