import React from 'react';
import moment from 'moment';
import Helpers from 'client-helpers';
import ReactTooltip from 'react-tooltip';
import { useStore, useStoreValue } from 'react-context-hook';
import withSmoothLoad from 'components/withSmoothLoad';
import { storeMap, Tours } from 'services/constants';
import { useUsers } from 'services/Users';
import { Row, Col } from 'reactstrap';
import SocialPostInput from 'components/SocialPostInput';
import { firstPostTour } from 'util/shepherd';

import './SocialPostForm.css';

const SocialPostForm = () => {
  const Users = useUsers();
  const [user, setUser] = useStore(storeMap.USER);
  const selectedDate = useStoreValue(storeMap.SELECTED_DATE);
  const [stubSocialPost, setStubSocialPost] = useStore(storeMap.STUB_SOCIAL_POST);

  const setupSocialPost = React.useCallback((date, userObject) => {
    // Create socialPost
    const socialPost = {
      userId: userObject._id,
      data: {},
    };

    const isInPast = moment(date).isBefore(moment());
    if (isInPast) {
      date = new Date();
    }

    let randomScheduleTime;

    if (userObject.preferredPostHour) {
      const hour = userObject.preferredPostHour.toSring();
      const minute = userObject.preferredPostMinute.toString();
      const today = moment(date).toDate();
      randomScheduleTime = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        hour,
        minute
      );
    } else {
      const startDateTime = moment(date, 'YYYY-MM-DD').set('hours', 8);
      const endDateTime = moment(date, 'YYYY-MM-DD').set('hours', 18);
      randomScheduleTime = Helpers.randomTime(startDateTime.toDate(), endDateTime.toDate());
    }

    const now = new Date();
    if (randomScheduleTime < now) {
      randomScheduleTime = now;
    }

    socialPost.date = randomScheduleTime.toUTCString();

    return socialPost;
  }, []);

  // Resolve SocialPost
  React.useEffect(() => {
    if (!stubSocialPost) {
      const newStubSocialPost = setupSocialPost(selectedDate, user);
      setStubSocialPost(newStubSocialPost);
    }

    ReactTooltip.rebuild();
  }, [stubSocialPost, selectedDate, user, setupSocialPost]);

  React.useEffect(() => {
    if (user.flags?.[Tours.FIRST_POST]) {
      const tour = firstPostTour();
      setTimeout(() => {
        tour.start();
      }, 500);

      Users.completeTour(Tours.FIRST_POST);
      setUser({ ...user, flags: { ...user.flags, [Tours.SOCIAL_LANDING]: null } });

      return () => {
        tour.complete();
      };
    }

    return null;
  }, []);

  if (!selectedDate || !stubSocialPost) {
    return <i className="fa-solid fa-circle-o-notch fa-spin" />;
  }

  return (
    <Row className="social-form">
      <Col>
        <SocialPostInput socialPost={stubSocialPost} setSocialPost={setStubSocialPost} />
      </Col>
    </Row>
  );
};

export default withSmoothLoad(SocialPostForm);
