import React from 'react';
import PropTypes from 'prop-types';
import { useContents } from 'services/Contents';
import { useStore, useStoreValue } from 'react-context-hook';
import { storeMap } from 'services/constants';
import Autolinker from 'autolinker';
import ReactTooltip from 'react-tooltip';
import { Modal, ModalBody, Button } from 'reactstrap';
import Helpers from 'client-helpers';
import ContentCard from 'components/ContentCard';
import ContentInput from 'components/ContentInput';
import Confirm from 'components/ConfirmModal';

import './ContentModal.css';

const ContentModal = ({ edit }) => {
  const Contents = useContents();
  const [viewingContentId, setViewingContentId] = useStore(storeMap.VIEWING_CONTENT_ID);
  const [contentsById, setContentsById] = useStore(storeMap.CONTENTS_BY_ID, {});
  const user = useStoreValue(storeMap.USER);
  const [isEditing, setIsEditing] = React.useState(edit);

  const content = contentsById[viewingContentId];

  // Unfurl links
  React.useEffect(() => {
    if (!content?.text) {
      return;
    }

    const links = Autolinker.parse(content?.text);
    const link = links.find((iLink) => iLink.url)?.url;
    if (link) {
      Contents.unfurl(content._id, link);
    }
  }, [content]);

  // Copy Content users private collection
  const copyContent = async () => {
    const contentCopy = await Contents.create({
      ...content,
      collectionIds: [user.privateCollectionId],
      copiedFrom: content._id,
      _id: undefined,
      createdAt: undefined,
      updatedAt: undefined,
    });

    if (contentCopy) {
      setViewingContentId(contentCopy._id);
    }
  };

  if (!content) {
    return null;
  }

  const setContent = (updatedContent) => {
    contentsById[updatedContent._id] = updatedContent;
    setContentsById({ ...contentsById });
  };

  const onClose = () => {
    setViewingContentId();
  };

  const canEdit = Helpers.canEditContent(content, user);

  return (
    <Modal className="content-modal" isOpen toggle={() => onClose()}>
      <ModalBody id="content-modal-body">
        <ReactTooltip effect="solid" anchorId="content-modal-body" />

        <i className="fa-solid fa-times" onClick={() => onClose()} />

        {isEditing && <ContentInput content={content} setContent={setContent} />}
        {!isEditing && <ContentCard {...content} showUnfurl />}

        <div className="content-modal__buttons">
          {!canEdit && (
            <i
              className="fa-solid fa-copy"
              data-tip="Customize Content"
              onClick={async () => {
                await copyContent();
                setIsEditing(true);
              }}
            />
          )}
          {canEdit && (
            <Button
              color="primary"
              className="content-modal__edit-button"
              onClick={() => {
                if (isEditing) {
                  Contents.update(content, true);
                }
                setIsEditing(!isEditing);
              }}
            >
              {isEditing ? 'Save' : 'Edit'}
            </Button>
          )}

          {canEdit && (
            <i
              className="fa-solid fa-trash"
              onClick={async () => {
                if (
                  await Confirm({
                    message: `Delete Content?`,
                  })
                ) {
                  Contents.deleteContent(content._id);
                  onClose();
                }
              }}
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

ContentModal.propTypes = {
  edit: PropTypes.bool,
};

ContentModal.defaultProps = {
  edit: false,
};

export default ContentModal;
