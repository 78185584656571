import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { EMAIL_REGEX } from 'util/constants';
import { useUsers } from 'services/Users';
import TextInput from 'components/TextInput';
import { UserStatus } from 'services/constants';

const NewUserForm = ({ onCreate, onCancel }) => {
  const Users = useUsers();
  const [user, setUser] = React.useState({
    email: '',
    firstName: '',
    lastName: '',
    firmName: '',
    firmWebsiteUrl: '',
    status: UserStatus.DEMO,
  });

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value, emailError: null });
  };

  const createUser = async () => {
    const newUser = await Users.create(user);

    if (!newUser) {
      window.flash('Error creating user', 'danger');
      return;
    }

    window.flash('User created', 'success');

    onCreate(newUser);
    onCancel();
  };

  const {
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    firmName,
    firmWebsiteUrl,
    emailError,
  } = user;

  const emailInvalid = !email || !EMAIL_REGEX.test(email);
  const passwordInvalid = password && password !== confirmPassword;

  return (
    <Row className="new-user-form">
      <Col>
        <h3 className="line-text">Create Demo Account</h3>
        <Row>
          <Col md={{ size: 4, offset: 4 }}>
            <TextInput
              type="email"
              name="email"
              value={email}
              onChange={onChange}
              invalid={emailInvalid}
              feedback={emailError}
              required
            />

            <TextInput
              type="password"
              name="password"
              displayText="Password"
              value={password}
              onChange={onChange}
              invalid={passwordInvalid}
              required
            />
            <TextInput
              type="password"
              name="confirmPassword"
              displayText="Confirm Password"
              value={confirmPassword}
              onChange={onChange}
              invalid={passwordInvalid}
              required
            />

            <TextInput
              name="firstName"
              displayText="First Name"
              value={firstName}
              onChange={onChange}
            />
            <TextInput
              name="lastName"
              displayText="Last Name"
              value={lastName}
              onChange={onChange}
            />

            <TextInput
              name="firmName"
              displayText="Company Name"
              value={firmName}
              onChange={onChange}
            />
            <TextInput
              name="firmWebsiteUrl"
              value={firmWebsiteUrl}
              displayText="Company Website"
              onChange={onChange}
            />
          </Col>
        </Row>

        <Button
          color="primary"
          className="success"
          onClick={createUser}
          disabled={emailInvalid || passwordInvalid}
        >
          Create
        </Button>

        {onCancel && (
          <Button size="sm" color="default" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </Col>
    </Row>
  );
};

NewUserForm.propTypes = {
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
};

NewUserForm.defaultProps = {
  onCancel: null,
  onCreate: () => {},
};

export default NewUserForm;
