/* eslint-disable max-len */
export const MAX_IMAGE_SIZE = 5 * 1000 * 1000; // 5Mb

export const MAX_IMAGE_WIDTH = 2048;

export const MAX_IMAGE_HEIGHT = 2048;

export const MAX_LOGO_WIDTH = 1000;

export const MAX_LOGO_HEIGHT = 600;

export const MIN_LOGO_WIDTH = 256;

export const MIN_LOGO_HEIGHT = 128;

export const MAX_VIDEO_SIZE = 100 * 1000 * 1000; // 100Mb

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
