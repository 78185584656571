import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children || null;
};

ScrollToTop.propTypes = {
  location: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.element),
};

ScrollToTop.defaultProps = {
  location: 0,
  children: null,
};

export default ScrollToTop;
