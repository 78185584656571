import React from 'react';
import { Row, Col, Button, FormGroup, Input } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useUsers } from 'services/Users';

import './PasswordReset.css';
const PasswordReset = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const Users = useUsers();
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onUpdateClick = async () => {
    setIsSubmitting(true);

    const success = await Users.resetPassword({ token, password });

    if (!success) {
      window.flash('Error resetting password. Please try again.', 'danger');
      setIsSubmitting(false);
      return;
    }

    // Show user success message
    window.flash('Password successfully changed! Redirecting to homepage in 5 seconds...');
    setPassword('');
    setConfirmPassword('');

    // Route to homepage after 5 seconds
    setTimeout(() => {
      navigate('/');
    }, 5000);
  };

  return (
    <Row className="password-reset">
      <Col md={{ size: 8, offset: 2 }} className="content-section">
        <a href="/">
          <img className="logo" alt="Storiful Logo" src="/img/storiful-logo-color-small.png" />
        </a>
        <p>Enter your new password below:</p>

        <FormGroup>
          <Input
            type="password"
            name="password"
            id="passwordField"
            placeholder="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            invalid={!password}
            required
          />
        </FormGroup>

        <FormGroup>
          <Input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
            invalid={!confirmPassword || password !== confirmPassword}
            required
          />
        </FormGroup>

        <FormGroup>
          <Button
            color="primary"
            onClick={onUpdateClick}
            disabled={isSubmitting || !password || password !== confirmPassword}
          >
            Update
          </Button>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default PasswordReset;
