import React from 'react';
import PropTypes from 'prop-types';
import useCollections from 'services/Collections';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormText,
} from 'reactstrap';

const ShareCollectionModal = ({ show, toggle, collection }) => {
  const Collections = useCollections();
  const [emails, setEmails] = React.useState('');

  const onChange = (e) => {
    setEmails(e.target.value);
  };

  const onShareClick = async () => {
    await Collections.share(collection._id, emails);

    window.flash('Collection shared successfully', 'success');

    toggle();
  };

  return (
    <Modal isOpen={show} toggle={toggle}>
      <ModalHeader toggle={toggle}>Share collection</ModalHeader>
      <ModalBody>
        <Row className="form-group">
          <Col>
            <FormGroup>
              <Label for="emailsField">Emails</Label>
              <Input
                type="email"
                name="email"
                id="emailsField"
                placeholder="email@domain.com, email2@domain.com,..."
                value={emails}
                onChange={onChange}
                invalid={!emails}
                required
              />
              <FormText color="muted">Comma separated list of emails</FormText>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onShareClick}>
          Share Collection
        </Button>{' '}
        <Button color="default" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ShareCollectionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  collection: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
};

export default ShareCollectionModal;
