import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import withSmoothLoad from 'components/withSmoothLoad';
import './Pricing.css';

const Pricing = ({ toggleSignupModal }) => {
  const navigate = useNavigate();
  return (
    <div className="pricing">
      <Row>
        <Col>
          <h3>Pricing</h3>
        </Col>
      </Row>

      <div className="pricing-cards">
        <Row>
          {/* <Col md={6}>
            <div className="pricing-card">
              <h4>{StripePlanTitle[StripePlanType.MEMBERSHIP]}</h4>

              <div className="content-section">
                <p>Storiful is a membership based platform. Members pay an annual dues of $99.</p>
                <div>
                  <i className="fa-solid fa-check" /> Schedule and post to Facebook, Instagram,
                  Twitter, and LinkedIn
                </div>
                <div>
                  <i className="fa-solid fa-check" /> Unlimited original posts
                </div>
                <div>
                  <i className="fa-solid fa-check" /> Ready-made content library includes curated
                  articles, graphics, and holidays
                </div>
                <div>
                  <i className="fa-solid fa-check" /> Compliance archiving tool
                </div>
              </div>
              <Button
                size="lg"
                color="cta"
                onClick={() => toggleSignupModal(StripePlanType.MEMBERSHIP)}
              >
                Sign Up Now
              </Button>
            </div>
          </Col> */}

          <Col md={{ size: 6, offset: 3 }}>
            <div className="pricing-card">
              <h4>Enterprise - Contact Us</h4>

              <div className="content-section">
                Custom needs require custom deals. Whether it&apos;s marketing, compliance, or
                custom content, reach out and let us know what your needs are.
              </div>

              <Button
                size="lg"
                color="primary"
                onClick={() => {
                  navigate('/contact');
                }}
              >
                Contact Us
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

Pricing.propTypes = {
  toggleSignupModal: PropTypes.func,
};

Pricing.defaultProps = {
  toggleSignupModal: (v) => v,
};

export default withSmoothLoad(Pricing);
