/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import moment from 'moment';
import EventBus from 'bus';
import { useStoreValue, useStore, useSetStoreValue } from 'react-context-hook';
import { FeatureFlags, storeMap, UserStatus } from 'services/constants';
import { useSocialPosts } from 'services/SocialPosts';
import { useJobs } from 'services/Jobs';
import ReactTooltip from 'react-tooltip';
import withSmoothLoad from 'components/withSmoothLoad';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import Helpers from 'client-helpers';
import Paywall from 'components/Paywall';
import DaySelect from 'App/Dashboard/DaySelect';
import DatePicker from 'react-datepicker';
import ContentContainer from 'components/ContentContainer';
import SocialPostForm from 'App/Dashboard/SocialPostForm';
import ContentPreview from 'App/Dashboard/ContentPreview';
import ContentModal from 'components/ContentModal';
import ContentExportModal from 'components/ContentExportModal';
import CalendarEventPreview from './CalendarEventPreview';
import PromptContainer from 'components/PromptContainer';
import RSSContentsContainer from 'components/RSSContentsContainer';
import AIBlade from 'components/AIBlade';

import './Dashboard.css';

const Dashboard = () => {
  const SocialPosts = useSocialPosts();
  const Jobs = useJobs();
  const user = useStoreValue(storeMap.USER);
  const token = useStoreValue(storeMap.TOKEN);
  const jobsById = useStoreValue(storeMap.JOBS_BY_ID, {});
  const [selectedContentId, setSelectedContentId] = useStore(storeMap.SELECTED_CONTENT_ID);
  const [selectedCalendarEvent, setSelectedCalendarEvent] = useStore(
    storeMap.SELECTED_CALENDAR_EVENT
  );
  const setStubSocialPost = useSetStoreValue(storeMap.STUB_SOCIAL_POST);
  const [viewingContentId] = useStore(storeMap.VIEWING_CONTENT_ID);
  const [selectedDate, setSelectedDate] = useStore(storeMap.SELECTED_DATE);
  const [optionsDropdownOpen, setOptionsDropdownOpen] = React.useState(false);
  const [showAIBlade, setShowAIBlade] = React.useState(false);

  // Fetch social posts for selected week
  React.useEffect(() => {
    const startDttm = moment(selectedDate).startOf('isoWeek').toDate().toISOString(true);
    const endDttm = moment(selectedDate).endOf('isoWeek').toDate().toISOString(true);
    SocialPosts.fetch({
      startDttm,
      endDttm,
    });
    Jobs.fetch({
      date: selectedDate,
    });
    setSelectedContentId(null);
    setStubSocialPost(null);

    const listener = EventBus.addListener('websocket_message', ({ type }) => {
      if (type === 'render_complete') {
        Jobs.fetch({
          date: selectedDate,
        });
        window.flash('Your render is complete!', 'success');
      } else if (type === 'render_error') {
        Jobs.fetch({
          date: selectedDate,
        });
        window.flash('There was an issue completing your render. Please try again!', 'danger');
      } else if (type === 'social_post') {
        SocialPosts.fetch({
          date: selectedDate,
        });
        window.flash('Your content has been posted!', 'success');
      } else if (type === 'social_post_error') {
        window.flash(
          'There was an issue with your request to the Social Platform. Please try again!',
          'danger'
        );
      }
    });

    return function cleanup() {
      listener.removeAllListeners();
    };
  }, [selectedDate]);

  const selectedMonday = Helpers.getMonday(selectedDate);
  const dates = [];
  for (let i = 0; i < 7; i++) {
    const date = moment(selectedMonday).startOf('day').add(i, 'd').toISOString(true).split('T')[0];
    dates.push(date);
  }
  const isContentSelected = selectedContentId || selectedCalendarEvent;
  const isSocialConnected = user.facebook || user.instagram || user.twitter || user.linkedin;

  const renderInspirationComponent = () => {
    if (selectedCalendarEvent) {
      return <CalendarEventPreview />;
    }

    if (selectedContentId) {
      return <ContentPreview />;
    }

    return null;
  };
  return (
    <div className="dashboard">
      <ReactTooltip effect="solid" />

      <Row>
        <Col className="week-select">
          <DatePicker
            customInput={
              <div className="date-range">
                {moment(selectedDate).utc().format('MMMM D, YYYY')}{' '}
                <i className="fa-solid fa-chevron-down" />
              </div>
            }
            selected={moment(selectedDate).utc().toDate()}
            onChange={(date) => {
              setSelectedDate(moment(date).toISOString(true).split('T')[0]);
            }}
          />

          <DaySelect dates={dates} />

          <div className="ai-button">
            <Button
              color="cta"
              onClick={() => {
                setShowAIBlade(true);
              }}
            >
              Storiful AI <i className="fa-solid fa-microchip" />
            </Button>
          </div>

          {showAIBlade && <AIBlade onClose={() => setShowAIBlade(false)} />}

          <Dropdown
            className="dashboard__option-menu"
            isOpen={optionsDropdownOpen}
            toggle={() => setOptionsDropdownOpen(!optionsDropdownOpen)}
          >
            <DropdownToggle color="none">
              <i className="fa-solid fa-ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem
                onClick={async () => {
                  await ContentExportModal({ token, user, jobsById });
                }}
              >
                Export Social Posts
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Col>
      </Row>

      <Row className={`post-form ${selectedContentId ? 'content-selected' : ''}`}>
        <Col md={6}>
          <div className="line-text">Post Form</div>
          {isSocialConnected ? (
            <SocialPostForm />
          ) : (
            <p>
              Head over to <NavLink to="/app/settings/social">Settings</NavLink> to connect a Social
              Account and get posting!
            </p>
          )}
        </Col>

        <Col md={6} className={`content-preview-container ${isContentSelected && 'populated'}`}>
          <div className="line-text">Content Inspiration</div>
          {renderInspirationComponent()}
        </Col>
      </Row>

      <Row>
        <Col>
          {!!user.RSSFeeds?.length && (
            <>
              <h4 className="line-text">News Feeds</h4>
              <RSSContentsContainer
                onRSSContentSelect={(contentId) => {
                  setSelectedContentId(contentId);
                  setSelectedCalendarEvent();
                  window.scrollTo(0, 0);
                }}
              />
            </>
          )}

          <h4 className="line-text">Daily Content Options</h4>

          <ContentContainer
            onContentSelect={(contentId) => {
              setSelectedContentId(contentId);
              setSelectedCalendarEvent();
              window.scrollTo(0, 0);
            }}
          />

          <h4 className="line-text">Content Inspiration</h4>
          <PromptContainer
            onPromptSelect={(contentId) => {
              setSelectedContentId(contentId);
              setSelectedCalendarEvent();
              window.scrollTo(0, 0);
            }}
            onCalendarEventSelect={(calendarEvent) => {
              setSelectedCalendarEvent(calendarEvent);
              setSelectedContentId();
              window.scrollTo(0, 0);
            }}
          />
        </Col>
      </Row>

      {user.status === UserStatus.CANCELED && <Paywall />}

      {viewingContentId && <ContentModal edit />}
    </div>
  );
};

export default withSmoothLoad(Dashboard);
