import React from 'react';
import SignupModal from './SignupModal';
import { Elements } from '@stripe/react-stripe-js';
import { useStoreValue } from 'react-context-hook';
import { storeMap } from 'services/constants';

const SignupModalWithStripe = (props) => {
  const stripe = useStoreValue(storeMap.STRIPE);

  if (!stripe) {
    return <i className="fa-solid fa-circle-o-notch fa-spin loading-icon" />;
  }

  return (
    <Elements stripe={stripe}>
      <SignupModal {...props} />
    </Elements>
  );
};

export default SignupModalWithStripe;
