import React from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import withSmoothLoad from 'components/withSmoothLoad';

import './Support.css';

const SupportArticle = ({ _id, title, body, videoUrl, updatedAt }) => (
  <div className="support-article">
    <Row>
      <Col>
        <h3 className="line-text">{title}</h3>
        <div className="subtle-text">Last updated: {moment(updatedAt).format('MMM D, YYYY')}</div>

        <div className="support-article__body" dangerouslySetInnerHTML={{ __html: body }} />

        {videoUrl && (
          <video
            className="video-thumbnail"
            controls
            disablePictureInPicture
            controlsList="nodownload"
            preload="metadata"
          >
            <source src={videoUrl} type="video/mp4" />
          </video>
        )}
      </Col>
    </Row>
  </div>
);

SupportArticle.propTypes = {
  _id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  videoUrl: PropTypes.string,
  updatedAt: PropTypes.string.isRequired,
};

SupportArticle.defaultProps = {
  videoUrl: null,
};

export default withSmoothLoad(SupportArticle);
