import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { StripePlanType } from 'services/constants';
import withSmoothLoad from '../../components/withSmoothLoad';

import PercentCircle from 'components/PercentCircle';
import './LandingPage.css';

const LandingPage = ({ toggleSignupModal }) => (
  <div className="landing-page">
    <Row>
      <Col className="header-content">
        <Row className="engage-panel">
          <Col md="8">
            <h1>We make social media easy</h1>
          </Col>
          <Col md="4" className="header-image">
            <img src="/img/storiful-logo-color-square.png" alt="Storiful screen shot" />
          </Col>
        </Row>

        <Row>
          <Col>
            <a href="#why-subscribe" className="chevron-down">
              <i className="fa-solid fa-chevron-down" />
            </a>
          </Col>
        </Row>
      </Col>
    </Row>

    <Row id="why-subscribe" className="info-blocks">
      <Col>
        <Row>
          <Col>
            <h1>Storiful Membership</h1>
          </Col>
        </Row>
        <Row>
          <Col md="4" xs="auto" className="info-container">
            <i className="fa-solid fa-hashtag" />
            <p>
              <b>Integrated Content Management System</b>
            </p>
            <p>
              Storing your assets in one place and publishing them in another place is time
              consuming and we hate it. The Storiful platform lets you organize your posts, images
              and videos so they appear in your content calendar when you want them.
            </p>
          </Col>
          <Col md="4" xs="auto" className="info-container">
            <i className="fa-solid fa-clock" />
            <p>
              <b>Community Collections</b>
            </p>
            <p>
              Producing enough content to keep the social media giants happy is exhausting. So we
              built-in community. Have a bomb product reel you want everyone in your organization to
              be able to post? Easy. It will auto appear in their calendar if they are subscribed to
              your feed.
            </p>
          </Col>
          <Col md="4" xs="auto" className="info-container">
            <i className="fa-solid fa-share-alt" />
            <p>
              <b>The ease of AI</b>
            </p>
            <p>
              No one can be an expert in everything. That&apos;s why we hate that small business
              owners also have to be content creators. With Storiful AI you can quickly generate a
              post off of a pre-written topic prompt or choose from Storiful&apos;s library of
              ready-made posts and keep your social media profiles buzzing.
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <a className="btn btn-lg btn-cta" href="#pricing">
              Membership
            </a>
          </Col>
        </Row>
      </Col>
    </Row>

    <div className="stats">
      <Row>
        <Col>
          <h4>
            There&apos;s a reason small business owners feel guilty for not being content creators.
            The stats don&apos;t lie. If you aren&apos;t on social media you are missing out on
            revenue.
          </h4>
        </Col>
      </Row>
      <Row>
        <Col md="9">
          <h1>Engagement</h1>
          <h4>
            The percent of people willing to do business with you after engaging on social media
          </h4>
        </Col>
        <Col md="3">
          <PercentCircle amount={78} />
        </Col>
      </Row>
      <Row>
        <Col md="9">
          <h1>Discovery</h1>
          <h4>43% of consumers use social to discover new products and services.</h4>
        </Col>
        <Col md="3">
          <PercentCircle amount={43} />
        </Col>
      </Row>
    </div>

    {/* 
    <Row className="next-steps">
      <Col>
        <Row>
          <Col>
            <h1>Social Calendar</h1>
            <p>Every day choose one text post from a menu of options.</p>
          </Col>
        </Row>

        <Row>
          <Col>
            <img src="/img/AE_monitor_screenshot.png" alt="Storiful large screen shot" />
          </Col>
        </Row>
      </Col>
    </Row> */}

    <div id="pricing" className="pricing">
      <Row>
        <Col>
          <Row>
            <Col lg="5" className="left">
              <h1>Using the power of community based content to change social media forever.</h1>
              <p />
            </Col>
            <Col md={{ size: 4, offset: 2 }} sm="6" xs="12">
              <div className="pricing-card">
                <h2>Membership</h2>

                <div className="content-section">
                  <div>
                    <i className="fa-solid fa-check" /> Schedule and post to Facebook, Instagram,
                    Twitter, and LinkedIn
                  </div>
                  <div>
                    <i className="fa-solid fa-check" /> Unlimited original posts
                  </div>
                  <div>
                    <i className="fa-solid fa-check" /> Ready-made content library includes curated
                    articles, graphics, and holidays
                  </div>
                  <div>
                    <i className="fa-solid fa-check" /> Compliance archiving tool
                  </div>
                  <div>
                    <i className="fa-solid fa-check" /> ChatGPT integrated post creation
                  </div>
                  <div>
                    <i className="fa-solid fa-check" /> Community Collections
                  </div>
                </div>

                <Button
                  size="lg"
                  color="cta"
                  onClick={() => toggleSignupModal(StripePlanType.MEMBERSHIP)}
                >
                  Get Started!
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  </div>
);

LandingPage.propTypes = {
  toggleSignupModal: PropTypes.func.isRequired,
};

export default withSmoothLoad(LandingPage);
