import axios from 'axios';
import { store, useStoreValue, useSetStoreValue } from 'react-context-hook';
import { ContentType, storeMap } from './constants';
import Helpers from 'client-helpers';

const useContents = () => {
  const setContentsById = useSetStoreValue(storeMap.CONTENTS_BY_ID);
  const token = useStoreValue(storeMap.TOKEN);

  const fetch = async (params = {}, combined = false) => {
    if (!token) {
      return {};
    }

    const endpoint = combined ? '/api/contents/combined' : '/api/contents';

    // Fetch contents
    let response;
    try {
      response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      });
    } catch (error) {
      window.flash('Error fetching Contents', 'warning');
      return {};
    }

    const contents = response.data?.contents || [];
    const hasMoreResults = response.data?.hasMoreResults;
    const count = response.data?.count;
    const contentsById = store.getState()[storeMap.CONTENTS_BY_ID] || {};
    contents.forEach((content) => {
      contentsById[content._id] = content;
    });

    setContentsById({ ...contentsById });
    store.set(storeMap.CONTENTS_BY_ID, contentsById);
    return { hasMoreResults, count };
  };

  const fetchOne = async (contentId) => {
    if (!token) {
      return;
    }

    // Fetch contents
    let response;
    try {
      response = await axios.get(`/api/contents/${contentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      window.flash('Error fetching Content', 'warning');
      return;
    }

    const content = response.data;
    const contentsById = store.getState()[storeMap.CONTENTS_BY_ID] || {};
    contentsById[content._id] = content;
    setContentsById({ ...contentsById });
  };

  const unfurl = async (contentId, url) => {
    let contentsById = store.getState()[storeMap.CONTENTS_BY_ID] || {};
    if (!token || !contentsById[contentId]) {
      return;
    }

    const linkInfo = await Helpers.unfurl({ token, url });

    contentsById = store.getState()[storeMap.CONTENTS_BY_ID] || {};
    if (!contentsById[contentId]) {
      return;
    }

    contentsById[contentId].link = url;
    contentsById[contentId].linkInfo = linkInfo;
    setContentsById({ ...contentsById });
  };

  const create = async (content) => {
    if (!token) {
      return null;
    }

    let response;
    try {
      response = await axios.post(`/api/contents`, content, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      window.flash('Error creating Content', 'warning');
      return null;
    }

    const savedContent = response.data;
    const contentsById = store.getState()[storeMap.CONTENTS_BY_ID] || {};
    contentsById[savedContent._id] = savedContent;
    setContentsById({ ...contentsById });

    window.flash('Content successfully created');
    return savedContent;
  };

  const update = async (content) => {
    if (!token) {
      return;
    }

    let response;
    try {
      response = await axios.put(`/api/contents/${content._id}`, content, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      window.flash('Error updating Content', 'warning');
      return;
    }

    const savedContent = response.data;
    const contentsById = store.getState()[storeMap.CONTENTS_BY_ID] || {};
    contentsById[content._id] = savedContent;
    setContentsById({ ...contentsById });

    window.flash('Content successfully updated');
  };

  const uploadMedia = async (file) => {
    if (!token) {
      return null;
    }

    const uploadUrlResponse = await axios.post(
      `/api/contents/upload`,
      { key: file.name, contentType: file.type },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Handle invalid creation
    if (uploadUrlResponse.status !== 200) {
      window.flash('Error getting upload Url', 'danger');
      return null;
    }

    const { uploadUrl, accessUrl } = uploadUrlResponse.data;

    // Post video data to S3
    try {
      await axios.put(uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      });
    } catch (error) {
      return null;
    }

    return accessUrl;
  };

  const compressVideo = async (contentId, fileName, fileUrl) => {
    if (!token) {
      return null;
    }

    const compressResponse = await axios.post(
      `/api/contents/compress`,
      { contentId, fileName, fileUrl },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Handle invalid creation
    if (compressResponse.status !== 200) {
      window.flash('Error requesting video compress', 'danger');
      return null;
    }

    return compressResponse;
  };

  const deleteContent = async (contentId) => {
    try {
      await axios.delete(`/api/contents/${contentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      return window.flash('Error deleting Content', 'danger');
    }

    const contentsById = store.getState()[storeMap.CONTENTS_BY_ID] || {};
    delete contentsById[contentId];

    setContentsById({ ...contentsById });

    return window.flash('Content deleted');
  };

  const clearCache = (type) => {
    const newCache = {};
    const contentsById = store.getState()[storeMap.CONTENTS_BY_ID] || {};
    if (type === 'text') {
      Object.keys(contentsById).forEach((contentId) => {
        const content = contentsById[contentId];
        if (content.videoUrl) {
          newCache[contentId] = content;
        }
      });
    } else if (type === 'video') {
      Object.keys(contentsById).forEach((contentId) => {
        const content = contentsById[contentId];
        if (!content.videoUrl) {
          newCache[contentId] = content;
        }
      });
    } else if (type === ContentType.READY_MADE) {
      Object.keys(contentsById).forEach((contentId) => {
        const content = contentsById[contentId];
        if (content.type !== ContentType.READY_MADE) {
          newCache[contentId] = content;
        }
      });
    } else if (type === ContentType.PROMPT) {
      Object.keys(contentsById).forEach((contentId) => {
        const content = contentsById[contentId];
        if (content.type !== ContentType.PROMPT) {
          newCache[contentId] = content;
        }
      });
    }
    setContentsById(newCache);
  };

  return {
    fetch,
    fetchOne,
    unfurl,
    create,
    update,
    uploadMedia,
    compressVideo,
    deleteContent,
    clearCache,
  };
};

export default useContents;
export { useContents };
