import React from 'react';
import { Row, Col } from 'reactstrap';
import './FAQ.css';

const FAQ = () => (
  <div className="faq">
    <Row>
      <Col className="text-left">
        <h3>What do I get when I sign up?</h3>
        <p>
          For $99/year you get access to our full social media management tool and content feed,
          which includes a ready-made content library as well as new content every week. You can
          post as much as you want to Facebook, Instagram, Twitter, and Linkedin.
        </p>

        <h3>How do I cancel my subscription?</h3>
        <p>
          You can email us at team@storiful.com. Your cancellation will take place after the current
          bill period, we don&apos;t offer refunds.
        </p>

        <h3>Do you post the content for me?</h3>
        <p>
          You choose what to post. In Storiful you can select your content for the week and schedule
          when you would like it to go out... we will publish it at the right time!
        </p>

        <h3>Will you manage my social media accounts/strategy?</h3>
        <p>
          We won&apos;t be managing your accounts or social media strategy. We&apos;re in the
          business of content. We create it and you post it. It&apos;s that simple.
        </p>

        <h3>Should I still post my own content?</h3>
        <p>
          Yes! Our content is designed to improve your current social media efforts, not replace
          them. We suggest you keep posting your own original content. We&apos;ve even made it easy
          for you to manage and post your content through Storiful.
        </p>

        <h3>I have more questions, how do I contact support? </h3>
        <p>
          Got questions? We&apos;ve got answers! If you don&apos;t see what you&apos;re looking for
          shoot us an email: <a href="mail-to:team@storiful.com">team@storiful.com</a>.
        </p>
      </Col>
    </Row>
  </div>
);

export default FAQ;
