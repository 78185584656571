import React from 'react';
import PropTypes from 'prop-types';

import './PercentCircle.css';

const PercentCircle = ({ amount }) => (
  <div className="percent-circle">
    <div className="single-chart">
      <svg viewBox="0 0 36 36" className="circular-chart">
        <path
          className="circle-bg"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="circle"
          strokeDasharray={`${amount}, 100`}
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="20.35" className="percentage">
          {amount}%
        </text>
      </svg>
    </div>
  </div>
);

PercentCircle.propTypes = {
  amount: PropTypes.number.isRequired,
};

export default PercentCircle;
