/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';
import { useUsers } from 'services/Users';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { EMAIL_REGEX } from 'util/constants';
import './TourModal.css';

const TourModal = ({ show, toggle, email: initialEmail }) => {
  const Users = useUsers();
  const [email, setEmail] = React.useState(initialEmail);
  const [showVideo, setShowVideo] = React.useState(false);

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const onEmailSubmit = async () => {
    await Users.sendTourNotification(email);

    setEmail('');
    setShowVideo(true);
  };

  const emailInvalid = !email || !EMAIL_REGEX.test(email);

  return (
    <Modal isOpen={show} toggle={toggle} className={`tour-modal ${showVideo && 'video'}`}>
      <ModalHeader toggle={toggle}>Storiful Tour</ModalHeader>
      <ModalBody>
        {showVideo ? (
          <div>
            <iframe
              title="Tour Storiful"
              width="960"
              height="540"
              src="https://www.youtube.com/embed/aFNyZMQ00PQ"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        ) : (
          <Row className="form-group">
            <Col>
              <FormGroup>
                <Label for="tourEmailField">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="tourEmailField"
                  placeholder="Email"
                  value={email}
                  onChange={onChangeEmail}
                  invalid={!email || emailInvalid}
                  required
                />
              </FormGroup>

              <Button color="primary" onClick={onEmailSubmit}>
                Watch
              </Button>
            </Col>
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
};

TourModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  email: PropTypes.string,
};

TourModal.defaultProps = {
  email: '',
};

export default TourModal;
