import React from 'react';
import PropTypes from 'prop-types';

import './ContentCard.css';

const ContentCard = ({
  title,
  text,
  link,
  linkInfo,
  imageUrl,
  image,
  videoUrl,
  video,
  showUnfurl,
  showExpand,
}) => {
  const contentCardRef = React.createRef();
  const unfurlImageRef = React.createRef();
  const [isContentOverflowing, setIsContentOverflowing] = React.useState(false);
  const [isFullHeight, setIsFullHeight] = React.useState(false);

  React.useEffect(() => {
    setIsFullHeight(false);
  }, [text]);

  React.useEffect(() => {
    if (!contentCardRef.current) {
      return null;
    }

    if (unfurlImageRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        // Do what you want to do when the size of the element changes
        setIsContentOverflowing(
          contentCardRef.current?.clientHeight < contentCardRef.current?.scrollHeight
        );
      });
      resizeObserver.observe(unfurlImageRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }

    setIsContentOverflowing(
      contentCardRef.current?.clientHeight < contentCardRef.current?.scrollHeight
    );

    return null;
  }, [contentCardRef, unfurlImageRef, linkInfo]);

  return (
    <div className={`content-card ${isFullHeight ? 'full-height' : ''}`}>
      <div ref={contentCardRef} className="content-card__content">
        {title && <div className="title">{title}</div>}

        {imageUrl ? (
          <div>
            <img src={imageUrl} alt={title || text} />
          </div>
        ) : (
          <div>
            {image && (
              <div>
                <img src={URL.createObjectURL(image)} alt={title || text} />
              </div>
            )}
          </div>
        )}

        {text && <div>{text}</div>}

        {videoUrl ? (
          <div className="video-container" key={videoUrl}>
            <video
              className="video-thumbnail"
              controls
              controlsList="nodownload"
              preload="metadata"
            >
              <source src={videoUrl} type="video/mp4" />
            </video>
          </div>
        ) : (
          <div>
            {video && (
              <div className="video-container">
                <video
                  className="video-thumbnail"
                  controls
                  controlsList="nodownload"
                  preload="metadata"
                >
                  <source src={URL.createObjectURL(video)} type="video/mp4" />
                </video>
              </div>
            )}
          </div>
        )}

        {showUnfurl && linkInfo?.description && (
          <div onClick={() => window.open(link, '_blank')}>
            <div className="line-text">
              <span className="subtle-text">Link preview</span>
            </div>
            {linkInfo.title && <h6>{linkInfo.title}</h6>}
            {linkInfo.description}
            {linkInfo.imageUrl && <img src={linkInfo.imageUrl} alt="Link" ref={unfurlImageRef} />}
          </div>
        )}
      </div>
      {showExpand && isContentOverflowing && !isFullHeight && (
        <div className="content-card__expand" onClick={() => setIsFullHeight(true)}>
          <i className="fa-solid fa-chevron-down" />
        </div>
      )}
      {showExpand && isFullHeight && (
        <div className="content-card__expand" onClick={() => setIsFullHeight(false)}>
          <i className="fa-solid fa-chevron-up" />
        </div>
      )}
    </div>
  );
};

ContentCard.propTypes = {
  _id: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  linkInfo: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
  imageUrl: PropTypes.string,
  image: PropTypes.shape({}),
  videoUrl: PropTypes.string,
  video: PropTypes.shape({}),
  showUnfurl: PropTypes.bool,
  showExpand: PropTypes.bool,
};

ContentCard.defaultProps = {
  _id: null,
  title: '',
  text: '',
  link: '',
  linkInfo: null,
  imageUrl: '',
  image: null,
  videoUrl: '',
  video: null,
  showUnfurl: false,
  showExpand: false,
};

export default ContentCard;
