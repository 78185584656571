import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Row, Col, Button, FormGroup, FormText, Label, Input } from 'reactstrap';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

const BlogPostForm = ({ blogPost, createOrUpdateBlogPost, onCancel }) => {
  const [editingBlogPost, setEditingBlogPost] = React.useState(blogPost);

  const onChange = (event) => {
    const { name, value } = event.target;

    setEditingBlogPost({ ...editingBlogPost, [name]: value });
  };

  const handleChange = (value) => {
    setEditingBlogPost({ ...editingBlogPost, htmlContent: value });
  };

  const saveUpdatedBlogPost = () => {
    createOrUpdateBlogPost(editingBlogPost);
  };

  return (
    <Row>
      <Col key={blogPost._id} className="blogPost">
        <div>
          <b>Id:</b> {blogPost._id}
        </div>

        <FormGroup>
          <Label for="titleField">Title*:</Label>
          <Input
            bsSize="sm"
            type="text"
            name="title"
            id="titleField"
            placeholder="Title"
            value={editingBlogPost.title || ''}
            onChange={onChange}
            invalid={!editingBlogPost.title}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label for="keywordsField">Keywords:</Label>
          <Input
            bsSize="sm"
            type="text"
            name="keywords"
            id="keywordsField"
            placeholder="Keywords"
            value={editingBlogPost.keywords || ''}
            onChange={onChange}
          />
          <FormText color="muted">Comma separated list: Word, Word, etc</FormText>
        </FormGroup>

        <FormGroup>
          <ReactQuill
            value={editingBlogPost.htmlContent || ''}
            onChange={handleChange}
            modules={modules}
            formats={formats}
          />
        </FormGroup>

        <div>
          <Button size="sm" color="success" onClick={saveUpdatedBlogPost}>
            {blogPost._id ? 'Save' : 'Create'}
          </Button>
          {onCancel && (
            <Button size="sm" color="default" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </div>
      </Col>
    </Row>
  );
};

BlogPostForm.propTypes = {
  blogPost: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    htmlContent: PropTypes.string,
  }),
  createOrUpdateBlogPost: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

BlogPostForm.defaultProps = {
  blogPost: {
    _id: null,
    title: '',
    keywords: [],
    htmlContent: '',
  },
  onCancel: null,
};

export default BlogPostForm;
