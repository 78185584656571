import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Label, Input, FormGroup } from 'reactstrap';

const GoogleSettings = ({ google, onChange }) => (
  <Row className="social-platform-settings google-settings">
    {google.calendars?.map((calendar) => (
      <Col key={calendar.id} md={6}>
        <FormGroup check inline>
          <Input
            type="checkbox"
            onChange={(event) => onChange(calendar.id, event.target.checked)}
            checked={calendar.isActive}
          />
          <Label check>{calendar.name}</Label>
        </FormGroup>
      </Col>
    ))}
  </Row>
);

GoogleSettings.propTypes = {
  google: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    picture: PropTypes.string,
    calendars: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        isActive: PropTypes.bool,
      })
    ),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default GoogleSettings;
