import AIBlade from 'components/AIBlade';
import Confirm from 'components/ConfirmModal';
import ContentCard from 'components/ContentCard';
import ContentInput from 'components/ContentInput';
import ContentModal from 'components/ContentModal';
import moment from 'moment';
import React from 'react';
import { useStore, useStoreValue } from 'react-context-hook';
import DatePicker from 'react-datepicker';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { useCollections } from 'services/Collections';
import { useContents } from 'services/Contents';
import { storeMap } from 'services/constants';

import './ContentEditor.css';

const CONTENT_LIMIT = 50;

const ContentEditor = () => {
  const Contents = useContents();
  const Collections = useCollections();
  const contentsById = useStoreValue(storeMap.CONTENTS_BY_ID, {});
  const collectionsById = useStoreValue(storeMap.COLLECTIONS_BY_ID, {});
  const [viewingContentId, setViewingContentId] = useStore(storeMap.VIEWING_CONTENT_ID);
  const [contentParams, setContentParams] = React.useState({});
  const [contentStart, setContentStart] = React.useState(0);
  const [contentCount, setContentCount] = React.useState();
  const [editingContent, setEditingContent] = React.useState();
  const [isSaving, setIsSaving] = React.useState(false);
  const [pages, setPages] = React.useState([]);
  const [showAIBlade, setShowAIBlade] = React.useState(false);
  const [selectedCollectionId, setSelectedCollectionId] = React.useState();
  const collections = Object.values(collectionsById);

  const uploadMedia = async (file) => {
    const accessUrl = await Contents.uploadMedia(file);

    if (!accessUrl) {
      window.flash('Error uploading media', 'danger');

      return null;
    }

    window.flash(`Media uploaded to: ${accessUrl}`, 'success', 15000);

    return accessUrl;
  };

  const saveContent = async (content) => {
    if (content.image) {
      const imageUrl = await uploadMedia(content.image);
      delete content.image;

      if (imageUrl) {
        content.imageUrl = imageUrl;
      }
    }

    if (content.video) {
      // const filename = content.video.name;
      const videoUrl = await uploadMedia(content.video);
      delete content.video;

      if (videoUrl) {
        content.videoUrl = videoUrl;

        // Compress video
        // Contents.compressVideo(filename, videoUrl);
      }
    } else if (content.videoUrl) {
      const filename = content.videoUrl.slice(content.videoUrl.lastIndexOf('/') + 1);
      if (!filename.startsWith('compressed_')) {
        // Contents.compressVideo(content._id, filename, content.videoUrl);
      }
    }

    if (!content.imageUrl && !content.videoUrl && !content.text) {
      window.flash('Cannot create empty content', 'danger');
      return;
    }

    if (content._id) {
      Contents.update(content);
    } else {
      Contents.create(content);
    }
  };

  const deleteContent = async (contentId) => {
    Contents.deleteContent(contentId);
  };

  const fetchContent = async (start) => {
    const { count } = await Contents.fetch({
      ...contentParams,
      start,
      limit: CONTENT_LIMIT,
      includeCount: true,
      createdAt: '',
      includeAll: true,
      collectionId: selectedCollectionId,
    });

    return count;
  };

  React.useEffect(() => {
    Collections.fetch(true);
  }, []);

  React.useEffect(() => {
    Contents.clearCache();
    fetchContent(contentStart).then((count) => {
      setContentCount(count);
    });
  }, [contentStart, contentParams, selectedCollectionId]);

  React.useEffect(() => {
    const newPages = [];
    for (let i = 0; i < contentCount; i += CONTENT_LIMIT) {
      newPages.push(i);
    }
    setPages(newPages);
  }, [contentCount]);

  return (
    <div className="content-editor">
      <h3 className="line-text">Content Editor</h3>

      <Button
        className="ai-blade-button"
        color="cta"
        onClick={() => {
          setShowAIBlade(true);
        }}
      >
        Storiful AI <i className="fa-solid fa-microchip" />
      </Button>

      {editingContent ? (
        <Row>
          <Col className="content-form-container">
            <ContentInput content={editingContent} setContent={setEditingContent} showAdvanced />

            <Button
              color="primary"
              disabled={isSaving}
              onClick={async () => {
                setIsSaving(true);
                await saveContent(editingContent);
                setIsSaving(false);
              }}
            >
              {isSaving ? (
                <i className="fa-solid fa-circle-o-notch fa-spin" />
              ) : (
                <span>{editingContent._id ? 'Save' : 'Create'}</span>
              )}
            </Button>

            {editingContent._id && (
              <Button
                color="none"
                disabled={isSaving}
                onClick={async () => {
                  const result = await Confirm({
                    message: 'Delete Content?',
                  });

                  if (result) {
                    setIsSaving(true);
                    await deleteContent(editingContent._id);
                    setEditingContent();
                    setIsSaving(false);
                  }
                }}
              >
                Delete
              </Button>
            )}

            <Button className="close-form" color="none" onClick={() => setEditingContent()}>
              <i className="fa-solid fa-times" />
            </Button>
          </Col>
          <Col>
            <i
              className="fa-solid fa-eye"
              data-tip="Preview full content"
              onClick={() => {
                setViewingContentId(editingContent._id);
              }}
            />
            <span className="content-id">ContentId: {editingContent._id}</span>
            <ContentCard {...editingContent} />
          </Col>
        </Row>
      ) : (
        <div className="filters">
          <Row>
            <Col>
              <h5>Filters</h5>

              <Button onClick={() => setContentParams({ types: '' })}>Clear</Button>

              <Button className="pull-right" color="success" onClick={() => setEditingContent({})}>
                Create Content
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup floating>
                <Input
                  type="select"
                  name="types"
                  id="typesField"
                  value={contentParams.types}
                  onChange={(e) => {
                    const types = e.target.value;
                    setContentParams({ ...contentParams, types });
                  }}
                >
                  <option value="">All</option>
                  <option value="['video']">Video</option>
                  <option value="['text','image']">Text/Image</option>
                </Input>
                <Label for="typesField">Type</Label>
              </FormGroup>
              <FormGroup floating>
                <Input
                  type="text"
                  name="search"
                  id="searchField"
                  value={contentParams.search || ''}
                  onChange={(e) => {
                    const search = e.target.value;
                    setContentParams({ ...contentParams, search });
                  }}
                />
                <Label for="searchField">Search</Label>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="type">Date</Label>
                <DatePicker
                  selected={moment.utc(contentParams.date).toDate()}
                  onChange={(date) =>
                    setContentParams({ ...contentParams, date: date?.toUTCString() })
                  }
                />
              </FormGroup>
              {collections.length > 1 && (
                <FormGroup floating>
                  <Input
                    className="content-container__collection-select"
                    type="select"
                    name="collection"
                    onChange={(e) => {
                      setSelectedCollectionId(e.target.value);
                    }}
                    defaultValue={selectedCollectionId}
                  >
                    <option value="">All collections</option>
                    {collections.map((collection) => (
                      <option key={collection._id} value={collection._id}>
                        {collection.name}
                      </option>
                    ))}
                  </Input>

                  <Label for="type">Collection</Label>
                </FormGroup>
              )}
            </Col>
          </Row>
        </div>
      )}

      <hr />

      <div className="content-pagination">
        {pages.map((start, i) => (
          <div
            key={start}
            className={start === contentStart ? 'active' : ''}
            onClick={() => {
              setContentStart(start);
            }}
          >
            {i + 1}
          </div>
        ))}
      </div>

      <Row>
        {Object.values(contentsById).map((content) => (
          <Col
            key={content._id}
            className="content-option"
            md={3}
            onClick={() => {
              window.scrollTo(0, 0);
              setEditingContent(content);
            }}
          >
            <ContentCard {...content} />
          </Col>
        ))}
      </Row>

      <Row>
        <Col>
          <Button color="none" onClick={() => fetchContent(contentsById)}>
            Load more
          </Button>
        </Col>
      </Row>

      {viewingContentId && <ContentModal />}

      {showAIBlade && <AIBlade onClose={() => setShowAIBlade(false)} />}
    </div>
  );
};

export default ContentEditor;
