import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, FormGroup, Input } from 'reactstrap';
import TextInput from 'components/TextInput';
import ReactQuill from 'react-quill';
import { CollectionStatus } from 'services/constants';

const QUILL_MODULES = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image'],
    ['clean'],
  ],
};

const QUILL_FORMATS = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

const CollectionForm = ({
  collection: inputCollection = {},
  onCancel,
  onSave,
  allowAdminUpdate,
}) => {
  const [collection, setCollection] = React.useState(inputCollection);

  const onChange = (e) => {
    setCollection({ ...collection, [e.target.name]: e.target.value });
  };

  const {
    _id,
    userId,
    name,
    description,
    status,
    price,
    exampleContentIds = [],
    remainingPurchaseCount,
  } = collection;

  return (
    <div>
      <h3 className="line-text">{_id ? 'Edit' : 'Create'} Collection</h3>
      <Row>
        <Col>
          <TextInput name="name" displayText="Name" value={name} onChange={onChange} required />

          <FormGroup>
            <ReactQuill
              value={description || ''}
              onChange={(value) => {
                setCollection({ ...collection, description: value });
              }}
              modules={QUILL_MODULES}
              formats={QUILL_FORMATS}
            />
          </FormGroup>

          {allowAdminUpdate && (
            <>
              <Input
                type="select"
                name="STATUS"
                value={status}
                onChange={(event) => {
                  setCollection({ ...collection, status: event.target.value });
                }}
              >
                <option value={CollectionStatus.PRIVATE}>Private</option>
                <option value={CollectionStatus.PUBLIC}>Public</option>
                <option value={CollectionStatus.DRAFT}>Draft</option>
                <option value={CollectionStatus.ARCHIVED}>Archived</option>
              </Input>

              <TextInput name="price" displayText="Price" value={price} onChange={onChange} />
              <TextInput
                name="exampleContentIds"
                displayText="Example Content Ids"
                value={exampleContentIds.join(',')}
                helperText="Comma separated list: Id, Id, etc"
                onChange={(event) => {
                  const contentIds = event.target.value.split(',').map((k) => k.trim());
                  setCollection({ ...collection, exampleContentIds: contentIds });
                }}
              />

              <TextInput
                type="number"
                name="remainingPurchaseCount"
                displayText="Remaining Purchase Count"
                value={remainingPurchaseCount}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);
                  setCollection({ ...collection, remainingPurchaseCount: value });
                }}
              />
              <TextInput name="userId" displayText="User ID" value={userId} onChange={onChange} />
            </>
          )}
        </Col>
      </Row>

      <div className="button-container">
        <Button
          size="sm"
          color="primary"
          className="success"
          onClick={() => {
            onSave(collection);
          }}
        >
          Save
        </Button>

        {onCancel && (
          <Button size="sm" color="default" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </div>
    </div>
  );
};

CollectionForm.propTypes = {
  collection: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    price: PropTypes.string,
    exampleContentIds: PropTypes.arrayOf(PropTypes.string),
    remainingPurchaseCount: PropTypes.number,
  }),
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
  onSave: PropTypes.func,
  allowAdminUpdate: PropTypes.bool,
};

CollectionForm.defaultProps = {
  collection: {},
  onCancel: null,
  onCreate: () => {},
  onSave: () => {},
  allowAdminUpdate: false,
};

export default CollectionForm;
