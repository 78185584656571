import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import ContentExportPDF from './ContentExportPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';

import './ContentExportModal.css';

const ContentExportModal = ({
  startDate,
  endDate,
  onClose,
  className,
  size,
  user,
  token,
  jobsById,
}) => {
  const [exportStartDate, setExportStartDate] = React.useState(startDate);
  const [exportEndDate, setExportEndDate] = React.useState(endDate);
  const [isExporting, setIsExporting] = React.useState(false);
  const [socialPostsByDate, setSocialPostsByDate] = React.useState([]);

  const exportContent = async () => {
    // Disable button and show spinner
    setIsExporting(true);

    // Fire request to API to get all social posts in range
    let response;
    try {
      response = await axios.get('/api/social-posts', {
        params: {
          startDttm: exportStartDate,
          endDttm: exportEndDate,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      window.flash('Error exporting Social Posts', 'danger');
      onClose();
      return;
    }

    const newSocialPostsByDate = {};

    (response.data || []).forEach((socialPost) => {
      if (!socialPost.createdAt) {
        return;
      }

      const socialPostDate = moment(socialPost.date).utc().format().split('T')[0];

      if (!newSocialPostsByDate[socialPostDate]) {
        newSocialPostsByDate[socialPostDate] = [];
      }

      newSocialPostsByDate[socialPostDate].push(socialPost);
    });

    setSocialPostsByDate(newSocialPostsByDate);
    setIsExporting(false);
  };

  return (
    <Modal
      size={size}
      isOpen
      toggle={() => onClose()}
      className={`content-export-modal ${className}`}
    >
      <ModalHeader toggle={() => onClose()}>Archive Report</ModalHeader>
      <ModalBody>
        {Object.keys(socialPostsByDate).length ? (
          <PDFDownloadLink
            document={
              <ContentExportPDF
                socialPostsByDate={socialPostsByDate}
                jobsById={jobsById}
                user={user}
                startDate={exportStartDate}
                endDate={exportEndDate}
              />
            }
            fileName="export.pdf"
          >
            {({ loading }) => (loading ? 'Loading report...' : 'Click here to download!')}
          </PDFDownloadLink>
        ) : (
          <>
            <div className="date-row">
              <span>Start Date:</span>
              <DatePicker
                selected={exportStartDate}
                onChange={(date) => setExportStartDate(date)}
              />
            </div>

            <div className="date-row">
              <span>End Date:</span>
              <DatePicker selected={exportEndDate} onChange={(date) => setExportEndDate(date)} />
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        {isExporting ? (
          <i className="fa-solid fa-circle-o-notch fa-spin" />
        ) : (
          <>
            <Button color="" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button color="primary" onClick={exportContent}>
              Export
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

ContentExportModal.propTypes = {
  startDate: PropTypes.shape(),
  endDate: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
  user: PropTypes.shape({}).isRequired,
  jobsById: PropTypes.shape({}).isRequired,
  token: PropTypes.string.isRequired,
};

ContentExportModal.defaultProps = {
  startDate: moment().subtract(30, 'days').toDate(),
  endDate: moment().toDate(),
  className: '',
  size: null,
};

export default ContentExportModal;
