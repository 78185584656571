import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import { useStoreValue } from 'react-context-hook';
import { ContentType, storeMap } from 'services/constants';
import { useContents } from 'services/Contents';
import { useCalendarEvents } from 'services/CalendarEvents';
import CalendarEventCard from 'components/CalendarEventCard';

import './PromptContainer.css';
import ContentCard from 'components/ContentCard';

const PROMPT_LIMIT = 2;

const PromptContainer = ({ onPromptSelect, onCalendarEventSelect }) => {
  const Contents = useContents();
  const CalendarEvents = useCalendarEvents();
  const user = useStoreValue(storeMap.USER);
  const selectedDate = useStoreValue(storeMap.SELECTED_DATE);
  const selectedContentId = useStoreValue(storeMap.SELECTED_CONTENT_ID);
  const selectedCalendarEvent = useStoreValue(storeMap.SELECTED_CALENDAR_EVENT);
  const contentsById = useStoreValue(storeMap.CONTENTS_BY_ID, {});

  const [calendarEvents, setCalendarEvents] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [displayStartIndex, setDisplayStartIndex] = React.useState(0);

  React.useEffect(() => {
    if (!user.google) {
      return;
    }

    setIsLoading(true);

    CalendarEvents.fetch({
      startDttm: moment(selectedDate).startOf('day').toISOString(),
      endDttm: moment(selectedDate).endOf('day').toISOString(),
    }).then((events) => {
      setIsLoading(false);
      setCalendarEvents(events);
      setDisplayStartIndex(0);
    });
  }, [selectedDate]);

  React.useEffect(() => {
    if (!selectedDate) {
      return;
    }

    const fetchContent = async () => {
      setIsLoading(true);

      Contents.clearCache(ContentType.PROMPT);
      await Contents.fetch({
        date: selectedDate,
        limit: PROMPT_LIMIT,
        type: ContentType.PROMPT,
        isRandom: true,
      });

      setIsLoading(false);
    };

    fetchContent();
  }, [selectedDate]);

  const contentPrompts = React.useMemo(() => {
    const copiedContentsById = {};
    return Object.values(contentsById)
      .filter((content) => content.type === ContentType.PROMPT)
      .sort((a, b) => {
        if (a.copiedFrom) {
          copiedContentsById[a.copiedFrom] = 1;
        }
        if (b.copiedFrom) {
          copiedContentsById[b.copiedFrom] = 1;
        }

        if (
          a.collectionIds?.includes(user.privateCollectionId) &&
          !b.collectionIds?.includes(user.privateCollectionId)
        ) {
          return -1;
        }
        if (
          !a.collectionIds?.includes(user.privateCollectionId) &&
          b.collectionIds?.includes(user.privateCollectionId)
        ) {
          return 1;
        }

        if (a.year && !b.year) {
          return -1;
        }
        if (!a.year && b.year) {
          return 1;
        }

        if (a.collectionIds?.length && !b.collectionIds?.length) {
          return -1;
        }
        if (!a.collectionIds?.length && b.collectionIds?.length) {
          return 1;
        }

        return 0;
      })
      .filter((content) => !copiedContentsById[content._id]);
  }, [contentsById]);

  if (isLoading) {
    return <i className="fa-solid fa-circle-o-notch fa-spin loading-icon" />;
  }

  const prompts = [...contentPrompts, ...calendarEvents];

  return (
    <Row className="prompt-container">
      {displayStartIndex > 0 && (
        <span
          className="prompt-container__previous-arrow"
          onClick={() => {
            setDisplayStartIndex(Math.max(displayStartIndex - 4, 0));
          }}
        >
          <i className="fa-solid fa-chevron-left" />
        </span>
      )}

      {prompts.slice(displayStartIndex, displayStartIndex + 4).map((prompt) => {
        if (prompt.type === ContentType.PROMPT) {
          return (
            <Col
              key={prompt._id}
              className={`prompt-option ${selectedContentId === prompt.id ? 'selected' : ''}`}
              md={3}
              onClick={() => onPromptSelect(prompt._id)}
            >
              <ContentCard {...prompt} />
            </Col>
          );
        }

        return (
          <Col
            key={prompt.id}
            className={`prompt-option ${
              selectedCalendarEvent?.id === prompt?.id ? 'selected' : ''
            }`}
            md={3}
            onClick={() => onCalendarEventSelect(prompt)}
          >
            <CalendarEventCard {...prompt} />
          </Col>
        );
      })}

      {prompts.length > displayStartIndex + 4 && (
        <span
          className="prompt-container__next-arrow"
          onClick={() => {
            setDisplayStartIndex(displayStartIndex + 4);
          }}
        >
          <i className="fa-solid fa-chevron-right" />
        </span>
      )}
    </Row>
  );
};

PromptContainer.propTypes = {
  onPromptSelect: PropTypes.func.isRequired,
  onCalendarEventSelect: PropTypes.func.isRequired,
};

export default PromptContainer;
