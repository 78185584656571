import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import withSmoothLoad from 'components/withSmoothLoad';

import './AboutUs.css';

const AboutUs = ({ toggleSignupModal }) => (
  <Row className="content-section">
    <Col>
      <h2>About Us</h2>

      <p>
        Our story began building, selling, and supporting a FinTech startup. It was there, through
        conversations with thousands of users, that we observed a universal pain point: producing
        high quality content for social media is time consuming and expensive.
      </p>
      <p>
        On average, people spend nearly 3 hours a day on social media. Keeping up with your audience
        with a strong social media presence is key to growing your business and expanding your brand
        awareness. Whether you&apos;re a small business owner, and you&apos;re the entire marketing
        team, or you&apos;re a social media manager working with a tight budget you know that the
        single biggest barrier to online engagement is your ability to post consistent and timely
        content. It&apos;s simple, but also critical; if your online profile flatlines your digital
        lead generation dies with it.
      </p>

      <p>
        Our solution? Create ready-made content for you to post. Each post aims to join the
        conversation that is already happening online. We hope you&apos;ll{' '}
        <Button color="none" onClick={toggleSignupModal}>
          Join Us
        </Button>
        !
      </p>

      <div className="founders">
        <div id="cameron-text">
          <h2>Cameron Pope</h2>
          <p>
            <i>CEO</i>
          </p>
          <p>
            Cameron is a visionary. He knew how to fly a plane before he could drive a car. An early
            employee at Riskalyze, he consulted with thousands of advisors and learned of the many
            challenges they face. Cameron has consulted startups on growth hacking, run political
            campaigns, and traveled the world. He brings big ideas and a wealth of practical
            experience to every project.
          </p>
          <p>
            A graduate of Columbia University, he loves the high energy of startup life, camping,
            and playing board games. Cameron and his wife Katrina have three kids and reside in
            Alexandria, VA.
          </p>
          <p>
            <Button
              className="btn btn-social btn-linkedin"
              size="sm"
              onClick={() => {
                window.open('https://www.linkedin.com/in/campaignpope/');
              }}
            >
              <i className="fa-brands fa-linkedin" /> LinkedIn
            </Button>
          </p>
        </div>
        <img id="cameron-image" alt="Cameron Pope" src="/img/cameron_pope_family.jpg" />

        <div id="nick-text">
          <h2>Nick Barnes</h2>
          <p>
            <i>CTO</i>
          </p>
          <p>
            Nick is an architect. He&apos;s been building things online since he was a kid and
            he&apos;s damn good at it. An early employee at Riskalyze, he made big contributions to
            their award-winning risk analysis platform and cut his teeth building tools for
            advisors. Nick is a full-stack developer and is just as comfortable configuring
            databases as he is writing CSS. He brings a wealth of technical expertise to every
            build.
          </p>
          <p>
            Nick graduated from Kennesaw State University and received his Masters of Computer
            Science from The Georgia Institute of Technology. When not writing code, Nick can be
            found brewing beer or cheering on Atlanta United. Nick and his wife Brittany have two
            kids reside in Atlanta, GA.
          </p>
          <p>
            <Button
              className="btn btn-social btn-linkedin"
              size="sm"
              onClick={() => {
                window.open('https://www.linkedin.com/in/nick-barnes/');
              }}
            >
              <i className="fa-brands fa-linkedin" /> LinkedIn
            </Button>
          </p>
        </div>
        <img id="nick-image" alt="Nick Barnes" src="/img/nick_barnes_family.jpg" />
      </div>
    </Col>
  </Row>
);

AboutUs.propTypes = {
  toggleSignupModal: PropTypes.func.isRequired,
};

export default withSmoothLoad(AboutUs);
