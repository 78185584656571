import React from 'react';

const withSmoothLoad = (WrappedComponent) =>
  class SmoothLoad extends React.PureComponent {
    constructor(props) {
      super(props);

      this.state = { didMount: false };
    }

    componentDidMount() {
      this._isMounted = true;
      setTimeout(() => {
        if (this._isMounted) {
          this.setState({ didMount: true });
        }
      }, 0);
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    render() {
      const { didMount } = this.state;
      return (
        <div className={`fade-in ${didMount && 'visible'}`}>
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  };

export default withSmoothLoad;
