import React from 'react';
import withSmoothLoad from '../../components/withSmoothLoad';

import './ChangeLog.css';

const ChangeLog = () => (
  <div className="change-log">
    <h3 className="line-text">Change log</h3>

    <ul className="change-list">
      <li>Added Ehance with AI button to Social Form</li>
      <li>Added StorifulAI</li>
      <li>Added link unfurling to Post Form</li>
      <li>Added Google Calendar integration</li>
      <li>Added RSS Feed images and descriptions</li>
      <li>Enabled Facebook native posts (requires reconnect)</li>
      <li>Added User Referral Codes</li>
      <li>Added Custom RSS based News Feeds</li>
      <li>Added Collections</li>
      <li>Storiful Launch!</li>
    </ul>
  </div>
);

export default withSmoothLoad(ChangeLog);
