import React from 'react';
import PropTypes from 'prop-types';
import Autolinker from 'autolinker';
import { useSetStoreValue, useStore, useStoreValue } from 'react-context-hook';
import { storeMap, SocialPostStatus } from 'services/constants';
import { useSocialPosts } from 'services/SocialPosts';
import { useContents } from 'services/Contents';
import { Modal, ModalBody, Button } from 'reactstrap';
import SocialPostCard from 'components/SocialPostCard';
import SocialPostInput from 'components/SocialPostInput';
import Confirm from 'components/ConfirmModal';

import './SocialPostModal.css';
import ReactTooltip from 'react-tooltip';

const SocialPostModal = ({ socialPostId, onClose }) => {
  const Contents = useContents();
  const SocialPosts = useSocialPosts();
  const user = useStoreValue(storeMap.USER);
  const [socialPostsById, setSocialPostsById] = useStore(storeMap.SOCIAL_POSTS_BY_ID, {});
  const setViewingContentId = useSetStoreValue(storeMap.VIEWING_CONTENT_ID);
  const [isEditing, setIsEditing] = React.useState(false);

  const socialPost = socialPostsById[socialPostId] || {};
  const setSocialPost = (updatedSocialPost) => {
    socialPostsById[updatedSocialPost._id] = updatedSocialPost;
    setSocialPostsById({ ...socialPostsById });
  };

  // Copy social post to new Content
  const convertToContent = async () => {
    const contentCopy = await Contents.create({
      collectionIds: [user.privateCollectionId],
      text: socialPost.data?.text,
      imageUrl: socialPost.data?.imageUrl,
      videoUrl: socialPost.data?.videoUrl,
    });

    if (contentCopy) {
      onClose();
      setViewingContentId(contentCopy._id);
    }
  };

  // Unfurl links
  React.useEffect(() => {
    const links = Autolinker.parse(socialPost.data?.text || '');
    const link = links.find((iLink) => iLink.url)?.url;
    if (link) {
      SocialPosts.unfurl(socialPost._id, link);
    }
  }, [socialPost]);

  const isPosted = socialPost.status === SocialPostStatus.POSTED;

  return (
    <Modal className="social-post-modal" isOpen toggle={() => onClose()}>
      <ModalBody id="social-post-modal-body">
        <ReactTooltip effect="solid" anchorId="social-post-modal-body" />
        <i className="fa-solid fa-times" onClick={() => onClose()} />

        {isEditing && (
          <SocialPostInput
            socialPost={socialPost}
            setSocialPost={setSocialPost}
            onSave={() => onClose()}
          />
        )}
        {!isEditing && <SocialPostCard {...socialPost} showUnfurl />}

        <div className="social-post-modal__buttons">
          <Button data-tip="Save as Content" color="none" onClick={convertToContent}>
            Save
          </Button>
          {!isPosted && (
            <Button
              color="none"
              size="sm"
              onClick={() => {
                setIsEditing(!isEditing);
              }}
            >
              {isEditing ? 'Cancel' : 'Edit'}
            </Button>
          )}
          {socialPost.createdAt && (
            <i
              className="fa-solid fa-trash"
              data-tip="Delete Social Post"
              onClick={async () => {
                if (
                  await Confirm({
                    message: `Delete Social Post?`,
                  })
                ) {
                  onClose();
                  SocialPosts.deleteSocialPost(socialPost);
                }
              }}
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

SocialPostModal.propTypes = {
  socialPostId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SocialPostModal;
