import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { Row, Col, Input } from 'reactstrap';
import { storeMap } from 'services/constants';
import { useStoreValue } from 'react-context-hook';
import useRSSFeeds from 'services/RSSFeeds';
import ContentCard from 'components/ContentCard';

import './RSSContentsContainer.css';

const RSSContentsContainer = ({ onRSSContentSelect }) => {
  const RSSFeeds = useRSSFeeds();
  const user = useStoreValue(storeMap.USER);
  const selectedDate = useStoreValue(storeMap.SELECTED_DATE);
  const RSSContentsById = useStoreValue(storeMap.RSS_CONTENTS_BY_ID, {});
  const selectedContentId = useStoreValue(storeMap.selectedContentId);

  const [isLoading, setIsLoading] = React.useState(false);
  const [displayStartIndex, setDisplayStartIndex] = React.useState(0);
  const [selectedRSSFeed, setSelectedRSSFeed] = React.useState();

  const RSSContents = React.useMemo(
    () =>
      Object.values(RSSContentsById).sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return 1;
        }
        if (a.createdAt > b.createdAt) {
          return -1;
        }

        return 0;
      }),
    [RSSContentsById]
  );

  React.useEffect(() => {
    setIsLoading(true);
    RSSFeeds.fetch({
      startDttm: moment(selectedDate).subtract(7, 'days').toISOString(),
      endDttm: moment(selectedDate).endOf('day').toISOString(),
      feedName: selectedRSSFeed,
    }).then(() => {
      setDisplayStartIndex(0);
      setIsLoading(false);
    });
  }, [selectedDate, selectedRSSFeed]);

  return (
    <>
      <Row>
        <Col className="rss-feeds-buttons">
          {user.RSSFeeds.length > 1 && (
            <Input
              className="rss-feed-select"
              type="select"
              name="rss-feed"
              onChange={(e) => {
                setSelectedRSSFeed(e.target.value);
              }}
              defaultValue={selectedRSSFeed}
            >
              <option value="">All feeds</option>
              {user.RSSFeeds.map((feed) => (
                <option key={feed.name} value={feed.name}>
                  {feed.name}
                </option>
              ))}
            </Input>
          )}
          <span className="subtle-text">
            Add new feeds in <NavLink to="/app/settings/news">Settings</NavLink>
          </span>
        </Col>
      </Row>
      <Row className="rss-contents-container">
        {isLoading ? (
          <Col>
            <i className="fa-solid fa-circle-o-notch fa-spin loading-icon" />
          </Col>
        ) : (
          <>
            {RSSContents.length ? (
              <>
                {displayStartIndex > 0 && (
                  <span
                    className="rss-contents-container__previous-arrow"
                    onClick={() => {
                      setDisplayStartIndex(Math.max(displayStartIndex - 4, 0));
                    }}
                  >
                    <i className="fa-solid fa-chevron-left" />
                  </span>
                )}

                {RSSContents.slice(displayStartIndex, displayStartIndex + 4).map((rssContent) => (
                  <Col
                    key={rssContent.id}
                    className={`rss-contents-option ${
                      selectedContentId === rssContent?.id ? 'selected' : ''
                    }`}
                    md={3}
                    onClick={() => onRSSContentSelect(rssContent.id)}
                  >
                    <ContentCard {...rssContent} />
                  </Col>
                ))}

                {RSSContents.length > displayStartIndex + 4 && (
                  <span
                    className="rss-contents-container__next-arrow"
                    onClick={() => {
                      setDisplayStartIndex(displayStartIndex + 4);
                    }}
                  >
                    <i className="fa-solid fa-chevron-right" />
                  </span>
                )}
              </>
            ) : (
              <Col className="no-news-results">No News results</Col>
            )}
          </>
        )}
      </Row>
    </>
  );
};

RSSContentsContainer.propTypes = {
  onRSSContentSelect: PropTypes.func,
};

RSSContentsContainer.defaultProps = {
  onRSSContentSelect: null,
};

export default RSSContentsContainer;
