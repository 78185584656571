import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const Job = ({
  _id,
  userId,
  contentId,
  status,
  url,
  screenshotUrl,
  createdAt,
  updatedAt,
  completedAt,
}) => (
  <div className="job">
    <div>
      <b>Id:</b> {_id}
    </div>
    <div>
      <b>UserId:</b> {userId}
    </div>
    <div>
      <b>ContentId:</b> {contentId}
    </div>

    <div>
      <b>Status:</b> {status}
    </div>

    {url && (
      <div>
        <b>Video Url:</b> <a href={url}>Video</a>
      </div>
    )}

    {screenshotUrl && (
      <div>
        <b>Screenshot Url:</b> <a href={screenshotUrl}>Screenshot</a>
      </div>
    )}

    <div>
      <b>CreatedAt:</b> {moment.utc(createdAt).format('ddd DD-MMM-YYYY, h:mm A')}
    </div>
    <div>
      <b>UpdatedAt:</b> {moment.utc(updatedAt).format('ddd DD-MMM-YYYY, h:mm A')}
    </div>
    {completedAt && (
      <div>
        <b>CompletedAt:</b> {moment.utc(completedAt).format('ddd DD-MMM-YYYY, h:mm A')}
      </div>
    )}
  </div>
);

Job.propTypes = {
  _id: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  contentId: PropTypes.string,
  url: PropTypes.string,
  screenshotUrl: PropTypes.string,
  completedAt: PropTypes.string,
};

Job.defaultProps = {
  completedAt: null,
  url: null,
  screenshotUrl: null,
  contentId: null,
};

export default Job;
