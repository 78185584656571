import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import TextInput from 'components/TextInput';
import { useCollections } from 'services/Collections';
import CollectionForm from '../../../components/CollectionForm';
import Confirm from 'components/ConfirmModal';

import './CollectionEditor.css';
import 'react-quill/dist/quill.snow.css';

const CollectionEditor = () => {
  const Collections = useCollections();
  const [collections, setCollections] = React.useState([]);
  const [editingCollectionId, setEditingCollectionId] = React.useState();
  const [filter, setFilter] = React.useState();

  const onFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const editCollection = (e) => {
    setEditingCollectionId(e.target.dataset.id);
  };

  const cancelEdit = () => {
    setEditingCollectionId(null);
  };

  const fetchCollections = async () => {
    const fetchAll = true;
    // Fetch user data
    const fetchedCollections = await Collections.fetch(fetchAll);

    setCollections(fetchedCollections);
  };

  const filteredCollections = collections.filter(
    (collection) =>
      !filter ||
      collection.name.includes(filter) ||
      collection.description?.includes(filter) ||
      collection.userId?.includes(filter)
  );

  const onSave = async (collection) => {
    if (collection._id) {
      await Collections.update(collection);
    } else {
      await Collections.create(collection);
    }

    fetchCollections();
    cancelEdit();
  };

  React.useEffect(() => {
    fetchCollections();
  }, []);

  return (
    <div className="collection-editor">
      <h2>Collection Editor</h2>

      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <TextInput name="filter" displayText="Filter" value={filter} onChange={onFilterChange} />
        </Col>
      </Row>

      <Row>
        <Col>
          {editingCollectionId === 'new' ? (
            <CollectionForm onSave={onSave} onCancel={cancelEdit} allowAdminUpdate />
          ) : (
            <Button
              size="sm"
              color="success"
              onClick={() => {
                setEditingCollectionId('new');
              }}
            >
              New Collection
            </Button>
          )}
        </Col>
      </Row>

      <hr />

      <Row>
        {filteredCollections.map((collection) => {
          const isEditing = collection._id === editingCollectionId;

          if (isEditing) {
            return (
              <Col key={collection._id} md={4}>
                <CollectionForm
                  collection={collection}
                  onSave={onSave}
                  onCancel={cancelEdit}
                  allowAdminUpdate
                />
              </Col>
            );
          }

          return (
            <Col key={collection._id} md={6} className="collection">
              <div>
                <b>Id:</b> {collection._id}
                <b> User ID:</b> {collection.userId}
              </div>
              <div className="collection-card">
                <h4>{collection.name}</h4>

                <div
                  className="collection-preview"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: collection.description }}
                />
              </div>

              <div>
                {collection.price && (
                  <>
                    <b>Price:</b> ${collection.price}
                  </>
                )}

                {collection.remainingPurchaseCount && (
                  <>
                    <b>Reamaining Purchase Count:</b> {collection.remainingPurchaseCount}
                  </>
                )}
              </div>

              {!!collection.exampleContentIds?.length && (
                <div>
                  <b>Example ContentIds:</b> {collection.exampleContentIds.join(', ')}
                </div>
              )}
              <div />
              <div className="button-container">
                <Button size="sm" color="default" onClick={editCollection} data-id={collection._id}>
                  Edit
                </Button>

                <Button
                  size="sm"
                  color="default"
                  onClick={async () => {
                    const result = await Confirm({
                      message: `Delete collection?`,
                    });

                    if (result) {
                      await Collections.deleteCollection(collection._id);
                      fetchCollections();
                    }
                  }}
                  data-id={collection._id}
                >
                  Delete
                </Button>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default CollectionEditor;
