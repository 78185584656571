import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { StripePlanType, StripePlanTitle, UserStatus } from 'services/constants';
import {
  Row,
  Col,
  Button,
  FormGroup,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import TextInput from 'components/TextInput';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { EMAIL_REGEX } from 'util/constants';
import { useUsers } from 'services/Users';
import './SignupModal.css';
import ReCAPTCHA from 'react-google-recaptcha';

const recaptchaRef = React.createRef();

const SignupModal = ({ show, planType, toggle, onLogin }) => {
  const [searchParams] = useSearchParams();
  const stripe = useStripe();
  const elements = useElements();
  const Users = useUsers();
  const [user, setUser] = React.useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    firmName: '',
    firmWebsiteUrl: '',
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [stripeError, setStripeError] = React.useState();
  const [emailTouched, setEmailTouched] = React.useState(false);
  const [emailError, setEmailError] = React.useState();
  const [referralCode, setReferralCode] = React.useState(searchParams.get('referral_code'));

  const onChange = (event) => {
    if (event.target.name === 'email') {
      setEmailTouched(true);
    }
    setUser({ ...user, [event.target.name]: event.target.value });
    setEmailError();
  };

  const onStripeElementChange = (event) => {
    const { error } = event;

    setStripeError(error);
  };

  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSignupClick = async () => {
    const { email, password, firstName, lastName, firmName, firmLogoUrl, firmWebsiteUrl } = user;

    setIsLoading(true);

    // Verify email is not in use
    if (await Users.userExists(email)) {
      setEmailError('Email already in use');
      setIsLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    // Get Stripe token
    let token;

    // TODO: Handle invalid stripe card

    try {
      const result = await stripe.createToken(cardElement, { name: email });
      token = result.token;
    } catch (error) {
      setStripeError({ message: 'Invalid payment information' });
      setIsLoading(false);
      return;
    }

    if (!token) {
      setStripeError({ message: 'Invalid payment information' });
      setIsLoading(false);
      return;
    }

    // Create Stripe subscription
    let stripeResult;
    try {
      stripeResult = await axios.post('/api/stripe/subscribe', {
        email,
        token: token.id,
        planType,
      });
    } catch (error) {
      window.flash('Invalid payment information', 'danger');
      setStripeError({ message: 'Invalid payment information' });
      setIsLoading(false);
      return;
    }

    const {
      data: { subscriptionId, customerId, zip, brand, exp, last4 },
    } = stripeResult;

    // Create User
    let newUser = await Users.create({
      email,
      password,
      firstName,
      lastName,
      firmName,
      firmLogoUrl,
      firmWebsiteUrl,
      stripe: {
        customerId,
        subscriptionId,
        planType,
        zip,
        brand,
        exp,
        last4,
      },
      status: UserStatus.ACTIVE,
      referralCode,
    });

    if (!newUser) {
      window.flash('Error creating user', 'danger');
      setIsLoading(false);
      return;
    }

    newUser = await Users.login({ email, password });

    // Handle invalid login
    if (!newUser) {
      window.flash('Error creating user', 'danger');
      setIsLoading(false);
      return;
    }

    if (newUser) {
      window.flash('Signup successful!', 'success');
      onLogin(newUser, '/app/settings/social');
    }
  };

  const { email, password, firstName, lastName, firmName, firmWebsiteUrl } = user;

  const emailInvalid = emailTouched && (!email || !EMAIL_REGEX.test(email));
  const passwordInvalid = !password;
  const stripeInvalid = !!stripeError;

  return (
    <Modal isOpen={show} toggle={toggle} className="signup-modal">
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LdOX0UkAAAAAAl8IEYZKyZ_QNElz10FBvaEJkTW"
      />
      <ModalHeader toggle={toggle}>Sign up</ModalHeader>
      <ModalBody>
        <FormGroup className="plan-select">
          <h4>{StripePlanTitle[planType]}</h4>
        </FormGroup>
        <Row className="form-group">
          <Col>
            <h3 className="line-text">Required Fields</h3>

            <TextInput
              type="email"
              name="email"
              value={email}
              onChange={onChange}
              invalid={emailInvalid}
              feedback={emailError}
              required
            />

            <div className="password-wrapper">
              <TextInput
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={password}
                onChange={onChange}
                invalid={passwordInvalid}
                required
              />
              <i
                className={`fas fa-eye ${showPassword ? 'color-blue' : ''}`}
                onClick={onShowPassword}
              />
            </div>

            <FormGroup className="stripe-input">
              <CardElement onChange={onStripeElementChange} className="form-control" />
              {stripeError && <FormFeedback>{stripeError.message}</FormFeedback>}
            </FormGroup>

            <h3 className="line-text">Optional Fields</h3>

            <Row>
              <Col md={6}>
                <TextInput
                  name="firstName"
                  displayText="First Name"
                  value={firstName}
                  onChange={onChange}
                />
              </Col>
              <Col md={6}>
                <TextInput
                  name="lastName"
                  displayText="Last Name"
                  value={lastName}
                  onChange={onChange}
                />
              </Col>
            </Row>

            <TextInput
              name="firmName"
              displayText="Company Name"
              value={firmName}
              onChange={onChange}
            />

            <TextInput
              name="firmWebsiteUrl"
              value={firmWebsiteUrl}
              displayText="Company Website"
              onChange={onChange}
              helperText="Or Social Handle"
            />

            <TextInput
              name="referralCode"
              value={referralCode}
              displayText="Referral Code"
              onChange={(event) => {
                setReferralCode(event.target.value);
              }}
            />
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <Button
          color="primary"
          onClick={onSignupClick}
          disabled={isLoading || emailInvalid || passwordInvalid || stripeInvalid}
        >
          {isLoading ? <i className="fa-solid fa-circle-o-notch fa-spin" /> : 'Sign up'}
        </Button>{' '}
        <Button color="default" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

SignupModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  planType: PropTypes.string,
};

SignupModal.defaultProps = {
  planType: StripePlanType.MEMBERSHIP,
};

export default SignupModal;
