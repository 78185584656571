import { CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { useStore, useStoreValue } from 'react-context-hook';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { useUsers } from 'services/Users';
import { StripePlanTitle, StripePlanType, UserStatus, storeMap } from 'services/constants';

const SubscriptionSettings = ({ stripe, elements, stripeInvalid }) => {
  const token = useStoreValue(storeMap.TOKEN);
  const [user, setUser] = useStore(storeMap.USER);
  const [selectedPlanType, setSelectedPlanType] = React.useState(
    user.stripe?.planType || StripePlanType.MEMBERSHIP
  );
  const [isSaving, setIsSaving] = React.useState(false);
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [cancelationReason, setCancelationReason] = React.useState('');
  const [cancelationImprove, setCancelationImprove] = React.useState('');
  const [referralCode, setReferralCode] = React.useState();
  const Users = useUsers();

  const onUpdate = (updatedUser) => {
    setUser({ ...updatedUser });
  };

  const isSubscribed = user.status === UserStatus.ACTIVE;

  const subscribe = async () => {
    setIsSaving(true);

    // Get Stripe token
    const cardElement = elements.getElement(CardElement);
    const stripeResponse = await stripe.createToken(cardElement, { name: user.email });
    const { token: { id } = {} } = stripeResponse;
    if (!id) {
      window.flash('Please enter a valid credit card', 'danger');
      setIsSaving(false);
      return;
    }

    // Call API to update user
    let response;
    try {
      response = await axios.post(
        '/api/stripe/subscribe',
        { token: id, email: user.email, planType: selectedPlanType, referralCode },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      window.flash('Error subscribing', 'danger');
      setIsSaving(false);
      return;
    }

    const {
      data: { subscriptionId, customerId, zip, brand, exp, last4 },
    } = response;

    const updatedUser = await Users.update({
      _id: user._id,
      stripe: {
        customerId,
        subscriptionId,
        planType: selectedPlanType,
        zip,
        brand,
        exp,
        last4,
      },
      status: UserStatus.ACTIVE,
    });

    window.flash('Subscription successful', 'success');
    onUpdate(updatedUser);
    setIsSaving(false);
  };

  const updateSubscription = async () => {
    setIsSaving(true);
    let response;
    // Call API to update user
    try {
      response = await axios.post(
        `/api/stripe/update-subscription`,
        { planType: selectedPlanType },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      window.flash('Error updating subsription', 'danger');
      setIsSaving(false);
      return;
    }

    const {
      data: { planType, subscriptionId, customerId },
    } = response;

    onUpdate({
      ...user,
      stripe: {
        customerId,
        subscriptionId,
        planType,
      },
    });

    window.flash('Subscription updated');
    setIsSaving(false);
  };

  const cancelSubscription = async () => {
    let response;
    // Call API to cancel subscription
    try {
      response = await axios.post(
        `/api/stripe/cancel-subscription`,
        { cancelationReason, cancelationImprove },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      window.flash('Error canceling subscription', 'danger');
      setShowCancelModal(false);
      return;
    }

    const {
      data: { status },
    } = response;

    onUpdate({
      ...user,
      status,
      stripe: null,
    });

    window.flash('Subscription canceled');
    setShowCancelModal(false);
  };

  const isChangingPlan = selectedPlanType !== user.stripe?.planType;

  return (
    <Row className="subscription-settings">
      <Col>
        <Row className="stripe-plans">
          <Col
            className="pricing-card active"
            md={{ size: 4, offset: 2 }}
            onClick={() => setSelectedPlanType(StripePlanType.MEMBERSHIP)}
          >
            <h2>
              {/* {selectedPlanType === StripePlanType.MEMBERSHIP && (
                <i className="fa-solid fa-check selected" />
              )} */}
              {user._id === '63e6a9c0e112ad201b554e95'
                ? StripePlanTitle[StripePlanType.ENTERPRISE]
                : StripePlanTitle[StripePlanType.MEMBERSHIP]}
            </h2>
            <span>
              Storiful is a membership based platform. Members pay an annual dues of $99. Membership
              includes:
            </span>

            <div className="content-section">
              <div>
                <i className="fa-solid fa-check" /> Social media scheduling on Facebook, Instagram,
                LinkedIn, and Twitter.
              </div>
              <div>
                <i className="fa-solid fa-check" /> Social media archiving for regulated industries
              </div>
              <div>
                <i className="fa-solid fa-check" /> A ready-made content library.
              </div>
              <div>
                <i className="fa-solid fa-check" /> Your own content calendar to ensure you always
                have something to post.
              </div>
            </div>
          </Col>
        </Row>

        {!isSubscribed ? (
          <Col md={{ size: 4, offset: 4 }}>
            {/* <TextInput
              name="referralCode"
              value={referralCode}
              displayText="Referral Code"
              onChange={(event) => setReferralCode(event.target.value)}
            /> */}
            <Button color="cta" onClick={subscribe} disabled={stripeInvalid || isSaving}>
              {isSaving ? <i className="fa-solid fa-circle-o-notch fa-spin" /> : 'Subscribe'}
            </Button>
          </Col>
        ) : (
          <>
            {isChangingPlan && (
              <Button color="cta" onClick={updateSubscription} disabled={isSaving}>
                {isSaving ? <i className="fa-solid fa-circle-o-notch fa-spin" /> : 'Update Plan'}
              </Button>
            )}

            <hr />

            {isSubscribed && (
              <div>
                <Button
                  color="warning"
                  onClick={() => {
                    setShowCancelModal(true);
                  }}
                >
                  Cancel Subscription
                </Button>

                <Modal isOpen={showCancelModal}>
                  <ModalHeader toggle={() => setShowCancelModal(false)}>
                    Are you sure you want to cancel?
                  </ModalHeader>
                  <ModalBody>
                    <p>Please let us know how we can improve!</p>
                    <FormGroup>
                      <Label for="reasonSelect">Reason for canceling*</Label>
                      <Input
                        type="select"
                        name="reason"
                        id="reasonSelect"
                        onChange={(e) => setCancelationReason(e.target.value)}
                        defaultValue={cancelationReason}
                        required
                      >
                        <option value="">Select a reason</option>

                        <option value="found_alternative">Found an alternative</option>
                        <option value="unrelatable_content">Unrelatable content</option>
                        <option value="no_support">Not enough support</option>
                        <option value="no_value">Didn&apos;t see the value</option>
                        <option value="didnt_use">Didn&apos;t use it</option>
                        <option value="other">Other</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="improveField">How can we improve?</Label>
                      <Input
                        bsSize="sm"
                        type="textarea"
                        name="improve"
                        id="improveField"
                        value={cancelationImprove}
                        onChange={(event) => setCancelationImprove(event.target.value)}
                      />
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="danger"
                      disabled={!cancelationReason}
                      onClick={cancelSubscription}
                    >
                      Cancel subscription
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

SubscriptionSettings.propTypes = {
  stripe: PropTypes.shape({
    createToken: PropTypes.func,
    customers: PropTypes.shape({
      update: PropTypes.func,
    }),
  }).isRequired,
  elements: PropTypes.shape({
    getElement: PropTypes.func,
  }).isRequired,
  stripeInvalid: PropTypes.bool,
};

SubscriptionSettings.defaultProps = {
  stripeInvalid: false,
};

export default SubscriptionSettings;
