import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Helpers from 'client-helpers';
import {
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Card,
} from 'reactstrap';
import { useSetStoreValue, useStore, useStoreValue } from 'react-context-hook';
import { storeMap } from 'services/constants';
import { useContents } from 'services/Contents';

import './AIBlade.css';

const MAX_TOKENS = 250;
const MODEL = {
  TEXT_ADA_001: 'text-ada-001',
  TEXT_BABBAGE_001: 'text-babbage-001',
  TEXT_CURIE_001: 'text-curie-001',
  TEXT_DAVINCI_003: 'text-davinci-003',
  TEXT_DAVINCI_001: 'text-davinci-001',
  GPT_35_TURBO_0301: 'gpt-3.5-turbo-0301',
};

const AIBlade = ({ onClose, isAdmin }) => {
  const Contents = useContents();
  const token = useStoreValue(storeMap.TOKEN);
  const user = useStoreValue(storeMap.USER);
  const [stubSocialPost, setStubSocialPost] = useStore(storeMap.STUB_SOCIAL_POST, {});
  const [selectedContentId, setSelectedContentId] = useStore(storeMap.SELECTED_CONTENT_ID);
  const setViewingContentId = useSetStoreValue(storeMap.VIEWING_CONTENT_ID);
  const selectedCalendarEvent = useStoreValue(storeMap.SELECTED_CALENDAR_EVENT);
  const contentsById = useStoreValue(storeMap.CONTENTS_BY_ID);

  let initialPrompt = '';
  if (selectedContentId) {
    initialPrompt = contentsById[selectedContentId]?.text || contentsById[selectedContentId]?.title;
  } else if (selectedCalendarEvent) {
    initialPrompt = selectedCalendarEvent.summary;
  }

  const [prompt, setPrompt] = React.useState(initialPrompt);
  const [promptResponse, setPromptResponse] = React.useState('');
  const [maxTokens, setMaxTokens] = React.useState(MAX_TOKENS);
  const [model, setModel] = React.useState(MODEL.GPT_35_TURBO_0301);
  const [isLoading, setIsLoading] = React.useState(false);
  const textareaRef = React.createRef();
  const debouncedPrompt = Helpers.useDebounce(prompt, 100);

  React.useEffect(() => {
    if (!textareaRef.current) {
      return;
    }

    // Reset height to match text
    textareaRef.current.style.height = '1px';
    textareaRef.current.style.height = `${8 + textareaRef.current.scrollHeight}px`;
  }, [textareaRef, debouncedPrompt]);

  const formattedPrompt = React.useMemo(() => {
    const { noInvestmentAdvice } = user.AISettings || {};
    let finalizedPrompt = `social post about ${prompt}`;

    if (noInvestmentAdvice) {
      finalizedPrompt += ` (do not include SEC or FINRA regulated content, investment advice 
        or strategies. Do not mention specific stocks, funds, or ETFs.)`;
    }

    return finalizedPrompt;
  }, [prompt, user.AISettings]);

  const generate = async () => {
    setIsLoading(true);

    const endpoint =
      model === MODEL.GPT_35_TURBO_0301 ? '/api/ai/chat_completion' : '/api/ai/completion';

    let response;
    try {
      response = await axios.post(
        endpoint,
        {
          prompt: formattedPrompt,
          maxTokens,
          model,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      console.error(errorMessage);
      window.flash(errorMessage, 'warning');
      setIsLoading(false);
      return;
    }

    const completion = response.data.result?.choices?.[0]?.message?.content?.replaceAll('\n', '');
    setPromptResponse(completion);
    setIsLoading(false);
  };

  // Copy Content users private collection
  const copyContent = async () => {
    const contentCopy = await Contents.create({
      collectionIds: [user.privateCollectionId],
      text: promptResponse,
    });

    if (contentCopy) {
      setSelectedContentId(contentCopy._id);
      setViewingContentId(contentCopy._id);
    }
  };

  return (
    <Offcanvas
      className="ai-blade"
      isOpen
      direction="end"
      toggle={() => {
        onClose(false);
      }}
    >
      <OffcanvasHeader toggle={() => onClose()}>AI Generation</OffcanvasHeader>
      <OffcanvasBody>
        <FormGroup floating>
          <Input
            type="textarea"
            className="prompt-input"
            value={prompt}
            innerRef={textareaRef}
            onChange={(event) => setPrompt(event.target.value)}
          />
          <Label>Subject</Label>
        </FormGroup>

        {isAdmin && (
          <>
            <FormGroup floating>
              <Input
                type="number"
                value={maxTokens}
                onChange={(event) => setMaxTokens(event.target.value)}
              />
              <Label>Max Tokens</Label>
            </FormGroup>
            <FormGroup floating>
              <Input type="select" value={model} onChange={(event) => setModel(event.target.value)}>
                {Object.keys(MODEL).map((key) => (
                  <option key={key} value={MODEL[key]}>
                    {MODEL[key]}
                  </option>
                ))}
              </Input>
              <Label>Model</Label>
            </FormGroup>
          </>
        )}
        <Button color="cta" disabled={isLoading} onClick={generate}>
          {isLoading ? <i className="fa-solid fa-circle-o-notch fa-spin" /> : 'Generate'}
        </Button>

        {promptResponse && (
          <>
            <hr />
            <Row className="ai-prompt__buttons">
              <Col>
                <i
                  className="fa-solid fa-feather-pointed"
                  data-tip="Copy to Social Post"
                  onClick={async () => {
                    setStubSocialPost({
                      ...stubSocialPost,
                      data: {
                        ...stubSocialPost.data,
                        text: promptResponse,
                      },
                    });
                  }}
                />
                <Button data-tip="Save as Content" color="none" onClick={copyContent}>
                  Save
                </Button>
              </Col>
            </Row>
            <Card>{promptResponse}</Card>
          </>
        )}
      </OffcanvasBody>
    </Offcanvas>
  );
};

AIBlade.propTypes = {
  onClose: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

AIBlade.defaultProps = {
  isAdmin: false,
};

export default AIBlade;
