export const storeMap = {
  // Models
  JOBS_BY_ID: 'jobsById',
  SOCIAL_POSTS_BY_ID: 'socialPostsById',
  CONTENTS_BY_ID: 'contentsById',
  COLLECTIONS_BY_ID: 'collectionsById',
  CALENDAR_EVENTS_BY_ID: 'calendarEventsById',
  RSS_CONTENTS_BY_ID: 'RSSContentsById',

  // Dashboard
  SELECTED_DATE: 'selectedDate',
  SELECTED_CONTENT_ID: 'selectedContentId',
  SELECTED_CALENDAR_EVENT: 'selectedCalendarEvent',
  STUB_SOCIAL_POST: 'stubSocialPost',

  // User
  USER: 'user',
  TOKEN: 'token',

  // Global
  FLASH_MESSAGE: 'flashMessage',
  STRIPE: 'stripe',

  // Modals
  SIGNUP_MODAL_VISIBLE: 'signupModalVisible',
  SIGNUP_MODAL_PLAN_TYPE: 'signupModalPlanType',
  FORGOT_PASSWORD_MODAL_VISIBLE: 'forgotPasswordModalVisible',
  TRIAL_MODAL_VISIBLE: 'trialModalVisible',
  VIEWING_CONTENT_ID: 'viewingContentId',
};

export const SocialPostStatus = {
  SCHEDULED: 'scheduled',
  POSTED: 'posted',
  RETRYING: 'retrying',
  ERRORED: 'errored',
};

export const SocialChannels = {
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedin',
};

export const SocialPostPlatformOrder = {
  facebook: 0,
  instagram: 1,
  twitter: 2,
  linkedin: 3,
};

export const UserStatus = {
  DEMO: 'demo',
  ACTIVE: 'active',
  CANCELED: 'canceled',
  ADMIN: 'admin',
};

export const StripePlanType = {
  MEMBERSHIP: 'MEMBERSHIP',
  ENTERPRISE: 'ENTERPRISE',
};

export const StripePlanTitle = {
  MEMBERSHIP: 'Membership | $99 / year',
  ENTERPRISE: 'Membership | $99 / month',
};

export const FeatureFlags = {
  ENABLE_SOCIAL_STATS: 'ENABLE_SOCIAL_STATS',
  ENABLE_TIKTOK: 'ENABLE_TIKTOK',
  ENABLE_CONTENT_MANAGER: 'ENABLE_CONTENT_MANAGER',
  FACEBOOK_TEST: 'FACEBOOK_TEST',
};

export const Tours = {
  SOCIAL_LANDING: 'SOCIAL_LANDING',
  SOCIAL_CONNECTED: 'SOCIAL_CONNECTED',
  FIRST_POST: 'FIRST_POST',
};

export const CollectionStatus = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
  DRAFT: 'DRAFT',
  ARCHIVED: 'ARCHIVED',
};

export const ContentType = {
  READY_MADE: 'READY_MADE',
  PROMPT: 'PROMPT',
};

export const AI_MAX_TOKENS = 250;

export const AI_MODEL = {
  TEXT_ADA_001: 'text-ada-001',
  TEXT_BABBAGE_001: 'text-babbage-001',
  TEXT_CURIE_001: 'text-curie-001',
  TEXT_DAVINCI_003: 'text-davinci-003',
  TEXT_DAVINCI_001: 'text-davinci-001',
  GPT_35_TURBO_0301: 'gpt-3.5-turbo-0301',
};
