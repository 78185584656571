/* eslint-disable no-alert */

import React from 'react';
import axios from 'axios';
import { useStoreValue } from 'react-context-hook';
import { storeMap } from 'services/constants';
import { Row, Col, Button } from 'reactstrap';
import BlogPost from '../../../components/BlogPost';
import BlogPostForm from './BlogPostForm';
import Confirm from 'components/ConfirmModal';

import './BlogEditor.css';
import 'react-quill/dist/quill.snow.css';

const BlogEditor = () => {
  const token = useStoreValue(storeMap.TOKEN);
  const [blogPosts, setBlogPosts] = React.useState([]);
  const [editingBlogPostId, setEditingBlogPostId] = React.useState();

  const editBlogPost = (e) => {
    setEditingBlogPostId(e.target.dataset.id);
  };

  const cancelEdit = () => {
    setEditingBlogPostId(null);
  };

  const fetchBlogPosts = async () => {
    if (!token) {
      return;
    }

    // Fetch user data
    let response;
    try {
      response = await axios.get('/api/blog-posts', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      window.flash('Error fetching blogPosts', 'danger');
      return;
    }
    const updatedBlogPosts = response.data || [];

    setBlogPosts(updatedBlogPosts);
  };

  const deleteBlogPost = async (e) => {
    const { id } = e.target.dataset;

    const result = await Confirm({
      message: `Delete Blog Post?`,
    });

    if (!result) {
      return;
    }

    let response;
    try {
      response = await axios.delete(`/api/blog-posts/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      window.flash('Error deleting BlogPost', 'danger');
      return;
    }

    if (response.status === 200) {
      fetchBlogPosts();
    }
  };

  const createOrUpdateBlogPost = async (blogPost) => {
    const updatedBlogPosts = blogPosts.slice(0);

    // Flatten keywords into array of strings
    if (Array.isArray(blogPost.keywords)) {
      blogPost.keywords = blogPost.keywords.reduce(
        (acc, entry) => acc.concat(entry.split(',').map((k) => k.trim())),
        []
      );
    } else if (blogPost.keywords) {
      blogPost.keywords = blogPost.keywords.split(',').map((k) => k.trim());
    }

    if (blogPost._id !== 'new') {
      // Update existing blogPost
      let response;
      try {
        response = await axios.put(`/api/blog-posts/${blogPost._id}`, blogPost, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        window.flash('Error updating blogPost', 'danger');
        return;
      }

      const updatedBlogPost = response.data;

      blogPosts.forEach((existingBlogPost, idx) => {
        if (existingBlogPost._id === blogPost._id) {
          updatedBlogPosts[idx] = updatedBlogPost;
        }
      });
    } else {
      // Create new blogPost
      let response;
      try {
        response = await axios.post(`/api/blog-posts/`, blogPost, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        window.flash('Error creating blogPost', 'danger');
        return;
      }
      const newBlogPost = response.data;

      updatedBlogPosts.push(newBlogPost);
    }

    setBlogPosts(updatedBlogPosts);
    setEditingBlogPostId(null);
    fetchBlogPosts();
  };

  React.useEffect(() => {
    fetchBlogPosts();
  }, []);

  return (
    <div className="blog-editor">
      <h2>Blog Editor</h2>

      <Row>
        <Col>
          {editingBlogPostId === 'new' ? (
            <BlogPostForm
              blogPost={{ _id: editingBlogPostId, htmlContent: '' }}
              createOrUpdateBlogPost={createOrUpdateBlogPost}
              onCancel={cancelEdit}
            />
          ) : (
            <Button
              size="sm"
              color="success"
              onClick={() => {
                setEditingBlogPostId('new');
              }}
            >
              New
            </Button>
          )}
        </Col>
      </Row>

      <hr />

      {blogPosts.map((blogPost) => {
        const isEditing = blogPost._id === editingBlogPostId;

        if (isEditing) {
          return (
            <BlogPostForm
              key={blogPost._id}
              blogPost={blogPost}
              createOrUpdateBlogPost={createOrUpdateBlogPost}
              onCancel={cancelEdit}
            />
          );
        }

        return (
          <Row key={blogPost._id}>
            <Col>
              <BlogPost {...blogPost} />
              <div>
                <Button size="sm" color="default" onClick={editBlogPost} data-id={blogPost._id}>
                  Edit
                </Button>{' '}
                <Button
                  size="sm"
                  color="danger"
                  onClick={deleteBlogPost}
                  data-id={blogPost._id}
                  data-title={blogPost.title}
                >
                  Delete
                </Button>
              </div>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default BlogEditor;
