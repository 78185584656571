/* eslint-disable no-alert */

import React from 'react';
import axios from 'axios';
import { useStoreValue } from 'react-context-hook';
import { storeMap } from 'services/constants';
import { Row, Col, Button } from 'reactstrap';
import SupportArticleForm from './SupportArticleForm';
import Confirm from 'components/ConfirmModal';

import './SupportArticleEditor.css';
import 'react-quill/dist/quill.snow.css';

const SupportArticleEditor = () => {
  const token = useStoreValue(storeMap.TOKEN);
  const [supportArticles, setSupportArticles] = React.useState([]);
  const [editingId, setEditingId] = React.useState();

  const editSupportArticle = (e) => {
    setEditingId(e.target.dataset.id);
  };

  const cancelEdit = () => {
    setEditingId(null);
  };

  const fetchSupportArticles = async () => {
    if (!token) {
      return;
    }

    // Fetch user data
    let response;
    try {
      response = await axios.get('/api/support-articles', {
        params: {
          all: true,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      window.flash('Error fetching supportArticles', 'danger');
      return;
    }
    const responseSupportArticles = response.data || [];

    setSupportArticles(responseSupportArticles);
  };

  const deleteSupportArticle = async (e) => {
    const { id } = e.target.dataset;

    const result = await Confirm({
      message: `Delete Support Article Post?`,
    });

    if (!result) {
      return;
    }

    let response;
    try {
      response = await axios.delete(`/api/support-articles/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      window.flash('Error deleting SupportArticle', 'danger');
      return;
    }

    if (response.status === 200) {
      fetchSupportArticles();
    }
  };

  const createOrUpdateSupportArticle = async (supportArticle) => {
    const updatedSupportArticles = supportArticles.slice(0);

    if (supportArticle._id !== 'new') {
      // Update existing supportArticle
      let response;
      try {
        response = await axios.put(`/api/support-articles/${supportArticle._id}`, supportArticle, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        window.flash('Error updating supportArticle', 'danger');
        return;
      }
      window.flash('Support article updated');
      const updatedSupportArticle = response.data;

      supportArticles.forEach((existingSupportArticle, idx) => {
        if (existingSupportArticle._id === supportArticle._id) {
          updatedSupportArticles[idx] = updatedSupportArticle;
        }
      });
    } else {
      // Create new supportArticle
      let response;
      try {
        response = await axios.post(`/api/support-articles/`, supportArticle, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        window.flash('Error creating SupportArticle', 'danger');
        return;
      }
      window.flash('Support article created');
      const newSupportArticle = response.data;

      updatedSupportArticles.push(newSupportArticle);
    }

    setSupportArticles(updatedSupportArticles);
    setEditingId(null);
    fetchSupportArticles();
  };

  React.useEffect(() => {
    fetchSupportArticles();
  }, []);

  return (
    <div className="support-article-editor">
      <h2>Support Article Editor</h2>

      <Row>
        <Col>
          {editingId === 'new' ? (
            <SupportArticleForm
              supportArticle={{ _id: editingId, htmlContent: '' }}
              createOrUpdateSupportArticle={createOrUpdateSupportArticle}
              onCancel={cancelEdit}
            />
          ) : (
            <Button
              size="sm"
              color="success"
              onClick={() => {
                setEditingId('new');
              }}
            >
              New
            </Button>
          )}
        </Col>
      </Row>

      <hr />

      <Row>
        {supportArticles.map((supportArticle) => {
          const isEditing = supportArticle._id === editingId;

          if (isEditing) {
            return (
              <SupportArticleForm
                key={supportArticle._id}
                supportArticle={supportArticle}
                createOrUpdateSupportArticle={createOrUpdateSupportArticle}
                onCancel={cancelEdit}
              />
            );
          }

          return (
            <Col key={supportArticle._id} md={3}>
              <div>
                <h5>{supportArticle.title}</h5>
                <div>CreatedAt: {supportArticle.createdAt}</div>
                <div>UpdatedAt: {supportArticle.updatedAt}</div>
              </div>
              <div>
                <Button
                  size="sm"
                  color="default"
                  onClick={editSupportArticle}
                  data-id={supportArticle._id}
                >
                  Edit
                </Button>{' '}
                <Button
                  size="sm"
                  color="danger"
                  onClick={deleteSupportArticle}
                  data-id={supportArticle._id}
                  data-title={supportArticle.title}
                >
                  Delete
                </Button>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default SupportArticleEditor;
