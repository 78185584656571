import React from 'react';
import axios from 'axios';
import { useStoreValue } from 'react-context-hook';
import { Row, Col, Button } from 'reactstrap';
import Confirm from 'components/ConfirmModal';
import Job from './Job';

import './Job.css';
import { storeMap } from 'services/constants';
import TextInput from 'components/TextInput';

const JobEditor = () => {
  const token = useStoreValue(storeMap.TOKEN);
  const [jobs, setJobs] = React.useState([]);
  const [filter, setFilter] = React.useState();

  const fetchJobs = async () => {
    if (!token) {
      return;
    }

    // Fetch Job data
    let response;
    try {
      response = await axios.get('/api/jobs?all=true', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      window.flash('Error fetching jobs', 'danger');
      return;
    }

    const fetchedJobs = response.data || [];

    setJobs(fetchedJobs);
  };

  React.useEffect(() => {
    fetchJobs();
  }, []);

  const filteredJobs = jobs.filter(
    (job) => !filter || job._id.includes(filter) || job.userId.includes(filter)
  );

  return (
    <div className="job-editor">
      <h2>Jobs</h2>

      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <TextInput
            name="filter"
            displayText="Filter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </Col>
      </Row>

      <Row>
        {filteredJobs.map((job) => (
          <Col key={job._id}>
            <Job {...job} />
            <Button
              color="danger"
              onClick={async () => {
                const result = await Confirm({
                  message: 'Delete Content?',
                });

                if (result) {
                  await axios.delete(`/api/jobs/${job._id}`, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });
                  fetchJobs();
                }
              }}
            >
              Delete
            </Button>
          </Col>
        ))}
      </Row>

      <hr />
    </div>
  );
};

export default JobEditor;
